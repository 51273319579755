import BreadCrumb from "Common/BreadCrumb";
import TableContainer from "Common/TableContainer";
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  getLeaveApplications as onGetLeaveApplications,
  getActiveUsers as onGetUserList,
} from "slices/thunk";
const LeaveReport = () => {
  const dispatch = useDispatch<any>();

  const selectDataList = createSelector(
    (state: any) => state.HRManagment,
    (user: any) => user.Users,
    (state, user) => ({
      dataList: state.leaveApplications,
      userList: user.activeUsers,
    })
  );
  const { dataList, userList } = useSelector(selectDataList);

  const [data, setData] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const perPageData = 12;
  useEffect(() => {
    setCurrentPage(currentPage);
    dispatch(
      onGetLeaveApplications({
        page: currentPage,
        per_page: perPageData,
      })
    );
    dispatch(onGetUserList());
  }, [currentPage, dispatch]);

  useEffect(() => {
    setData(dataList);
    setUsers(userList?.data);
  }, [dataList, userList?.data]);

  const eliglibleEmployees = users?.filter(
    (user: any) => user?.isConfirmed === true
  );

  const today = new Date();

  //filter to find users currently on leave
  const onLeave = dataList?.data?.filter((user: any) => {
    const startDate = new Date(user.start_date);
    const endDate = new Date(user.end_date);
    return today >= startDate && today <= endDate;
  });

  const remainingDays = onLeave?.map((user: any) => {
    const startDate = new Date(user.start_date);
    const endDate = new Date(user.end_date);
    const diff = endDate.getTime() - today.getTime();
    const days = Math.floor(diff / (1000 * 3600 * 24));
    return days;
  });

  console.log(onLeave, "onLeave");

  const columns = useMemo(
    () => [
      {
        header: "SN",
        enableColumnFilter: false,
        cell: (cell: any) => cell.row.index + 1,
        // cell: (cell) => info.row.index + 1,
      },
      {
        header: "First Name",
        accessorKey: "firstname",
        enableColumnFilter: false,
      },
      {
        header: "Last Name",
        accessorKey: "lastname",
        enableColumnFilter: false,
      },
      {
        header: "Allocated Days",
        accessorKey: "entitled_leave_days",
        enableColumnFilter: false,
      },
      {
        header: "Carried Over",
        accessorKey: "leave_carried_over",
        enableColumnFilter: false,
      },
      {
        header: "Entitled Total",
        accessorKey: "total_leave_days",
        enableColumnFilter: false,
      },
      {
        header: "Taken This Year",
        accessorKey: "taken_leave_days",
        enableColumnFilter: false,
      },
      {
        header: "Left To Take",
        accessorKey: "pending_leave_days",
        enableColumnFilter: false,
      },
    ],
    []
  );

  const currentLeaveColumns = useMemo(
    () => [
      {
        header: "SN",
        enableColumnFilter: false,
        cell: (cell: any) => cell.row.index + 1,
      },
      {
        header: "Full Name",
        accessorKey: "user_name",
        enableColumnFilter: false,
      },
      {
        header: "Date of Resumption",
        accessorKey: "end_date",
        enableColumnFilter: false,
      },
      {
        header: "Days Remaining",
        accessorKey: "days_remaining",
        enableColumnFilter: false,
      },
    ],
    []
  );
  // Table Head Action On Right
  useEffect(() => {
    const tableHead = document.querySelectorAll("th");
    const lastTheadElement = tableHead[tableHead.length - 1];
    lastTheadElement?.classList.add("ltr:text-right", "rtl:text-left");

    return () => {
      lastTheadElement?.classList.remove("ltr:text-right", "rtl:text-left");
    };
  });

  return (
    <React.Fragment>
      {/* <BreadCrumb title="Leave Report" pageTitle="Leaves" /> */}
      <main className="grid grid-cols-1 md:grid-cols-[1fr_2fr] gap-4 md:gap-8">
        <div>
          <p className="font-bold">
            Staff Currently on Leave - {onLeave?.length}
          </p>
          {onLeave && onLeave.length > 0 ? (
            <TableContainer
              isPagination={false}
              columns={currentLeaveColumns || []}
              data={onLeave || []}
              customPageSize={10}
              divclassName="overflow-x-auto"
              tableclassName="w-full whitespace-nowrap"
              theadclassName="ltr:text-left rtl:text-right bg-slate-100 text-slate-500 dark:bg-zink-600 dark:text-zink-200"
              thclassName="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500"
              tdclassName="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500"
              PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
            />
          ) : (
            <div className="noresult">
              <div className="py-6 text-center">
                {/* -<Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" /> */}
                <h5 className="mt-2 mb-1">
                  There is currently no staff on leave
                </h5>
                {/* <p className="mb-0 text-slate-500 dark:text-zink-200">
                    We've searched more than 5+ Departments We did not find any
                    Departments for you search.
                  </p> */}
              </div>
            </div>
          )}
        </div>
        <div className="all-reports">
          <h3 className="text-center">Total Leave Report</h3>
          <div className="overflow-x-auto">
            {eliglibleEmployees && eliglibleEmployees.length > 0 ? (
              <TableContainer
                isPagination={true}
                columns={columns || []}
                data={eliglibleEmployees || []}
                customPageSize={10}
                divclassName="overflow-x-auto"
                tableclassName="w-full whitespace-nowrap"
                theadclassName="ltr:text-left rtl:text-right bg-slate-100 text-slate-500 dark:bg-zink-600 dark:text-zink-200"
                thclassName="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500"
                tdclassName="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500"
                PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
              />
            ) : (
              <div className="noresult">
                <div className="py-6 text-center">
                  {/* -<Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" /> */}
                  <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                  {/* <p className="mb-0 text-slate-500 dark:text-zink-200">
                    We've searched more than 5+ Departments We did not find any
                    Departments for you search.
                  </p> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default LeaveReport;
