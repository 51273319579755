import React, { useCallback, useEffect, useMemo, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";
import Flatpickr from "react-flatpickr";
import CountUp from "react-countup";

// Icons
import {
  Search,
  Check,
  X,
  Home,
  LucideHome,
  HomeIcon,
  UsersRound,
  Building,
  UserRoundX,
  UserRoundCheck,
  Briefcase,
  CalendarRange,
  Plus,
} from "lucide-react";
import TableContainer from "Common/TableContainer";
import { Link } from "react-router-dom";
import Modal from "Common/Components/Modal";

// react-redux
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  getWfh as onGetWfh,
  getWfhDetails as onGetWfhDetails,
  getWfhBoard as onGetWfhBoard,
  addWfh as onAddWfh,
  updateWfh as onUpdateWfh,
  deleteWfh as onDeleteWfh,
  getUserWfh as onGetUserWfh,
} from "slices/thunk";
import filterDataBySearch from "Common/filterDataBySearch";
import DeleteModal from "Common/DeleteModal";
import { ToastContainer } from "react-toastify";

const WFHBoard = () => {
  const dispatch = useDispatch<any>();

  const selectDataList = createSelector(
    (state: any) => state.HRManagment,
    (state) => ({
      dataList: state.wfhBoard,
    })
  );

  const { dataList } = useSelector(selectDataList);
  const [data, setData] = useState<any>([]);
  const [eventData, setEventData] = useState<any>();
  const [show, setShow] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState({ min: "", max: "" });
  const [todaysDate, setTodaysDate] = useState<any>('');

  // Get Data
  useEffect(() => {
    dispatch(onGetWfhBoard({search: '', date: ''}));
  }, [dispatch]);

  useEffect(() => {
    setData(dataList?.data);
  }, [dataList]);

  useEffect(() => {
    // Get the current date
    const today = new Date();
  
    // Store today's date in YYYY-MM-DD format
    const todaysDate = today.toISOString().split("T")[0];
    setTodaysDate(todaysDate);
  
    // Calculate the next Monday (next week's Monday)
    const nextMonday = new Date(today);
    nextMonday.setDate(today.getDate() + ((1 + 7 - today.getDay()) % 7 || 7)); // Ensure next Monday
  
    // Calculate the next Friday (next week's Friday)
    const nextFriday = new Date(nextMonday);
    nextFriday.setDate(nextMonday.getDate() + 4); // Add 4 days to Monday to get Friday
  
    // Format dates in YYYY-MM-DD format for input field
    const minDate = nextMonday.toISOString().split("T")[0];
    const maxDate = nextFriday.toISOString().split("T")[0];
  
    // Update the state with the calculated date range (Monday to Friday next week)
    setDateRange({ min: minDate, max: maxDate });
  }, []);
  

  // Search Data
  const filterSearchData = (e: any) => {
    const search = e.target.value;
    const keysToSearch = ["user_name", "user_role", "supervisor_name", "wfh_date", "name"];
    dispatch(onGetWfhBoard({search: search}));
    filterDataBySearch(data, search, keysToSearch, setData);
  };

  const filterByDate = (selectedDate: string) => {

    console.log('', selectedDate)
    dispatch(onGetWfhBoard({date: selectedDate}));
    setTodaysDate(selectedDate);
  };

  const Attendance = ({ item, week}: any) => {
    switch (item?.status) {
      case true:
        if (item?.auth_id === item?.data?.user_id && week == 'nextweek') {
          // Render link with onClick if auth_id matches user_id
          return (
            <Link
              to="#!"
              className="text-red-500 transition-all duration-200 ease-linear hover:text-red-800"
              onClick={() => {
                const data = item?.data;
                handleUpdateDataClick(data);
              }}
            >
              <HomeIcon className="size-4" />
            </Link>
          );
        } else {
          // Render link without onClick if auth_id does not match user_id
          return (
            <Link
              to="#!"
              className="text-green-500 transition-all duration-200 ease-linear hover:text-green-800"
            >
              <HomeIcon className="size-4" />
            </Link>
          );
        }

      case false:
        return (
          <React.Fragment>
            <b>-</b>
          </React.Fragment>
        );
      // return (<Building className="size-4 text-slate-400" />);

      default:
        return <React.Fragment>-</React.Fragment>;
    }
  };

  // Delete Modal
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const deleteToggle = () => setDeleteModal(!deleteModal);

  // Delete Data
  const onClickDelete = (cell: any) => {
    setDeleteModal(true);
    if (cell.id) {
      setEventData(cell);
    }
  };

  const handleDelete = () => {
    if (eventData) {
      dispatch(onDeleteWfh(eventData.id));
      setDeleteModal(false);
      setShow(false);
      setEventData("");
      setIsEdit(false);
    }
  };
  //

  // Update Data
  const handleUpdateDataClick = (ele: any) => {
    // console.log(ele);
    setEventData({ ...ele });
    setIsEdit(true);
    setShow(true);
  };

  // validation
  const validation: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      date:
        (eventData && new Date(eventData.date).toISOString().split("T")[0]) ||
        "",
      comment: (eventData && eventData.comment) || "",
      status: (eventData && eventData.status) || "",
    },
    validationSchema: Yup.object({
      date: Yup.string().required("Please select your work from home date"),
      comment: Yup.string(),
      status: Yup.string(),
    }),

    onSubmit: (values) => {
      if (isEdit) {
        const updateData = {
          id: eventData ? eventData.id : 0,
          ...values,
        };
        // update user
        dispatch(onUpdateWfh(updateData));
      } else {
        const newData = {
          ...values,
          id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          userId:
            "#TW15000" +
            (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
        };
        // save new user
        dispatch(onAddWfh(newData));
      }
      toggle();
    },
  });

  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
      setEventData("");
      setIsEdit(false);
    } else {
      setShow(true);
      setEventData("");
      validation.resetForm();
    }
  }, [show, validation]);

  return (
    <React.Fragment>
      <BreadCrumb title="WFH Board" pageTitle="Work From Home" />
      <ToastContainer closeButton={false} limit={1} />
      {/* <div className="grid grid-cols-1 gap-x-5 md:grid-cols-2 xl:grid-cols-12">
                <div className="xl:col-span-3">
                    <div className="card">
                        <div className="flex items-center gap-4 card-body">
                            <div className="flex items-center justify-center size-12 rounded-md text-sky-500 bg-sky-100 text-15 dark:bg-sky-500/20 shrink-0"><UsersRound /></div>
                            <div className="overflow-hidden grow">
                                <h5 className="mb-1 text-16">
                                    <CountUp end={43} className="counter-value" />
                                </h5>
                                <p className="truncate text-slate-500 dark:text-zink-200">Total Employee</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="xl:col-span-3">
                    <div className="card">
                        <div className="flex items-center gap-4 card-body">
                            <div className="flex items-center justify-center size-12 text-red-500 bg-red-100 rounded-md text-15 dark:bg-red-500/20 shrink-0"><UserRoundX /></div>
                            <div className="overflow-hidden grow">
                                <h5 className="mb-1 text-16">
                                    <CountUp end={6} className="counter-value" />
                                </h5>
                                <p className="truncate text-slate-500 dark:text-zink-200">Absent Employee (Today)</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="xl:col-span-3">
                    <div className="card">
                        <div className="flex items-center gap-4 card-body">
                            <div className="flex items-center justify-center size-12 text-green-500 bg-green-100 rounded-md text-15 dark:bg-green-500/20 shrink-0"><UserRoundCheck /></div>
                            <div className="overflow-hidden grow">
                                <h5 className="mb-1 text-16">
                                    <CountUp end={32} className="counter-value" />
                                </h5>
                                <p className="truncate text-slate-500 dark:text-zink-200">Present Employee (Today)</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="xl:col-span-3">
                    <div className="card">
                        <div className="flex items-center gap-4 card-body">
                            <div className="flex items-center justify-center size-12 rounded-md text-custom-500 bg-custom-100 text-15 dark:bg-custom-500/20 shrink-0"><Briefcase /></div>
                            <div className="overflow-hidden grow">
                                <h5 className="mb-1 text-16">
                                    <CountUp end={22} className="counter-value" />
                                </h5>
                                <p className="truncate text-slate-500 dark:text-zink-200">Working Days (Current Month)</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

      <div className="grid grid-cols-1 gap-x-5">
        <div className="card">
          <div className="card-body">
            <div className="flex items-center">
              <h6 className="text-15 grow">December 2024 WFH</h6>
              <div className="shrink-0">
                {/* <button type="button" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20" onClick={toggle}><Plus className="inline-block size-4" /> <span className="align-middle">Add User</span></button> */}

                <div className="ltr:lg:text-right rtl:lg:float-left">
                  <Link
                    to="#!"
                    onClick={toggle}
                    type="button"
                    className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                  >
                    <Plus className="inline-block size-4" />{" "}
                    <span className="align-middle">Create New</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="!py-3.5 card-body border-y border-dashed border-slate-200 dark:border-zink-500">
            <div className="grid grid-cols-1 gap-5 mb-5 xl:grid-cols-12">
              <div className="xl:col-span-3">
                <div className="relative">
                  <input
                    type="text"
                    className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    placeholder="Search for ..."
                    autoComplete="off"
                    onChange={(e) => filterSearchData(e)}
                  />
                  <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
                </div>
              </div>
              <div className="xl:col-span-2 xl:col-start-11">
                <div className="relative">
                  <CalendarRange className="absolute size-4 ltr:left-3 rtl:right-3 top-3 text-slate-500 dark:text-zink-200" />
                    <input
                        type='date'
                        className="ltr:pl-10 rtl:pr-10 form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                        value={todaysDate}
                        onChange={(e) => filterByDate(e.target.value)}
                    />
                </div>
              </div>
            </div>
            {data && data.length > 0 ? (
              <div className="overflow-x-auto">
                <table className="w-full bg-custom-50 dark:bg-custom-500/10">
                  <thead className="ltr:text-left rtl:text-right text-white bg-agusto-500 dark:bg-agusto-500/10">
                    <tr>
                      <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900 text-center">
                        SN
                      </th>
                      <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900 text-center">
                        STAFF
                      </th>
                      <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900 text-center">
                        DEPARTMENT
                      </th>
                      <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900 text-center">
                        THIS WEEK
                      </th>
                      <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900 text-center">
                        NEXT WEEK
                      </th>
                    </tr>
                  </thead>
                  <thead className="ltr:text-left rtl:text-right bg-zink-100 dark:bg-zink-700/10">
                    <tr>
                      <th className="px-3.5 py-2.5 font-semibold border-b border-zink-200 dark:border-zink-900 "></th>
                      <th className="px-3.5 py-2.5 font-semibold border-b border-zink-200 dark:border-zink-900 "></th>
                      <th className="px-3.5 py-2.5 font-semibold border-b border-zink-200 dark:border-zink-900 "></th>
                      <th className="px-4 py-2.5 font-semibold border-b border-zink-200 dark:border-zink-900">
                        {["MON", "TUE", "WED", "THUR", "FRI"].map(
                          (day: any, index: any) => (
                            <th key={`thisweekHeader-${index}`} className="p-2">
                              {day}
                            </th>
                          )
                        )}
                      </th>
                      <th className="px-4 py-2.5 font-semibold border-b border-zink-200 dark:border-zink-900">
                        {["MON", "TUE", "WED", "THUR", "FRI"].map(
                          (day: any, index: any) => (
                            <th key={`thisweekHeader-${index}`} className="p-2">
                              {day}
                            </th>
                          )
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((user: any) => (
                      <tr
                        key={user.sn}
                        className="even:bg-slate-50 hover:bg-slate-50 even:hover:bg-slate-100 dark:even:bg-zink-600/50 dark:hover:bg-zink-600 dark:even:hover:bg-zink-600"
                      >
                        <td className="px-3.5 py-2.5 border border-custom-200 dark:border-custom-900">
                          <a
                            href="#!"
                            className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600"
                          >
                            {user.sn}
                          </a>
                        </td>
                        <td className="px-3.5 py-2.5 border border-custom-200 dark:border-custom-900">
                          <div className="flex items-center gap-2">
                            <div className="flex items-center justify-center size-10 font-medium rounded-full shrink-0 bg-slate-200 text-slate-800 dark:text-zink-50 dark:bg-zink-600">
                              {user.user_image ? (
                                <img
                                  src={user.user_image}
                                  alt=""
                                  className="h-10 rounded-full"
                                />
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="grow">
                              <h6 className="mb-1">
                                <Link to="#!" className="name">
                                  {user.user_name}
                                </Link>
                              </h6>
                              <p className="text-slate-500 dark:text-zink-200">
                                {user.user_role.length > 20
                                  ? `${user.user_role.slice(0, 20)}...`
                                  : user.user_role}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="px-3.5 py-2.5 border border-custom-200 dark:border-custom-900">
                          {user.user_dept.length > 20
                            ? `${user.user_dept.slice(0, 20)}...`
                            : user.user_dept}
                        </td>
                        <td className="px-3.5 py-2.5 border border-custom-200 dark:border-custom-900">
                          {user.thisweek.map((day: any, index: any) => (
                            <td key={`thisweek-${index}`} className="p-5">
                              {" "}
                              <Attendance item={day} week='thisweek' />
                            </td>
                            // <div key={`week-one-day-${index}`}>{day}</div>
                          ))}
                        </td>
                        <td className="px-3.5 py-2.5 border border-custom-200 dark:border-custom-900">
                          {user.nextweek.map((day: any, index: any) => (
                            <td key={`nextweek-${index}`} className="p-5">
                              {" "}
                              <Attendance item={day} week='nextweek' />
                            </td>
                            // <div key={`week-one-day-${index}`}>{day}</div>
                          ))}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              // <TableContainer
              //     isPagination={false}
              //     columns={(columns || [])}
              //     data={(data || [])}
              //     customPageSize={10}
              //     divclassName="overflow-x-auto"
              //     tableclassName="w-full whitespace-nowrap"
              //     theadclassName="ltr:text-left rtl:text-right bg-slate-100 text-slate-500 dark:text-zink-200 dark:bg-zink-600"
              //     thclassName="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500"
              //     tdclassName="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500"
              // />
              <div className="noresult">
                <div className="py-6 text-center">
                  <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                  <h5 className="mt-2 mb-1">No WFH! Seems all staff work from office or not a working day </h5>
                  {/* <p className="mb-0 text-slate-500 dark:text-zink-200">
                    We've searched more than 10+ WFH We did not find any WFH for
                    you search.
                  </p> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Main Attendance Modal */}

      {/* <Modal show={show} onHide={() => setShow(!show)} modal-center="true"
                className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
                dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600">
                <Modal.Header className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
                    closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500">
                    <Modal.Title className="text-16">Attendance Info (05 June, 2023)</Modal.Title>
                </Modal.Header>
                <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
                    <div className="rounded-md bg-slate-100 dark:bg-zink-500/50">
                        <div className="p-3">
                            <p className="mb-1 text-slate-500 dark:text-zink-200">Check In Date & Time</p>
                            <h6>Monday, 05 June, 2023 <b>08:29 AM</b></h6>
                        </div>
                        <div className="p-5">
                            <div className="flex items-center justify-center size-24 mx-auto bg-white rounded-full dark:bg-zink-600 text-16 outline outline-white dark:outline-zink-600 outline-offset-4">
                                <b>08:15</b> <small className="ltr:ml-1 rtl:mr-1">Hrs</small>
                            </div>
                        </div>
                        <div className="p-3">
                            <p className="mb-1 text-slate-500 dark:text-zink-200">Check Out Date & Time</p>
                            <h6>Monday, 05 June, 2023 <b>06:11 AM</b></h6>
                        </div>
                    </div>
                </Modal.Body>
            </Modal> */}

      <Modal
        show={show}
        onHide={toggle}
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b dark:border-zink-500"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
        >
          <Modal.Title className="text-16">
            {!!isEdit ? "Update WFH" : "Create New WFH"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            action="#!"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="grid grid-cols-1 gap-4 xl:grid-cols-12">
              <div className="xl:col-span-12">
                <label
                  htmlFor="dateInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Select Date
                </label>
                <input
                  type="date"
                  id="dateInput"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="User Category name"
                  name="date"
                  onChange={(e) => {
                    const selectedDate = new Date(e.target.value);
                    const dayOfWeek = selectedDate.getDay();
                
                    // If the selected date is Tuesday (2) or Wednesday (3), reset the value
                    if (dayOfWeek === 2 || dayOfWeek === 3) {
                      alert("Please select a Monday, Thursday, or Friday.");
                      e.target.value = ""; // Clear the input if invalid day is selected
                    } else {
                      validation.handleChange(e); // Allow the change if it's a valid day
                    }
                  }}
                  value={validation.values.date || ""}
                  min={dateRange.min}
                  max={dateRange.max}
                />
                {validation.touched.date && validation.errors.date ? (
                  <p className="text-red-400">{validation.errors.date}</p>
                ) : null}
              </div>

              <div className="xl:col-span-12">
                <label
                  htmlFor="commentInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Comment
                </label>
                <input
                  type="text"
                  id="commentInput"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Description"
                  name="comment"
                  onChange={validation.handleChange}
                  value={validation.values.comment || ""}
                />
                {validation.touched.comment && validation.errors.comment ? (
                  <p className="text-red-400">{validation.errors.comment}</p>
                ) : null}
              </div>

              {/* {isEdit && (
                                
                                <div className="xl:col-span-12">
                                    <label htmlFor="statusInput" className="inline-block mb-2 text-base font-medium">Status</label>
                                    <select className="form-input border-slate-300 focus:outline-none focus:border-custom-500" data-choices data-choices-search-false 
                                        id="statusInput"
                                        name="status"
                                        onChange={validation.handleChange}
                                        value={validation.values.status || ""}
                                    >
                                        <option value="">Select Status</option>
                                        <option value="pending">Pending</option>
                                        <option value="approved">Approve</option>
                                        <option value="declined">Decline</option>
                                    </select>
                                    {validation.touched.status && validation.errors.status ? (
                                        <p className="text-red-400">{validation.errors.status}</p>
                                    ) : null}
                                </div>
                            )} */}
            </div>
            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                data-modal-close="addDepartmentModal"
                className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                onClick={toggle}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                {!!isEdit ? "Update WFH" : "Create WFH"}
              </button>
              {isEdit && (
                <button
                  type="button"
                  className="text-white btn bg-red-500 border-red-500 hover:text-white hover:bg-red-600 hover:border-red-600 focus:text-white focus:bg-red-600 focus:border-red-600 focus:ring focus:ring-red-100 active:text-white active:bg-red-600 active:border-red-600 active:ring active:ring-red-100 dark:ring-red-400/20"
                  onClick={onClickDelete}
                >
                  Delete WFH
                </button>
              )}
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <DeleteModal
        show={deleteModal}
        onHide={deleteToggle}
        onDelete={handleDelete}
      />
    </React.Fragment>
  );
};

export default WFHBoard;
