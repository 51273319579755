import React, { useCallback, useEffect, useMemo, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";
import CreatableSelect from "react-select/creatable";
import { Nav } from "Common/Components/Tab/Nav";
import Tab from "Common/Components/Tab/Tab";

// Icons
import {
  Search,
  Plus,
  Trash2,
  Pencil,
  MoreHorizontal,
  FileEdit,
  Eye,
  InfoIcon,
} from "lucide-react";
import TableContainer from "Common/TableContainer";

import { Link } from "react-router-dom";
import DeleteModal from "Common/DeleteModal";
import Modal from "Common/Components/Modal";

// react-redux
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

// Formik
import * as Yup from "yup";
import { useFormik, Formik, Field, Form, ErrorMessage, FieldArray} from "formik";
import {
  // getAppraisals as onGetAppraisals,
  getUserAppraisals as onGetUserAppraisals,
  getSupervisorAppraisals as onGetSupervisorAppraisals,
  createAppraisal as onCreateAppraisal,
  updateAppraisal as onUpdateAppraisal,
  deleteAppraisal as onDeleteAppraisal,
  updateAppraisePerformance as onUpdateAppraisePerformance,
  updateColleagueAppraise as onUpdateColleagueAppraise,
  getUserColleagueRelations as onGetUserColleagueRelations,
  getActiveAppraisalCategories as onGetActiveAppraisalCategories,
  getColleagueRelationsDetails as onGetColleagueRelationsDetails,
  checkOpenedAppraisalCategories as onCheckOpenedAppraisalCategories,

  getAppraisalParameter as onGetAppraisalParameter,
  getTeamLeadPerformances as onGetTeamLeadPerformances
} from "slices/thunk";
// import { getDepartments as onGetDepartments } from "services/api_service";
import { ToastContainer } from "react-toastify";
import Pagination from "Common/Pagination";
import { Dropdown } from "Common/Components/Dropdown";

interface Option {
  readonly label: string;
  readonly value?: string;
  readonly options?: Option[];
  readonly isDisabled?: boolean;
}

const Appraisals = () => {
  const dispatch = useDispatch<any>();

  const selectDataList = createSelector(
    (state: any) => state.HRManagment,
    (state) => ({
      dataList: state.userAppraisals,
      dataDetails: state.appraisalDetails,
      checkAppraisal: state.checkAppraisalCategories,
      activeCategories: state.acativeAppraisalCategories,
      colleagueRelationDetails: state.colleagueRelationsDetails,
      colleagueRelations: state.userColleagueRelations,
      supervisorList: state.supervisorAppraisals,

      teamLeadPerformances: state.teamLeadPerformances,
      appraisalParameter: state.appraisalParameter,
    })
  );

  const {
    dataList,
    supervisorList,
    activeCategories,
    checkAppraisal,
    colleagueRelations,
    appraisalParameter,
    colleagueRelationDetails,
    teamLeadPerformances,
  } = useSelector(selectDataList);

  const [data, setData] = useState<any>([]);
  const [supervisorData, setSupervisorData] = useState<any>([]);
  const [collegueRelationData, setCollegueRelations] = useState<any>([]);
  const [eventData, setEventData] = useState<any>();
  const [colleagueData, setColleagueData] = useState<any>({});
  const [appraisal, setAppraisal] = useState<any>({});
  const [categories, setCategories] = useState<any>([]);
  const [show, setShow] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [performanceData, setPerformanceData] = useState<any>();
  const [teamLeadperformanceData, setTeamLeadPerformance] = useState<any>({});
  const [performanceValidationData, setPerformanceValidationData] = useState<any>({
    id: "",
    appraisal_id: "",
    kpis: {},
    max_score: 0,
    comment: "",
  });

  // largeModal
  const [largeModal, setLargeModal] = useState<boolean>(false);
  const largeToggle = () => setLargeModal(!largeModal);

  const [collegeModal, setCollegeModal] = useState<boolean>(false);
  const collegeToggle = () => setCollegeModal(!collegeModal);

  const [performanceModal, setPerformanceModal] = useState<boolean>(false);
  const performanceToggle = () => setPerformanceModal(!performanceModal);

  // Delete Modal
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const deleteToggle = () => setDeleteModal(!deleteModal);

  // Get Data
  useEffect(() => {
    dispatch(onGetUserColleagueRelations());
    dispatch(onGetActiveAppraisalCategories());
    dispatch(onCheckOpenedAppraisalCategories());
    dispatch(onGetUserAppraisals({ page: 1, per_page: 20 }));
    dispatch(onGetSupervisorAppraisals({ page: 1, per_page: 20 }));

    dispatch(onGetTeamLeadPerformances({ page: 1, per_page: 20 }));
  }, [dispatch]);

  useEffect(() => {
    setData(dataList?.data);
    setAppraisal(checkAppraisal?.data);
    setCategories(activeCategories?.data);
    setSupervisorData(supervisorList?.data);
    setCollegueRelations(colleagueRelations?.data)

    setTeamLeadPerformance(teamLeadPerformances?.data);
    // setPerformanceData(appraisalParameter?.data);
  }, [
    dataList,
    activeCategories,
    checkAppraisal,
    colleagueRelations,
    supervisorList,
    teamLeadPerformances,
    colleagueData
  ]);

  // Delete Data
  const onClickDelete = (cell: any) => {
    setDeleteModal(true);
    if (cell.id) {
      setEventData(cell);
    }
  };

  const handleDelete = () => {
    if (eventData) {
      dispatch(onDeleteAppraisal(eventData.id));
      setDeleteModal(false);
    }
  };

  const handleUpdateDataClick = (ele: any) => {
    setEventData({ ...ele });
    setIsEdit(true);
    setShow(true);
  };

  const pendingCount = data?.filter(
    (appraisal: any) => appraisal.status === "pending"
  ).length;

  const totalCount = data?.length;

  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      category_id: (eventData && eventData.appraisal_category_id) || "",
      goals: (eventData && eventData.goals) || "",
      achivements: (eventData && eventData.achivements) || "",
      challenges: (eventData && eventData.challenges) || "",
      strength: (eventData && eventData.strength) || "",
      improvements: (eventData && eventData.improvements) || "",
      recommendations: (eventData && eventData.recommendations) || "",
      status: (eventData && eventData.status) || "",
    },
    validationSchema: Yup.object({
      category_id: Yup.string().required(
        "Please Select Your Appraisal Category"
      ),
      goals: Yup.string().required("Please state the goals for the period"),
      achivements: Yup.string().required(
        "Please state the goals achieved for the period"
      ),
      challenges: Yup.string(),
      strength: Yup.string(),
      improvements: Yup.string(),
      recommendations: Yup.string(),
      status: Yup.string(),
    }),

    onSubmit: (values) => {
      if (isEdit) {
        const updateData = {
          id: eventData ? eventData.id : 0,
          ...values,
        };
        // update user
        dispatch(onUpdateAppraisal(updateData));
      } else {
        const newData = {
          ...values,
          id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          userId:
            "#TW15000" +
            (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
        };
        // save new user
        dispatch(onCreateAppraisal(newData));
      }
      toggle();
    },
  });
  
  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
      setEventData("");
      setIsEdit(false);
    } else {
      setShow(true);
      setEventData("");
      validation.resetForm();
    }
  }, [show, validation]);

  // Status
  const Status = ({ item }: any) => {
    switch (item) {
      case "approved":
        return (
          <span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent">
            Concluded
          </span>
        );
      case "pending":
        return (
          <span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-yellow-100 border-transparent text-yellow-500 dark:bg-yellow-500/20 dark:border-transparent">
            Pending
          </span>
        );
      case "opened":
        return (
          <span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-custom-100 border-transparent text-custom-500 dark:bg-custom-500/20 dark:border-transparent">
            Opened
          </span>
        );
      case "appraised":
        return (
          <span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-yellow-100 border-transparent text-yellow-500 dark:bg-yellow-500/20 dark:border-transparent">
            Appraised
          </span>
        );
      case "reviewed":
        return (
          <span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-orange-100 border-transparent text-orange-500 dark:bg-orange-500/20 dark:border-transparent">
            Reviewed
          </span>
        );
      case "closed":
        return (
          <span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-yellow-100 border-transparent text-yellow-500 dark:bg-yellow-500/20 dark:border-transparent">
            Closed, Await Approval
          </span>
        );

        case "updated":
          return (
            <span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent">
              Appraised
            </span>
          );

      default:
        return (
          <span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-slate-100 border-transparent text-slate-500 dark:bg-slate-500/20 dark:border-transparent">
            {item}
          </span>
        );
    }
  };

  const Type = ({ item }: any) => {
    switch (item) {
      case "half_yr":
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">
            Half Year
          </span>
        );
      case "full_yr":
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">
            Full Year
          </span>
        );
      case "confirmation":
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">
            Confirmation
          </span>
        );
      default:
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">
            {item}
          </span>
        );
    }
  };

  const Category = ({ item }: any) => {
    switch (item) {
      case "training":
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">
            Training
          </span>
        );
      case "client_specific_report":
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">
            Consulting/Client Specific Reports
          </span>
      );
      case "mi_research":
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">
            Macro & Industry Research
          </span>
      );
      case "ami_research":
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">
            AMI Research
          </span>
      );
      case "ratings":
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">
            Ratings
          </span>
      );
      case "people_relations":
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">
            People Relations
          </span>
      );
      case "business_support":
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">
            Business Support
          </span>
        );
      default:
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">
            {item}
          </span>
        );
    }
  };

  const columns = useMemo(
    () => [
      {
        header: (
          <div className="flex items-center h-full">
            <input
              id="CheckboxAll"
              className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer"
              type="checkbox"
            />
          </div>
        ),
        enableSorting: false,
        id: "checkAll",
        cell: (cell: any) => {
          return (
            <div className="flex items-center h-full">
              <input
                id="Checkbox1"
                className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer"
                type="checkbox"
              />
            </div>
          );
        },
      },
      {
        header: "Appraisal ID",
        accessorKey: "ref_no",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <Link
            to={`/appaisals/${cell.row.original.id}`}
            className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600 user-id"
          >
            <Type item={cell.getValue()} />
          </Link>
        ),
      },
      {
        header: "Start Date",
        accessorKey: "created_at",
        enableColumnFilter: false,
      },
      {
        header: "Type",
        accessorKey: "appraisal_category.type",
        enableColumnFilter: false,
        cell: (cell: any) => <Type item={cell.getValue()} />,
      },
      {
        header: "Period",
        accessorKey: "appraisal_category.year",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div className="flex items-center gap-2">
            <div className="grow">
              <p className="mb-1">
                <span className="font-bold pr-1">
                  <Link to="#!" className="name">
                    {cell.row.original.appraisal_category?.month}
                  </Link>
                </span>
                {cell.getValue()}
              </p>
              {/* <p className="text-slate-500 dark:text-zink-200">
                {cell.getValue()}
              </p> */}
            </div>
          </div>
        ),
      },
      {
        header: "Supervisor",
        accessorKey: "supervisor_name",
        enableColumnFilter: false,
      },
      {
        header: "Score",
        accessorKey: "total_percentage",
        enableColumnFilter: false,
        cell: (cell: any) => <p className="font-bold">{cell.getValue()}%</p>,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => <Status item={cell.getValue()} />,
      },

      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <div className="flex gap-3">
            <Link
              className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md bg-slate-100 text-slate-500 hover:text-custom-500 hover:bg-custom-100 dark:bg-zink-600 dark:text-zink-200 dark:hover:bg-custom-500/20 dark:hover:text-custom-500"
              to={`/appaisals/${cell.row.original.id}`}
            >
              <Eye className="inline-block size-3" />{" "}
            </Link>
            <Link
              to="#!"
              data-modal-target="addEmployeeModal"
              className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md edit-item-btn bg-slate-100 text-slate-500 hover:text-custom-500 hover:bg-custom-100 dark:bg-yellow-600 dark:text-yellow-200 dark:hover:bg-custom-500/20 dark:hover:text-custom-500"
              onClick={() => {
                const data = cell.row.original;
                handleUpdateDataClick(data);
              }}
            >
              <Pencil className="size-4" />
            </Link>
            <Link
              to="#!"
              className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md remove-item-btn bg-slate-100 text-slate-500 hover:text-custom-500 hover:bg-custom-100 dark:bg-red-600 dark:text-red-200 dark:hover:bg-custom-500/20 dark:hover:text-custom-500"
              onClick={() => {
                const data = cell.row.original;
                onClickDelete(data);
              }}
            >
              <Trash2 className="size-4" />
            </Link>
          </div>
        ),
      },
    ],
    []
  );

  const colleagueValidation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      mark: (eventData && eventData.mark) || 0,
      appraisal_id: (eventData && eventData.appraisal_id) || "",
      type: (eventData && eventData.type) || "",
      question: (eventData && eventData.question) || "",
      comment: (eventData && eventData.comment) || "",
      status: (eventData && eventData.status) || "",
    },
    validationSchema: Yup.object({
      mark: Yup.number(),
      appraisal_id: Yup.string(),
      type: Yup.string(),
      question: Yup.string(),
      comment: Yup.string(),
      status: Yup.string(),
    }),

    onSubmit: (values) => {
      const updateData = {
        id: eventData ? eventData.id : 0,
        ...values,
      };
      dispatch(onUpdateColleagueAppraise(updateData));
      console.log('updateData', updateData);
      // toggle();
    },
  });

  const colleagueColumns = useMemo(
    () => [
      {
        header: (
          <div className="flex items-center h-full">
            <input
              id="CheckboxAll"
              className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer"
              type="checkbox"
            />
          </div>
        ),
        enableSorting: false,
        id: "checkAll",
        cell: (cell: any) => {
          return (
            <div className="flex items-center h-full">
              <input
                id="Checkbox1"
                className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer"
                type="checkbox"
              />
            </div>
          );
        },
      },
      {
        header: "Appraisal ID",
        accessorKey: "ref_no",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <Link
            to="#!"
            onClick={() => {
              const data = cell.row.original;
              // onAppraiseColleague(data);
            }}
            data-modal-target="collegeModal"
            className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600 user-id"
          >
            <Type item={cell.getValue()} />
          </Link>
        ),
      },

      {
        header: "Staff",
        accessorKey: "appraisee",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div className="flex items-center gap-2">
            <div className="flex items-center justify-center size-10 font-medium rounded-full shrink-0 bg-slate-200 text-slate-800 dark:text-zink-50 dark:bg-zink-600">
              {cell.row.original.appraisee_image ? (
                <img
                  src={cell.row.original.appraisee_image}
                  alt=""
                  className="h-10 rounded-full"
                />
              ) : (
                ""
              )}
            </div>
            <div className="grow">
              <h6 className="mb-1">
                <Link to="#!" className="name">
                  {cell.getValue()}
                </Link>
              </h6>
              <p className="text-slate-500 dark:text-zink-200">
                {cell.row.original.appraisee_role}
              </p>
            </div>
          </div>
        ),
      },
      {
        header: "Type",
        accessorKey: "type",
        enableColumnFilter: false,
        cell: (cell: any) => <Category item={cell.getValue()} />,
      },

      {
        header: "Deadline",
        accessorKey: "deadline",
        enableColumnFilter: false,
      },

      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => <Status item={cell.getValue()} />,
      },

      {
        header: "Date",
        accessorKey: "created_at",
        enableColumnFilter: false,
      },

      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <div className="flex gap-3">
            <Link
              to="#!"
              // data-modal-target="collegeModal"
              className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md edit-item-btn bg-slate-100 text-slate-500 hover:text-custom-500 hover:bg-custom-100 dark:bg-yellow-600 dark:text-yellow-200 dark:hover:bg-custom-500/20 dark:hover:text-custom-500"
              onClick={() => {
                const data = cell.row.original;
                onAppraiseColleague(data);
              }}
            >
              <Pencil className="size-4" />
            </Link>
            {/* <Link to="#!" className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md remove-item-btn bg-slate-100 text-slate-500 hover:text-custom-500 hover:bg-custom-100 dark:bg-red-600 dark:text-red-200 dark:hover:bg-custom-500/20 dark:hover:text-custom-500" onClick={() => {
                  const data = cell.row.original;
                  onClickDelete(data);
              }}><Trash2 className="size-4" /></Link> */}
          </div>
        ),
      },
    ],
    []
  );

  const onAppraiseColleague = (data: any) => {
    setEventData({ ...data });
    collegeToggle();
  };

  const performanceColumns = useMemo(
    () => [
      {
        header: (
          <div className="flex items-center h-full">
            <input
              id="CheckboxAll"
              className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer"
              type="checkbox"
            />
          </div>
        ),
        enableSorting: false,
        id: "checkAll",
        cell: (cell: any) => {
          return (
            <div className="flex items-center h-full">
              <input
                id="Checkbox1"
                className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer"
                type="checkbox"
              />
            </div>
          );
        },
      },
      {
        header: "Appraisal ID",
        accessorKey: "id",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <Link
            to="#!"
            onClick={() => {
              const data = cell.row.original;
              // onAppraiseColleague(data);
            }}
            data-modal-target="collegeModal"
            className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600 user-id"
          >
            <Type item={cell.getValue().slice(0, 8)} />
          </Link>
        ),
      },

      {
        header: "Staff",
        accessorKey: "appraisal.employee_name",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div className="flex items-center gap-2">
            <div className="flex items-center justify-center size-10 font-medium rounded-full shrink-0 bg-slate-200 text-slate-800 dark:text-zink-50 dark:bg-zink-600">
              {cell.row.original.appraisal.employee_image ? (
                <img
                  src={cell.row.original.appraisal.employee_image}
                  alt=""
                  className="h-10 rounded-full"
                />
              ) : (
                ""
              )}
            </div>
            <div className="grow">
              <h6 className="mb-1">
                <Link to="#!" className="name">
                  {cell.getValue()}
                </Link>
              </h6>
              <p className="text-slate-500 dark:text-zink-200">
                {cell.row.original.appraisal.employee_role}
              </p>
            </div>
          </div>
        ),
      },
      {
        header: "Type",
        accessorKey: "parameter",
        enableColumnFilter: false,
        cell: (cell: any) => <Category item={cell.getValue()} />,
      },

      {
        header: "Deadline",
        accessorKey: "appraisal.appraisal_category.deadline",
        enableColumnFilter: false,
      },

      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => <Status item={cell.getValue()} />,
      },

      {
        header: "Date",
        accessorKey: "created_at",
        enableColumnFilter: false,
      },

      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <div className="flex gap-3">
            <Link
              to="#!"
              // data-modal-target="collegeModal"
              className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md edit-item-btn bg-slate-100 text-slate-500 hover:text-custom-500 hover:bg-custom-100 dark:bg-yellow-600 dark:text-yellow-200 dark:hover:bg-custom-500/20 dark:hover:text-custom-500"
              onClick={() => {
                const data = cell.row.original;
                onAppraiseOtherPerformance(data);
              }}
            >
              <Pencil className="size-4" />
            </Link>
            {/* <Link to="#!" className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md remove-item-btn bg-slate-100 text-slate-500 hover:text-custom-500 hover:bg-custom-100 dark:bg-red-600 dark:text-red-200 dark:hover:bg-custom-500/20 dark:hover:text-custom-500" onClick={() => {
                  const data = cell.row.original;
                  onClickDelete(data);
              }}><Trash2 className="size-4" /></Link> */}
          </div>
        ),
      },
    ],
    []
  );

  const onAppraiseOtherPerformance = (cell: any) => {
    setEventData({...cell});
    setPerformanceModal(true); 
  };

  const performanceValidation = useFormik({
    enableReinitialize: true,
  
    initialValues: {
      appraisal_id: eventData?.appraisal_id || "",
      kpis: eventData?.kpis || [], // Initialize as an empty array if missing
      max_score: eventData?.max_score || 0,
      comment: eventData?.comment || "",
    },
  
    validationSchema: Yup.object({
      comment: Yup.string(),
      kpis: Yup.array().of(
        Yup.object({
          item: Yup.string(),
          mark: Yup.number().min(0, "Mark must be at least 0").max(5, "Mark must be at most 5"),
        })
      ),
    }),
  
    onSubmit: (values) => {
      const updateData = {
        id: eventData?.id || 0,
        ...values,
      };
      console.log('updateData', updateData);
      dispatch(onUpdateAppraisePerformance(updateData));
    },
  });

  // Pagination
  const [currentPage, setCurrentPage] = useState<any>(1);
  const perPageData = 20;
  useEffect(() => {
    setCurrentPage(currentPage);
    dispatch(onGetUserAppraisals({ page: currentPage, per_page: perPageData }));
  }, [dispatch, currentPage]);

  return (
    <React.Fragment>
      <BreadCrumb title="Appraisal List" pageTitle="Appraisals" />
      <DeleteModal
        show={deleteModal}
        onHide={deleteToggle}
        onDelete={handleDelete}
      />
      <ToastContainer closeButton={false} limit={1} />

      <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-x-5">
        <div>
          <div className="card">
            <div className="text-center card-body">
              <h6 className="mb-1 underline">{totalCount}</h6>
              <p className="uppercase text-slate-500 dark:text-zink-200">
                Total Appraisal
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="card">
            <div className="text-center card-body">
              <h6 className="mb-1">{pendingCount}</h6>
              <p className="uppercase text-slate-500 dark:text-zink-200">
                Pending Appraisal
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="card">
            <div className="text-center card-body">
              {appraisal?.status ? (
                <h6 className="mb-1">
                  {appraisal?.category?.type === "half_yr"
                    ? `Half Year - ${appraisal?.category?.month}, ${appraisal?.category?.year} Appraisal`
                    : appraisal?.category?.type === "full_yr"
                    ? `Full Year - ${appraisal?.category?.month}, ${appraisal?.category?.year} Appraisal`
                    : `Confirmation - ${appraisal?.category?.month}, ${appraisal?.category?.year}`}
                </h6>
              ) : (
                <h6 className="mb-1">N/A</h6>
              )}
              <p className="uppercase text-slate-500 dark:text-zink-200">
                Ongoing Type
              </p>
            </div>
          </div>
        </div>
        {/* <div>
              <div className="card">
                  <div className="text-center card-body">
                      <h6 className="mb-1">$843.49</h6>
                      <p className="uppercase text-slate-500 dark:text-zink-200">Order Amount</p>
                  </div>
              </div>
          </div> */}

        <div>
          <div className="card">
            <div className="text-center card-body">
              {appraisal?.status ? (
                <span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-yellow-100 border-yellow-200 text-yellow-500 dark:bg-yellow-500/20 dark:border-yellow-500/20">
                  Opened
                </span>
              ) : (
                <span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-purple-100 border-purple-200 text-purple-500 dark:bg-purple-500/20 dark:border-purple-500/20">
                  Closed
                </span>
              )}
              <p className="uppercase text-slate-500 dark:text-zink-200 mt-2">
                Ongoing Status
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="card" id="ordersTable">
        <div className="card-body">
          <div>
            <Tab.Container defaultActiveKey="homeJustified">
              <Nav className="flex flex-wrap w-full text-sm font-medium text-center border-b border-slate-200 dark:border-zink-500 nav-tabs">
                <Nav.Item eventKey="homeJustified" className="group grow">
                  <a
                    href="#!"
                    data-tab-toggle
                    data-target="homeJustified"
                    className="inline-block px-4 py-2 text-base w-full transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 dark:group-[.active]:hover:text-white -mb-[1px]"
                  >
                    My Appraisals
                  </a>
                </Nav.Item>

                <Nav.Item eventKey="ProfileJustified" className="group grow">
                  <a
                    href="#!"
                    data-tab-toggle
                    data-target="ProfileJustified"
                    className="inline-block px-4 py-2 text-base w-full transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 dark:group-[.active]:hover:text-white -mb-[1px]"
                  >
                    Colleagues Appraisals
                  </a>
                </Nav.Item>

                <Nav.Item eventKey="OtherAppraisal" className="group grow">
                  <a
                    href="#!"
                    data-tab-toggle
                    data-target="OtherAppraisal"
                    className="inline-block px-4 py-2 text-base w-full transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 dark:group-[.active]:hover:text-white -mb-[1px]"
                  >
                    Other Appraisals
                  </a>
                </Nav.Item>
              </Nav>

              <Tab.Content className="mt-5 tab-content">

                <Tab.Pane eventKey="homeJustified" id="homeJustified">
                  <div className="flex flex-col justify-end md:flex-row items-center gap-3 mb-4">
                    {/* <h6 className="text-15 grow"> My Appraisals</h6> */}
                    <div className="shrink-0 flex">
                      <Link
                        to="#!"
                        data-modal-target="largeModal"
                        type="button"
                        className="text-white mr-2 btn bg-yellow-500 border-yellow-500 hover:text-white hover:bg-yellow-600 hover:border-yellow-600 focus:text-white focus:bg-yellow-600 focus:border-yellow-600 focus:ring focus:ring-yellow-100 active:text-white active:bg-yellow-600 active:border-yellow-600 active:ring active:ring-yellow-100 dark:ring-yellow-400/20"
                        onClick={largeToggle}
                      >
                        <InfoIcon className="inline-block size-4" />
                        <span className="align-middle text-base pl-2">
                          Introduction
                        </span>
                      </Link>

                      <Link
                        to="#!"
                        data-modal-target="addAppraisalModal"
                        type="button"
                        className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                        onClick={toggle}
                      >
                        <Plus className="inline-block size-4" />
                        <span className="align-middle text-base pl-2">
                          Start New Appraisal
                        </span>
                      </Link>
                    </div>
                  </div>
                  {data && data.length > 0 ? (
                    <TableContainer
                      isPagination={false}
                      columns={columns || []}
                      data={data || []}
                      customPageSize={perPageData}
                      divclassName="overflow-x-auto"
                      tableclassName="w-full whitespace-nowrap"
                      theadclassName="ltr:text-left rtl:text-right"
                      thclassName="px-3.5 py-2.5 font-semibold border border-slate-200 dark:border-zink-500"
                      tdclassName="px-3.5 py-2.5 border border-slate-200 dark:border-zink-500"
                      PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
                    />
                  ) : (
                    <div className="noresult">
                      <div className="py-6 text-center">
                        <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                        <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                        {/* <p className="mb-0 text-slate-500 dark:text-zink-200">
                          We've searched more than 5+ times We did not find any
                          appraisal category data for you search.
                        </p> */}
                      </div>
                    </div>
                  )}
                  <Pagination
                    perPageData={perPageData}
                    data={dataList ? dataList?.data : dataList}
                    total={dataList ? dataList?.meta?.total : 0}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    currentdata={dataList ? dataList?.data : []}
                  />
                </Tab.Pane>

                <Tab.Pane eventKey="ProfileJustified" id="ProfileJustified">
                  {/* <div className="flex items-center gap-3 mb-4">
                    <h6 className="text-15 grow">Colleagues Appraisals</h6>
                    <div className="shrink-0">
                      <Link
                        to="#!"
                        data-modal-target="largeModal"
                        type="button"
                        className="text-white mr-2 btn bg-yellow-500 border-yellow-500 hover:text-white hover:bg-yellow-600 hover:border-yellow-600 focus:text-white focus:bg-yellow-600 focus:border-yellow-600 focus:ring focus:ring-yellow-100 active:text-white active:bg-yellow-600 active:border-yellow-600 active:ring active:ring-yellow-100 dark:ring-yellow-400/20"
                        onClick={largeToggle}
                      >
                        <InfoIcon className="inline-block size-4" />
                        <span className="align-middle">Introduction</span>
                      </Link>
                    </div>
                  </div> */}
                  {collegueRelationData && collegueRelationData.length > 0 ? (
                    <TableContainer
                      isPagination={false}
                      columns={colleagueColumns || []}
                      data={collegueRelationData || []}
                      customPageSize={perPageData}
                      divclassName="overflow-x-auto"
                      tableclassName="w-full whitespace-nowrap"
                      theadclassName="ltr:text-left rtl:text-right"
                      thclassName="px-3.5 py-2.5 font-semibold border border-slate-200 dark:border-zink-500"
                      tdclassName="px-3.5 py-2.5 border border-slate-200 dark:border-zink-500"
                      PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
                    />
                  ) : (
                    <div className="noresult">
                      <div className="py-6 text-center">
                        <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                        <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                        {/* <p className="mb-0 text-slate-500 dark:text-zink-200">
                          We've searched more than 5+ times We did not find any
                          appraisal category data for you search.
                        </p> */}
                      </div>
                    </div>
                  )}
                  <Pagination
                    perPageData={perPageData}
                    data={
                      colleagueRelations
                        ? colleagueRelations?.data
                        : colleagueRelations
                    }
                    total={
                      colleagueRelations ? colleagueRelations?.meta?.total : 0
                    }
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    currentdata={
                      colleagueRelations ? colleagueRelations?.data : []
                    }
                  />
                </Tab.Pane>

                <Tab.Pane eventKey="OtherAppraisal" id="OtherAppraisal">
                  {teamLeadperformanceData && teamLeadperformanceData.length > 0 ? (
                    <TableContainer
                      isPagination={false}
                      columns={performanceColumns || []}
                      data={teamLeadperformanceData || []}
                      customPageSize={perPageData}
                      divclassName="overflow-x-auto"
                      tableclassName="w-full whitespace-nowrap"
                      theadclassName="ltr:text-left rtl:text-right"
                      thclassName="px-3.5 py-2.5 font-semibold border border-slate-200 dark:border-zink-500"
                      tdclassName="px-3.5 py-2.5 border border-slate-200 dark:border-zink-500"
                      PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
                    />
                  ) : (
                    <div className="noresult">
                      <div className="py-6 text-center">
                        <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                        <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                        {/* <p className="mb-0 text-slate-500 dark:text-zink-200">
                          We've searched more than 5+ times We did not find any
                          appraisal category data for you search.
                        </p> */}
                      </div>
                    </div>
                  )}
                  <Pagination
                    perPageData={perPageData}
                    data={
                      colleagueRelations
                        ? colleagueRelations?.data
                        : colleagueRelations
                    }
                    total={
                      colleagueRelations ? colleagueRelations?.meta?.total : 0
                    }
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    currentdata={
                      colleagueRelations ? colleagueRelations?.data : []
                    }
                  />
                </Tab.Pane>

              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </div>

      {/* Department Modal */}

      <Modal
        show={largeModal}
        onHide={largeToggle}
        id="largeModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[40rem] bg-white shadow rounded-md dark:bg-zink-600 flex flex-col h-full"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border border-custom-500 dark:border-custom-800"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-500 hover:text-red-500 dark:text-zink-200 dark:hover:text-red-500"
        >
          <Modal.Title className="text-16">
            Appraisal & Confirmation Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <h5 className="mb-3 text-16">
            <u>Summary</u>
          </h5>

          <h3 className="mb-3 text-16">
            <u>The purpose of the confirmation review:</u>
          </h3>

          <li data-v-05645a84>
            <span className="text-slate-500 dark:text-zink-200">
              Review the progress and performance demonstrated to date.
            </span>
          </li>
          <li data-v-05645a84>
            <span className="text-slate-500 dark:text-zink-200">
              Compare actual performance with agreed goals for the probation
              period.
            </span>
          </li>
          <li data-v-05645a84>
            <span className="text-slate-500 dark:text-zink-200">
              To serve as a basis for modifying or changing work patterns and
              behaviour towards more effective working habits.
            </span>
          </li>

          <h3 className="mb-2 text-16 mt-3">
            <u>Instructions and Guidelines:</u>
          </h3>

          <li data-v-05645a84>
            <span className="text-slate-500 dark:text-zink-200">
              {" "}
              The appraisee initiates the confirmation appraisal process by
              completing this appraisal form.
            </span>
          </li>
          <li data-v-05645a84>
            <span className="text-slate-500 dark:text-zink-200">
              Discussion between the appraisee and the supervisor is mandatory
              prior to sign off on the form.
            </span>
          </li>
          <li data-v-05645a84>
            <span className="text-slate-500 dark:text-zink-200">
              All forms must be filled and completed within five (5) days of
              commencement of the appraisals.
            </span>
          </li>

          <li data-v-05645a84>
            <span className="text-slate-500 dark:text-zink-200">
              Supervisors are expected to review employee performance based on
              the goals agreed.
            </span>
          </li>

          <h6 className="mb-4 mt-4 text-15">Overall Ranking</h6>
          <div className="overflow-x-auto">
            <table className="w-full bg-custom-50 dark:bg-custom-500/10">
              <thead className="ltr:text-left rtl:text-right bg-custom-100 dark:bg-custom-500/10">
                <tr>
                  <th className="px-3.5 py-2.5 font-semibold border border-custom-500 dark:border-custom-800">
                    GRADE
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border border-custom-500 dark:border-custom-800">
                    RANKING
                  </th>
                  <th className="px-3.5 py-2.5 font-semibold border border-custom-500 dark:border-custom-800">
                    WEIGHTED SCORE
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">
                    <a
                      href="#!"
                      className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600"
                    >
                      5
                    </a>
                  </td>
                  <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">
                    Outstanding
                  </td>
                  <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">
                    Achieved at least 80% of all set target
                  </td>
                </tr>
                <tr>
                  <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">
                    <a
                      href="#!"
                      className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600"
                    >
                      4
                    </a>
                  </td>
                  <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">
                    Good
                  </td>
                  <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">
                    Achieved at least 70% - 79% of all set target
                  </td>
                </tr>
                <tr>
                  <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">
                    <a
                      href="#!"
                      className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600"
                    >
                      3
                    </a>
                  </td>
                  <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">
                    Satisfactory
                  </td>
                  <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">
                    Achieved at least 60% - 69% of all set target
                  </td>
                </tr>
                <tr>
                  <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">
                    <a
                      href="#!"
                      className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600"
                    >
                      2
                    </a>
                  </td>
                  <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">
                    Weak
                  </td>
                  <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">
                    Achieved at least 50% - 59% of all set target
                  </td>
                </tr>
                <tr>
                  <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">
                    <a
                      href="#!"
                      className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600"
                    >
                      1
                    </a>
                  </td>
                  <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">
                    Very Weak
                  </td>
                  <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">
                    Achieved below 49% of all set target
                  </td>
                </tr>
                <tr>
                  <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">
                    <a
                      href="#!"
                      className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600"
                    >
                      0
                    </a>
                  </td>
                  <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">
                    NA
                  </td>
                  <td className="px-3.5 py-2.5 border border-custom-500 dark:border-custom-800">
                    Not Applicable to the staff
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-between p-4 mt-auto border-t border-slate-200 dark:border-zink-500">
          <h5 className="text-16">Best of Luck</h5>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show}
        onHide={toggle}
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[40rem] bg-white shadow rounded-md dark:bg-zink-600 flex flex-col h-full"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b dark:border-zink-500"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
        >
          <Modal.Title className="text-16">
            {!!isEdit ? "Edit Appraisal" : "Create New Appraisal"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            action="#!"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="grid grid-cols-1 gap-4 xl:grid-cols-12">
              <div className="xl:col-span-12">
                <label
                  htmlFor="CategoryInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Appraisal Category
                </label>
                <select
                  className="form-input border-slate-300 focus:outline-none focus:border-custom-500"
                  data-choices
                  data-choices-search-false
                  id="CategoryInput"
                  name="category_id"
                  disabled={validation.values.status == 'closed' ||validation.values.status == 'approved'}
                  onChange={validation.handleChange}
                  value={validation.values.category_id || ""}
                >
                  <option value="">Select Appraisal Category</option>
                  {categories?.map((category: any) => (
                    <option key={category.id} value={category.id}>
                      {category.month}, {category.year} -{" "}
                      {category.type == "half_yr"
                        ? "Half Year"
                        : category.type == "full_yr"
                        ? "Full Year"
                        : "Confirmation"}
                    </option>
                  ))}
                </select>
                {validation.touched.category_id &&
                validation.errors.category_id ? (
                  <p className="text-red-400">
                    {validation.errors.category_id}
                  </p>
                ) : null}
              </div>

              <div className="xl:col-span-12">
                <label
                  htmlFor="goalInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Goals to Achieve
                </label>
                <textarea
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  id="goalInput"
                  name="goals"
                  placeholder="State the goals for the period"
                  value={validation.values.goals}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  disabled={validation.values.status == 'closed' || validation.values.status == 'approved'}
                  rows={5}
                ></textarea>
                {validation.touched.goals && validation.errors.goals ? (
                  <div id="goals" className="text-red-500">
                    {validation.errors.goals}
                  </div>
                ) : null}
              </div>

              <div className="xl:col-span-12">
                <label
                  htmlFor="goalAchievedInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Goals Achieved
                </label>
                <textarea
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  id="goalAchievedInput"
                  name="achivements"
                  placeholder="State the goals achieved for the period"
                  value={validation.values.achivements}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  disabled={validation.values.status == 'closed' || validation.values.status == 'approved'}
                  rows={5}
                ></textarea>
                {validation.touched.achivements &&
                validation.errors.achivements ? (
                  <div id="achivements" className="text-red-500">
                    {validation.errors.achivements}
                  </div>
                ) : null}
              </div>

              <div className="xl:col-span-12">
                <label
                  htmlFor="goalChallengesInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Challenges
                </label>
                <textarea
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  id="goalChallengesInput"
                  name="challenges"
                  placeholder="State the challengies"
                  value={validation.values.challenges}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  disabled={validation.values.status == 'closed' || validation.values.status == 'approved'}
                  rows={5}
                ></textarea>
                {validation.touched.challenges &&
                validation.errors.challenges ? (
                  <div id="challenges" className="text-red-500">
                    {validation.errors.challenges}
                  </div>
                ) : null}
              </div>

              {/* <div className="xl:col-span-12">
                <label htmlFor="strengthInput" className="inline-block mb-2 text-base font-medium">Identified areas of strength</label>
                <textarea className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" 
                  id="strengthInput" name="strength" placeholder="State the strength for the period"
                  value={validation.values.strength}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  rows={4}>
                </textarea>
                {validation.touched.strength && validation.errors.strength ? <div id="strength" className="text-red-500">{validation.errors.strength}</div> : null }
              </div>
             
              <div className="xl:col-span-12">
                <label htmlFor="improvementsInput" className="inline-block mb-2 text-base font-medium">Identified areas where improvement is needed.</label>
                <textarea className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" 
                  id="improvementsInput" name="improvements" placeholder="Identified areas where improvement is needed."
                  value={validation.values.improvements}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  rows={4}>
                </textarea>
                {validation.touched.improvements && validation.errors.improvements ? <div id="improvements" className="text-red-500">{validation.errors.improvements}</div> : null }
              </div>

              <div className="xl:col-span-12">
                <label htmlFor="recommendationsInput" className="inline-block mb-2 text-base font-medium">Training recommendations / other comments</label>
                <textarea className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" 
                  id="recommendationsInput" name="recommendations" placeholder="State the challengies"
                  value={validation.values.recommendations}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  rows={4}>
                </textarea>
                {validation.touched.recommendations && validation.errors.recommendations ? <div id="recommendations" className="text-red-500">{validation.errors.recommendations}</div> : null }
              </div> */}

              {isEdit && (
                <div className="xl:col-span-12">
                  <label
                    htmlFor="statusInput"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Status
                  </label>
                  <select
                    className="form-input border-slate-300 focus:outline-none focus:border-custom-500"
                    data-choices
                    data-choices-search-false
                    id="statusInput"
                    name="status"
                    onChange={validation.handleChange}
                    value={validation.values.status || ""}
                  >
                    <option value="">Select Status</option>
                    <option value="pending">Pending</option>
                    <option value="opened">Open</option>
                    {/* <option value="closed">Close</option> */}
                  </select>
                  {validation.touched.status && validation.errors.status ? (
                    <p className="text-red-400">{validation.errors.status}</p>
                  ) : null}
                </div>
              )}
            </div>

            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                data-modal-close="addAppraisalModal"
                className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                onClick={toggle}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                {!!isEdit ? "Update" : "Submit Apraisal"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={collegeModal}
        onHide={collegeToggle}
        id="collegeModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[40rem] bg-white shadow rounded-md dark:bg-zink-600 flex flex-col h-full"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border border-custom-500 dark:border-custom-800"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-500 hover:text-red-500 dark:text-zink-200 dark:hover:text-red-500"
        >
          <Modal.Title className="text-16">Colleague Appraisal</Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">

          <form
            action="#!"
            onSubmit={(e) => {
              e.preventDefault();
              colleagueValidation.handleSubmit();
              return false;
            }}
          >
            <div className="xl:grid-cols-12">
              <div className="flex gap-2 mb-2 justify-end">
                <label className="text-sm font-medium">
                  N/A: 0
                </label>
                <label className="text-sm font-medium">
                  Very Weak: 1
                </label>
                <label className="text-sm font-medium">
                  Weak: 2
                </label>
                <label className="text-sm font-medium">
                  Satisfactory: 3
                </label>
                <label className="text-sm font-medium">
                  Good: 4
                </label>
                <label className="text-sm font-medium">
                  Outstanding: 5
                </label>
              </div>

              <div className="xl:col-span-12">
                <label
                  htmlFor="markInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Relationship with Colleagues
                </label>

                <div className="border rounded-md mb-4">
                  <div className="px-3.5 py-2.5 border-b dark:border-zink-500">
                    <div className="flex flex-wrap gap-2">
                      <div className="flex items-center gap-2">

                      {[0, 1, 2, 3, 4, 5].map((value) => (
                          <div key={value} className="flex items-center gap-2">
                            <input
                              id={`mark_${value}`} // Unique ID for each radio button
                              name='mark'
                              type="radio"
                              value={value}
                              checked={colleagueValidation.values.mark == value}
                              onChange={colleagueValidation.handleChange}
                              className="size-4 border rounded-full appearance-none cursor-pointer bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 checked:border-custom-500 dark:checked:bg-custom-500 dark:checked:border-custom-500"
                            />
                            <label htmlFor={`mark_${value}`}>{value}</label>
                          </div>
                        ))}
                      </div>
                    </div>
                    {colleagueValidation.touched.mark &&
                      colleagueValidation.errors.mark ? (
                      <p className="text-red-400">
                        {colleagueValidation.errors.mark}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="xl:col-span-12">
                <label
                  htmlFor="commentInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Comment
                </label>
                <textarea
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  id="commentInput"
                  name="comment"
                  value={colleagueValidation.values.comment || ""}
                  onChange={colleagueValidation.handleChange}
                  placeholder="State your comment"
                  rows={5}
                ></textarea>
                {colleagueValidation.touched.comment && colleagueValidation.errors.comment ? (
                  <div id="comment" className="text-red-500">
                    {colleagueValidation.errors.comment}
                  </div>
                ) : null}
              </div>

            </div>
            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                data-modal-close="addAppraisalModal"
                className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                onClick={collegeToggle}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                Submit Apraisal
              </button>
            </div>
          </form>

          {/* <Formik
            initialValues={peopleRelationData}
            validationSchema={peopleValidationSchema}
            onSubmit={(values) => {
              console.log('peopleRelationData', peopleRelationData);
              console.log('Submitted peopleRelationData values', values);
              // dispatch(onAppraisePerformance(values.peopleRelationData));
          }}>
            {({ values, setFieldValue, handleChange, handleBlur}) => (

              <Form>
                <div className="xl:grid-cols-12">
                  <div className="flex gap-2 mb-2 justify-end">
                    <label className="text-sm font-medium">
                      N/A: 0
                    </label>
                    <label className="text-sm font-medium">
                      Very Weak: 1
                    </label>
                    <label className="text-sm font-medium">
                      Weak: 2
                    </label>
                    <label className="text-sm font-medium">
                      Satisfactory: 3
                    </label>
                    <label className="text-sm font-medium">
                      Good: 4
                    </label>
                    <label className="text-sm font-medium">
                      Outstanding: 5
                    </label>
                  </div>

                  <div className="xl:col-span-12">
                    <label
                      htmlFor="CategoryInput"
                      className="inline-block mb-2 text-base font-medium"
                    >
                      Relationship with Colleagues
                    </label>

                    <div className="border rounded-md mb-4">
                      <div className="px-3.5 py-2.5 border-b dark:border-zink-500">
                        <div className="flex flex-wrap gap-2">
                          <div className="flex items-center gap-2">

                          {[0, 1, 2, 3, 4, 5].map((value) => (
                              <div key={value} className="flex items-center gap-2">
                                <Field
                                  id={`mark_${value}`} // Unique ID for each radio button
                                  name={`mark`} // Correct path to 'mark' field
                                  type="radio"
                                  value={value} // The radio button's value
                                  checked={values?.mark === value}
                                  // disabled={!user.isAdmin}
                                  onChange={() => setFieldValue(`mark`, value)} // Explicitly set the field value
                                  className="size-4 border rounded-full appearance-none cursor-pointer bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 checked:border-custom-500 dark:checked:bg-custom-500 dark:checked:border-custom-500"
                                />
                                <label htmlFor={`mark_${value}`}>{value}</label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="xl:col-span-12">
                    <label
                      htmlFor="commentInput"
                      className="inline-block mb-2 text-base font-medium"
                    >
                      Comment
                    </label>
                    <textarea
                      className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                      id="commentInput"
                      name="comment"
                      value={values?.comment}
                      onChange={(e) => setFieldValue('comment', e.target.value)}
                      onBlur={handleBlur}
                      placeholder="State your comment"
                      rows={5}
                    ></textarea>
                    {validation.touched.comment && validation.errors.comment ? (
                      <div id="comment" className="text-red-500">
                        {validation.errors.comment}
                      </div>
                    ) : null}
                  </div>

                </div>
                <div className="flex justify-end gap-2 mt-4">
                  <button
                    type="reset"
                    data-modal-close="addAppraisalModal"
                    className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                    onClick={collegeToggle}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                  >
                    Submit Apraisal
                  </button>
                </div>
              </Form>

            )}
          </Formik> */}

          {/* <form
            action="#!"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >

          </form> */}
        </Modal.Body>
      </Modal>

      <Modal
        show={performanceModal}
        onHide={performanceToggle}
        id="performanceModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[40rem] bg-white shadow rounded-md dark:bg-zink-600 flex flex-col h-full"
        >
        <Modal.Header
          className="flex items-center justify-between p-4 border border-custom-500 dark:border-custom-800"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-500 hover:text-red-500 dark:text-zink-200 dark:hover:text-red-500"
        >
          <Modal.Title className="text-16">Other Performance Appraisal</Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            action="#!"
            onSubmit={(e) => {
              e.preventDefault();
              performanceValidation.handleSubmit();
            }}
          >
            <div className="grid grid-cols-1 gap-4 xl:grid-cols-12">
              <div className="xl:col-span-12">
                  {performanceValidation?.values?.kpis?.length > 0 && (
                    <div>
                      {performanceValidation?.values?.kpis?.map((kpi: any, index: number) => (
                        <div key={index}>
                          <label
                            htmlFor={`markInput_${index}`}
                            className="inline-block mb-2 text-base font-medium"
                          >
                            {kpi.item}
                          </label>
                          <div className="border rounded-md mb-4">
                            <div className="px-3.5 py-2.5 border-b dark:border-zink-500">
                              <div className="flex flex-wrap gap-2">
                                <div className="flex items-center gap-2">
                                  {[0, 1, 2, 3, 4, 5].map((value) => (
                                    <div key={value} className="flex items-center gap-2">
                                      <input
                                        id={`mark_${index}_${value}`} // Unique ID for each radio button
                                        name={`kpis[${index}].mark`}  // Correct name path for kpis
                                        type="radio"
                                        value={value}
                                        checked={kpi.mark === value}
                                        onBlur={(e) => {
                                          performanceValidation?.setFieldValue(`kpis[${index}].mark`, value); // Use setFieldValue here
                                        }}
                                        onChange={(e) => {
                                          performanceValidation?.setFieldValue(`kpis[${index}].mark`, value); // Use setFieldValue here
                                        }}
                                        className="size-4 border rounded-full appearance-none cursor-pointer bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 checked:border-custom-500 dark:checked:bg-custom-500 dark:checked:border-custom-500"
                                      />
                                      <label htmlFor={`mark_${index}_${value}`}>{value}</label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}

                  <div className="xl:col-span-12">
                    <label
                      htmlFor="goalInput"
                      className="inline-block mb-2 text-base font-medium"
                    >
                      Comment
                    </label>
                    <textarea
                      className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                      id="goalInput"
                      name="comment"
                      placeholder="State the goals for the period"
                      value={performanceValidation.values.comment}
                      onChange={performanceValidation.handleChange}
                      onBlur={performanceValidation.handleBlur}
                      rows={5}
                    ></textarea>
                  </div>
              </div>
            </div>

            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                data-modal-close="addAppraisalModal"
                className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                onClick={performanceToggle}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                {!!isEdit ? "Update" : "Submit Apraisal"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/* <Modal
        show={performanceModal}
        onHide={performanceToggle}
        id="performanceModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[40rem] bg-white shadow rounded-md dark:bg-zink-600 flex flex-col h-full"
        >
        <Modal.Header
          className="flex items-center justify-between p-4 border border-custom-500 dark:border-custom-800"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-500 hover:text-red-500 dark:text-zink-200 dark:hover:text-red-500"
        >
        <Modal.Title className="text-16">Other Performance Appraisal</Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
              action="#!"
              onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
              }}
          >
              <div className="grid grid-cols-1 gap-4 xl:grid-cols-12">
              <div className="flex gap-2 mb-2 justify-end">
                  <label className="text-sm font-medium">
                  N/A: 0
                  </label>
                  <label className="text-sm font-medium">
                  Very Weak: 1
                  </label>
                  <label className="text-sm font-medium">
                  Weak: 2
                  </label>
                  <label className="text-sm font-medium">
                  Satisfactory: 3
                  </label>
                  <label className="text-sm font-medium">
                  Good: 4
                  </label>
                  <label className="text-sm font-medium">
                  Outstanding: 5
                  </label>
              </div>

              <div className="xl:col-span-12">
                  <label
                  htmlFor="CategoryInput"
                  className="inline-block mb-2 text-base font-medium"
                  >
                  Relationship with Colleagues
                  </label>

                  <div className="border rounded-md mb-4">
                  <div className="px-3.5 py-2.5 border-b dark:border-zink-500">
                      <div className="flex flex-wrap gap-2">
                      <div className="flex items-center gap-2">
                          <input id="radioInline0" name="InlineRadio" className="size-4 border rounded-full appearance-none cursor-pointer bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 checked:border-custom-500 dark:checked:bg-custom-500 dark:checked:border-custom-500" type="radio" value="" />
                          <label htmlFor="radioInline0" className="align-middle">
                              0
                          </label>
                      </div>

                      <div className="flex items-center gap-2">
                          <input id="radioInline1" name="InlineRadio" className="size-4 border rounded-full appearance-none cursor-pointer bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 checked:border-custom-500 dark:checked:bg-custom-500 dark:checked:border-custom-500" type="radio" value="" />
                          <label htmlFor="radioInline1" className="align-middle">
                              1
                          </label>
                      </div>

                      <div className="flex items-center gap-2">
                          <input id="radioInline2" name="InlineRadio" className="size-4 border rounded-full appearance-none cursor-pointer bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-green-500 checked:border-green-500 dark:checked:bg-green-500 dark:checked:border-green-500" type="radio" value="" />
                          <label htmlFor="radioInline2" className="align-middle">
                              2
                          </label>
                      </div>

                      <div className="flex items-center gap-2">
                          <input id="radioInline3" name="InlineRadio" className="size-4 border rounded-full appearance-none cursor-pointer bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-orange-500 checked:border-orange-500 dark:checked:bg-orange-500 dark:checked:border-orange-500" type="radio" value="" />
                          <label htmlFor="radioInline3" className="align-middle">
                              3
                          </label>
                      </div>

                      <div className="flex items-center gap-2">
                          <input id="radioInline4" name="InlineRadio" className="size-4 border rounded-full appearance-none cursor-pointer bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-sky-500 checked:border-sky-500 dark:checked:bg-sky-500 dark:checked:border-sky-500" type="radio" value="" />
                          <label htmlFor="radioInline4" className="align-middle">
                              4
                          </label>
                      </div>

                      <div className="flex items-center gap-2">
                          <input id="radioInline5" name="InlineRadio" className="size-4 border rounded-full appearance-none cursor-pointer bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-sky-500 checked:border-sky-500 dark:checked:bg-sky-500 dark:checked:border-sky-500" type="radio" value="" />
                          <label htmlFor="radioInline5" className="align-middle">
                              5
                          </label>
                      </div>
                      </div>

                      {validation.touched.category_id &&
                      validation.errors.category_id ? (
                      <p className="text-red-400">
                      {validation.errors.category_id}
                      </p>
                      ) : null}
                  </div>
                  </div>
              </div>

              <div className="xl:col-span-12">
                  <label
                  htmlFor="goalInput"
                  className="inline-block mb-2 text-base font-medium"
                  >
                  Comment
                  </label>
                  <textarea
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  id="goalInput"
                  name="goals"
                  placeholder="State the goals for the period"
                  value={validation.values.goals}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  rows={5}
                  ></textarea>
                  {validation.touched.goals && validation.errors.goals ? (
                  <div id="goals" className="text-red-500">
                      {validation.errors.goals}
                  </div>
                  ) : null}
              </div>

              {isEdit && (
                  <div className="xl:col-span-12">
                  <label
                      htmlFor="statusInput"
                      className="inline-block mb-2 text-base font-medium"
                  >
                      Status
                  </label>
                  <select
                      className="form-input border-slate-300 focus:outline-none focus:border-custom-500"
                      data-choices
                      data-choices-search-false
                      id="statusInput"
                      name="status"
                      onChange={validation.handleChange}
                      value={validation.values.status || ""}
                  >
                      <option value="">Select Status</option>
                      <option value="pending">Pending</option>
                      <option value="opened">Open</option>
                      <option value="closed">Close</option>
                  </select>
                  {validation.touched.status && validation.errors.status ? (
                      <p className="text-red-400">{validation.errors.status}</p>
                  ) : null}
                  </div>
              )}
              </div>

              <div className="flex justify-end gap-2 mt-4">
              <button
                  type="reset"
                  data-modal-close="addAppraisalModal"
                  className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                  onClick={collegeToggle}
              >
                  Cancel
              </button>
              <button
                  type="submit"
                  className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                  {!!isEdit ? "Update" : "Submit Apraisal"}
              </button>
              </div>
          </form>
        </Modal.Body>
      </Modal> */}
    </React.Fragment>
  );
};

export default Appraisals;
