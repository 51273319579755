import React, { useCallback, useEffect, useMemo, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";
import Flatpickr from "react-flatpickr";
import TableContainer from "Common/TableContainer";
import CountUp from "react-countup";
import { Link, useParams } from "react-router-dom";
import Collapse from "Common/Components/Collapse/Collapse";
import { ChevronDown, ChevronUp } from "lucide-react";
import Select from "react-select";

// Icons
import { Search, Check, X, Clock, XOctagon, RefreshCw } from "lucide-react";

// Image
import user3 from "assets/images/users/user-3.jpg";

// react-redux
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

import {
  getAppraisalPerformance as onGetAppraisalPerformance,
  getAppraisalDetails as onGetAppraisalDetails,
  appraisalRequestStatus as onAppraisalRequestStatus,
  appraisePerformance as onAppraisePerformance,
  updateAppraisal as onUpdateAppraisal,
  deleteAppraisal as onDeleteAppraisal,
  getActiveAppraisalCategories as onGetActiveAppraisalCategories,
  checkOpenedAppraisalCategories as onCheckOpenedAppraisalCategories,
} from "slices/thunk";
import filterDataBySearch from "Common/filterDataBySearch";
// Formik
import * as Yup from "yup";
import { useFormik, Formik, Field, Form, ErrorMessage, FieldArray} from "formik";
import ApprovalModal from "Common/ApprovalModal";
import { ToastContainer } from "react-toastify";

const AppraisalDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch<any>();

  const selectDataList = createSelector(
    (state: any) => state.HRManagment,
    (user: any) => user.Users,
    (state, user) => ({
      profileList: user.userProfile,
      dataList: state.appraisalDetails,
      performanceList: state.appraisalPerformance,
    })
  );

  const { dataList, profileList, performanceList } = useSelector(selectDataList);
  const [data, setData] = useState<any>([]);
  const [user, setProfile] = useState<any>({});
  const [peopleRelation, setPeopleRelation] = useState<any>({});
  const [characterTraits, setCharacterTrait] = useState<any>({});
  const [effectiveComm, setEffectiveComm] = useState<any>({});
  const [workHabit, setWorkHabit] = useState<any>({});
  const [businessSupport, setBusinessSupport] = useState<any>({});
  const [training, setTraining] = useState<any>({});
  const [ratings, setRatings] = useState<any>({});
  const [miResearch, setMIResearch] = useState<any>({});
  const [amiResearch, setAMIResearch] = useState<any>({});
  const [clientSpecificReport, setClientSpecificReport] = useState<any>({});
  const [eventData, setEventData] = useState<any>();
  const [show, setShow] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  // Get Data
  useEffect(() => {
    dispatch(onGetAppraisalDetails(id));
    dispatch(onGetAppraisalPerformance(id));
  }, [dispatch]);

  useEffect(() => {
    setData(dataList?.data);
    setEventData({...dataList?.data})
    setProfile(profileList?.data);

    if (performanceList?.data) {

      setPeopleRelation(performanceList?.data.filter(
        (performanceItem: any) => performanceItem?.parameter === "people_relations"
      )[0]);

      setCharacterTrait(performanceList?.data.filter(
        (performanceItem: any) => performanceItem?.parameter === "character_traits"
      )[0]);

      setEffectiveComm(performanceList?.data.filter(
        (performanceItem: any) => performanceItem?.parameter === "effective_communication"
      )[0]);

      setWorkHabit(performanceList?.data.filter(
        (performanceItem: any) => performanceItem?.parameter === "work_habits"
      )[0]);
 
      setBusinessSupport(performanceList?.data.filter(
        (performanceItem: any) => performanceItem?.parameter === "business_support"
      )[0]);

      setTraining(performanceList?.data.filter(
        (performanceItem: any) => performanceItem?.parameter === "mi_research"
      )[0]);
      
      setRatings(performanceList?.data.filter(
        (performanceItem: any) => performanceItem?.parameter === "ami_research"
      )[0]);

      setMIResearch(performanceList?.data.filter(
        (performanceItem: any) => performanceItem?.parameter === "ratings"
      )[0]);

      setAMIResearch(performanceList?.data.filter(
        (performanceItem: any) => performanceItem?.parameter === "training"
      )[0]);

      setClientSpecificReport(performanceList?.data.filter(
        (performanceItem: any) => performanceItem?.parameter === "client_specific_report"
      )[0]);

    }
  }, [dataList, profileList, performanceList]);

  const columns = useMemo(
    () => [
      {
        header: "Date",
        accessorKey: "date",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <React.Fragment>
            {cell.getValue()}{" "}
            <span className="px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-white border-slate-400 text-slate-500 dark:bg-zink-700 dark:border-zink-400 dark:text-zink-200 ltr:ml-1 rtl:mr-1 align-middle">
              {cell.row.original.day}
            </span>
          </React.Fragment>
        ),
      },
      {
        header: "Check In",
        accessorKey: "checkIn",
        enableColumnFilter: false,
      },
      {
        header: "Check Out",
        accessorKey: "checkOut",
        enableColumnFilter: false,
      },
      {
        header: "Meal Break",
        accessorKey: "mealBreak",
        enableColumnFilter: false,
      },
      {
        header: "Work Hours",
        accessorKey: "workHours",
        enableColumnFilter: false,
      },
      {
        header: "Overtime",
        accessorKey: "overtime",
        enableColumnFilter: false,
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell: any) => (
          <div className="flex gap-2">
            <Link
              to="#!"
              className="flex items-center justify-center size-8 text-green-500 transition-all duration-200 ease-linear bg-green-100 rounded-md hover:text-white hover:bg-green-500 dark:bg-green-500/20 dark:hover:bg-green-500"
            >
              <Check className="size-4" />
            </Link>
            <Link
              to="#!"
              className="flex items-center justify-center size-8 text-red-500 transition-all duration-200 ease-linear bg-red-100 rounded-md hover:text-white hover:bg-red-500 dark:bg-red-500/20 dark:hover:bg-red-500"
            >
              <X className="size-4" />
            </Link>
          </div>
        ),
      },
    ],
    []
  );

  const options = [
    { value: "", label: "Select Employee" },
    { value: "Willie Torres", label: "Willie Torres" },
    { value: "Patricia Garcia", label: "Patricia Garcia" },
    { value: "Juliette Fecteau", label: "Juliette Fecteau" },
    { value: "Thomas Hatfield", label: "Thomas Hatfield" },
    { value: "Juliette Fecteau", label: "Juliette Fecteau" },
    { value: "Nancy Reynolds", label: "Nancy Reynolds" },
    { value: "Holly Kavanaugh", label: "Holly Kavanaugh" },
    { value: "Jonas Frederiksen", label: "Jonas Frederiksen" },
  ];

  // Delete Modal
  // const [deleteModal, setDeleteModal] = useState<boolean>(false);
  // const deleteToggle = () => setDeleteModal(!deleteModal);
  const [approvalStatus, setApprovalStatus] = useState<string>('');
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const deleteToggle = () => setDeleteModal(!deleteModal);

  // const handleDelete = () => {
  //     if (eventData) {
  //         dispatch(onDeleteLeaveApplication(eventData.id));
  //         setDeleteModal(false);
  //     }
  // };

  const onClickApproval = (id: any, status: string) => {
      if (id) {
          setEventData(id);
          setApprovalStatus(status);
      }
      setDeleteModal(true);
  };

  const handleApproval = () => {
      if (data) {
          dispatch(onAppraisalRequestStatus({id: data?.id, status: approvalStatus === 'appraise' ? 'appraised' : approvalStatus === 'review' ? 'reviewed' : approvalStatus === 'close' ? 'closed' : approvalStatus === 'approve' ? 'approved' :'opened'}));
          setDeleteModal(false);
      }
  };

  const payload:any ={
    id: (eventData && eventData.id) || "",
    appraisal_id: (eventData && eventData.id) || "",
    category_id: (eventData && eventData.category_id) || "",
    goals: (eventData && eventData.goals) || "",
    achivements: (eventData && eventData.achivements) || "",
    challenges: (eventData && eventData.challenges) || "",
    strength: (eventData && eventData.strength) || "",
    improvements: (eventData && eventData.improvements) || "",
    recommendations: (eventData && eventData.recommendations) || "",
    comment: (eventData && eventData.comment) || "",
    remark: (eventData && eventData.remark) || "",
    status: (eventData && eventData.status) || "",
  };

  const validation = Yup.object({
    kpis: Yup.array().of(
      Yup.object({
        category_id: Yup.string(),
        goals: Yup.string(),
        achivements: Yup.string(),
        challenges: Yup.string(),
        strength: Yup.string(),
        improvements: Yup.string(),
        recommendations: Yup.string(),
        action: Yup.string(),
        comment: Yup.string(),
        remark: Yup.string(),
        status: Yup.string(),
      })
    ),
  });

  const peopleRelationData:any ={
    id: (peopleRelation && peopleRelation.id) || "",
    appraisal_id: (peopleRelation && peopleRelation.appraisal_id) || "",
    kpis: (peopleRelation && peopleRelation.kpis) || {},
    max_score: (peopleRelation && peopleRelation.max_score) || 0,
    comment: (peopleRelation && peopleRelation.comment) || "",
    team_lead_id: (peopleRelation && peopleRelation.team_lead_id) || "",
    supervisor_id: (peopleRelation && peopleRelation.supervisor_id) || "",
  };

  const characterTraitsData:any ={
    id: (characterTraits && characterTraits.id) || "",
    appraisal_id: (characterTraits && characterTraits.appraisal_id) || "",
    kpis: (characterTraits && characterTraits.kpis) || {},
    max_score: (characterTraits && characterTraits.max_score) || 0,
    comment: (characterTraits && characterTraits.comment) || "",
    team_lead_id: (characterTraits && characterTraits.team_lead_id) || "",
    supervisor_id: (characterTraits && characterTraits.supervisor_id) || "",
  };

  const effectiveCommData:any ={
    id: (effectiveComm && effectiveComm.id) || "",
    appraisal_id: (effectiveComm && effectiveComm.appraisal_id) || "",
    kpis: (effectiveComm && effectiveComm.kpis) || {},
    max_score: (effectiveComm && effectiveComm.max_score) || 0,
    comment: (effectiveComm && effectiveComm.comment) || "",
    team_lead_id: (effectiveComm && effectiveComm.team_lead_id) || "",
    supervisor_id: (effectiveComm && effectiveComm.supervisor_id) || "",
  };

  const workHabitData:any ={
    id: (workHabit && workHabit.id) || "",
    appraisal_id: (workHabit && workHabit.appraisal_id) || "",
    kpis: (workHabit && workHabit.kpis) || {},
    max_score: (workHabit && workHabit.max_score) || 0,
    comment: (workHabit && workHabit.comment) || "",
    team_lead_id: (workHabit && workHabit.team_lead_id) || "",
    supervisor_id: (workHabit && workHabit.supervisor_id) || "",
  };

  const businessSupportData:any ={
    id: (businessSupport && businessSupport.id) || "",
    appraisal_id: (businessSupport && businessSupport.appraisal_id) || "",
    kpis: (businessSupport && businessSupport.kpis) || {},
    max_score: (businessSupport && businessSupport.max_score) || 0,
    comment: (businessSupport && businessSupport.comment) || "",
    team_lead_id: (businessSupport && businessSupport.team_lead_id) || "",
    supervisor_id: (businessSupport && businessSupport.supervisor_id) || "",
  };

  const trainingData:any ={
    id: (training && training.id) || "",
    appraisal_id: (training && training.appraisal_id) || "",
    kpis: (training && training.kpis) || {},
    max_score: (training && training.max_score) || 0,
    comment: (training && training.comment) || "",
    team_lead_id: (training && training.team_lead_id) || "",
    supervisor_id: (training && training.supervisor_id) || "",
  };

  const ratingsData:any ={
    id: (ratings && ratings.id) || "",
    appraisal_id: (ratings && ratings.appraisal_id) || "",
    kpis: (ratings && ratings.kpis) || {},
    max_score: (ratings && ratings.max_score) || 0,
    comment: (ratings && ratings.comment) || "",
    team_lead_id: (ratings && ratings.team_lead_id) || "",
    supervisor_id: (ratings && ratings.supervisor_id) || "",
  };

  const miResearchData:any ={
    id: (miResearch && miResearch.id) || "",
    appraisal_id: (miResearch && miResearch.appraisal_id) || "",
    kpis: (miResearch && miResearch.kpis) || {},
    max_score: (miResearch && miResearch.max_score) || 0,
    comment: (miResearch && miResearch.comment) || "",
    team_lead_id: (miResearch && miResearch.team_lead_id) || "",
    supervisor_id: (miResearch && miResearch.supervisor_id) || "",
  };

  const amiResearchData:any ={
    id: (amiResearch && amiResearch.id) || "",
    appraisal_id: (amiResearch && amiResearch.appraisal_id) || "",
    kpis: (amiResearch && amiResearch.kpis) || {},
    max_score: (amiResearch && amiResearch.max_score) || 0,
    comment: (amiResearch && amiResearch.comment) || "",
    team_lead_id: (amiResearch && amiResearch.team_lead_id) || "",
    supervisor_id: (amiResearch && amiResearch.supervisor_id) || "",
  };

  const clientSpecificReportData:any ={
    id: (clientSpecificReport && clientSpecificReport.id) || "",
    appraisal_id: (clientSpecificReport && clientSpecificReport.appraisal_id) || "",
    kpis: (clientSpecificReport && clientSpecificReport.kpis) || {},
    max_score: (clientSpecificReport && clientSpecificReport.max_score) || 0,
    comment: (clientSpecificReport && clientSpecificReport.comment) || "",
    team_lead_id: (clientSpecificReport && clientSpecificReport.team_lead_id) || "",
    supervisor_id: (clientSpecificReport && clientSpecificReport.supervisor_id) || "",
  };
  
  const performanceValidationSchema = Yup.object({
    kpis: Yup.array().of(
      Yup.object({
        item: Yup.string().required("KPI item is required"),
        mark: Yup.number().min(0, "Mark must be at least 0").max(5, "Mark must be at most 5"),
      })
    ),
  });

  const Status = ({ item }: any) => {
    switch (item) {
        case "approved":
            return (<span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent">Concluded</span>);
        case "pending":
            return (<span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-yellow-100 border-transparent text-yellow-500 dark:bg-yellow-500/20 dark:border-transparent">Pending</span>);
        case "opened":
            return (<span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-custom-100 border-transparent text-custom-500 dark:bg-custom-500/20 dark:border-transparent">Opened</span>);
        case "appraised":
            return (<span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-yellow-100 border-transparent text-yellow-500 dark:bg-yellow-500/20 dark:border-transparent">Appraised</span>);
        case "reviewed":
            return (<span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-orange-100 border-transparent text-orange-500 dark:bg-orange-500/20 dark:border-transparent">Reviewed</span>);
        case "closed":
              return (<span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-yellow-100 border-transparent text-yellow-500 dark:bg-yellow-500/20 dark:border-transparent">Closed, Await Approval</span>);
        default:
            return (<span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-slate-100 border-transparent text-slate-500 dark:bg-slate-500/20 dark:border-transparent">{item}</span>);
    }
  };

  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
      setEventData("");
      setIsEdit(false);
    } else {
      setShow(true);
      setEventData("");
      // validation.resetForm();
    }
  }, [show, validation]);
  
  return (
    <React.Fragment>
      <BreadCrumb title="Performance" pageTitle="Appraisal Details" />
      <ToastContainer closeButton={false} limit={1} />
      <ApprovalModal show={deleteModal} status={approvalStatus} onHide={deleteToggle} onDelete={handleApproval} />
      <div className="grid grid-cols-1 lg:grid-cols-12 xl:grid-cols-12 gap-x-5">
        <div className="lg:col-span-12 xl:col-span-3 xl:row-span-2">
          {/* <div className="mb-5">
            <label
              htmlFor="deliveryStatusSelect"
              className="inline-block mb-2 text-base font-medium"
            >
              Select Category
            </label>
            <Select
              className="border-slate-200 focus:outline-none focus:border-custom-500"
              options={options}
              isSearchable={false} // To disable search
              name="deliveryStatusSelect"
              id="deliveryStatusSelect"
            />
          </div> */}
          <div className="card">
            <div className="card-body">
              <div className="text-center">
                <div className="size-20 mx-auto rounded-full bg-slate-100 dark:bg-zink-600">
                  <img
                    src={data?.employee_image}
                    alt=""
                    className="h-20 rounded-full"
                  />
                </div>
                <h6 className="mt-3 mb-1 text-16">
                  <Link to="#!">{data?.employee_name}</Link>
                </h6>
                <p className="text-slate-500 dark:text-zink-200">
                  {data?.employee_role}
                </p>
              </div>
              <div className="mt-5 overflow-x-auto">
                <table className="w-full mb-0">
                  <tbody>
                    <tr>
                      <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent text-slate-500 dark:text-zink-200">
                        Status
                      </td>
                      <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent font-semibold">
                        <Status item={data?.status} />
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent text-slate-500 dark:text-zink-200">
                        Appraisal ID
                      </td>
                      <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent font-semibold">
                        #{data?.ref_no}
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent text-slate-500 dark:text-zink-200">
                        Period
                      </td>
                      <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent font-semibold">
                        {data?.appraisal_category?.month},{" "}
                        {data?.appraisal_category?.year}
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent text-slate-500 dark:text-zink-200">
                        Date Started
                      </td>
                      <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent font-semibold">
                        {data?.created_at}
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent text-slate-500 dark:text-zink-200">
                        Last Update
                      </td>
                      <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent font-semibold">
                        {data?.timestamp}
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent text-slate-500 dark:text-zink-200">
                        Supervisor
                      </td>
                      <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent font-semibold">
                        {data?.supervisor_name}
                      </td>
                    </tr>

                    <tr>
                      <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent text-slate-500 dark:text-zink-200">
                        Colleague Received
                      </td>
                      <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent font-semibold">
                        {0}
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent text-slate-500 dark:text-zink-200">
                        Colleague Attempted
                      </td>
                      <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent font-semibold">
                        {0}
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent text-slate-500 dark:text-zink-200">
                        Remark
                      </td>
                      <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent font-semibold">
                        {data?.remark ? data?.remark : 'N/A'}
                      </td>
                    </tr>

                    <tr>
                      <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent text-slate-500 dark:text-zink-200">
                        Comment
                      </td>
                      <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent font-semibold">
                        {data?.comment ? data?.comment : 'N/A'}
                      </td>
                    </tr>

                    <tr>
                      <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent text-slate-500 dark:text-zink-200">
                        Action
                      </td>
                      <td className="px-3.5 py-2.5 first:pl-0 last:pr-0 border-y border-transparent font-semibold">
                        {data?.action ? data?.action : 'N/A'}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:col-span-4 xl:col-span-3">
          <div className="card">
            <div className="flex items-center gap-4 card-body">
              <div className="flex items-center justify-center size-12 rounded-md text-sky-500 bg-sky-100 text-15 dark:bg-sky-500/20 shrink-0">
                <Clock />
              </div>
              <div className="grow">
                <h5 className="mb-1 text-16">
                  <CountUp end={data?.total_percentage} className="counter-value" />
                </h5>
                <p className="text-slate-500 dark:text-zink-200">
                  Total Percentage
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:col-span-4 xl:col-span-3">
          <div className="card">
            <div className="flex items-center gap-4 card-body">
              <div className="flex items-center justify-center size-12 text-red-500 bg-red-100 rounded-md text-15 dark:bg-red-500/20 shrink-0">
                <XOctagon />
              </div>
              <div className="grow">
                <h5 className="mb-1 text-16">
                  <CountUp end={data?.grade} className="counter-value" />
                </h5>
                <p className="text-slate-500 dark:text-zink-200">
                  Grade
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:col-span-4 xl:col-span-3">
          <div className="card">
            <div className="flex items-center gap-4 card-body">
              <div className="flex items-center justify-center size-12 text-yellow-500 bg-yellow-100 rounded-md text-15 dark:bg-yellow-500/20 shrink-0">
                <RefreshCw />
              </div>
              <div className="grow">
                <h5 className="mb-1 text-16">
                {data?.ranking} 
                  {/* <CountUp end={data?.ranking} className="counter-value" /> */}
                </h5>
                <p className="text-slate-500 dark:text-zink-200">
                  Ranking
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="xl:col-span-9 lg:col-span-12">
          <div className="grid grid-cols-2 2xl:grid-cols-5 gap-x-5">
            <div>
              <div className="card">
                <div className="text-center card-body">
                  <h6 className="mb-1 underline">{data?.people_relation}%</h6>
                  <p className="uppercase text-slate-500 dark:text-zink-200">
                    People Relations
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="card">
                <div className="text-center card-body">
                  <h6 className="mb-1">{data?.character_traits}%</h6>
                  <p className="uppercase text-slate-500 dark:text-zink-200">
                    Char traits
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="card">
                <div className="text-center card-body">
                  <h6 className="mb-1">{data?.effective_comm}%</h6>
                  <p className="uppercase text-slate-500 dark:text-zink-200">
                    Eff of comm.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="card">
                <div className="text-center card-body">
                  <h6 className="mb-1">{data?.work_habits}%</h6>
                  <p className="uppercase text-slate-500 dark:text-zink-200">
                    Work habits
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div className="card">
                <div className="text-center card-body">
                  <h6 className="mb-1">{data?.osdq}%</h6>
                  <p className="uppercase text-slate-500 dark:text-zink-200">
                    OSDQ
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <div className="grid grid-cols-1 gap-5">
                <Collapse>
                  <Collapse.Trigger className="flex text-white collapsible-header group/item btn w-full py-3 px-6 text-lg bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                    
                    <span>Self Assesement</span>
                    <span className="ml-auto pr-2">Max Score: 0</span>
                    <div className="ltr:ml-2 rtl:mr-2 shrink-0">
                      <ChevronDown className="hidden size-4 group-[.show]/item:inline-block"></ChevronDown>
                      <ChevronUp className="inline-block size-4 group-[.show]/item:hidden"></ChevronUp>
                    </div>
                  </Collapse.Trigger>
                  <Collapse.Content className="mt-2 mb-0 collapsible-content card">
                    <div className="card-body">

                      <Formik
                        initialValues={payload} // Initialize with your peopleRelationData object
                        validationSchema={validation}
                        onSubmit={(values) => {
                          console.log('Submitted values', values);
                          dispatch(onUpdateAppraisal(values));
                        }}
                        >
                          {({ values, setFieldValue, handleChange, handleBlur}) => (

                            
                        <Form>

                          <div className="grid grid-cols-1 gap-4 xl:grid-cols-12">
                            <div className="xl:col-span-12">
                              <label
                                htmlFor="goalInput"
                                className="inline-block mb-2 text-base font-medium"
                              >
                                Goals to Achieve
                              </label>
                              <textarea
                                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                id="goalInput"
                                name="goals"
                                placeholder="State the goals for the period"
                                value={values?.goals}
                                onChange={(e) => setFieldValue('goals', e.target.value)}
                                onBlur={handleBlur}
                                disabled={user.isAdmin}
                                rows={6}
                              ></textarea>
                            </div>

                            <div className="xl:col-span-12">
                              <label
                                htmlFor="goalAchievedInput"
                                className="inline-block mb-2 text-base font-medium"
                              >
                                Goals Achieved
                              </label>
                              <textarea
                                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                id="goalAchievedInput"
                                name="achivements"
                                placeholder="State the goals achieved for the period"
                                value={values?.achivements}
                                onChange={(e) => setFieldValue('achivements', e.target.value)}
                                onBlur={handleBlur}
                                disabled={user.isAdmin}
                                rows={6}
                              ></textarea>
                            </div>

                            <div className="xl:col-span-12">
                              <label
                                htmlFor="goalChallengesInput"
                                className="inline-block mb-2 text-base font-medium"
                              >
                                Challenges
                              </label>
                              <textarea
                                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                id="goalChallengesInput"
                                name="challenges"
                                placeholder="State the challengies"
                                value={values?.challenges}
                                onChange={(e) => setFieldValue('challenges', e.target.value)}
                                onBlur={handleBlur}
                                disabled={user.isAdmin}
                                rows={6}
                              ></textarea>
                            </div>

                            <div className="xl:col-span-12">
                              <label
                                htmlFor="goalstrengthInput"
                                className="inline-block mb-2 text-base font-medium"
                              >
                                Identified areas of strength
                              </label>
                              <textarea
                                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                id="goalstrengthInput"
                                name="strength"
                                placeholder="State the challengies"
                                value={values?.strength}
                                onChange={(e) => setFieldValue('strength', e.target.value)}
                                onBlur={handleBlur}
                                disabled={!user.isAdmin}
                                rows={6}
                              ></textarea>
                            </div>

                            <div className="xl:col-span-12">
                              <label
                                htmlFor="goalimprovementsInput"
                                className="inline-block mb-2 text-base font-medium"
                              >
                                Identified areas where improvement is needed.
                              </label>
                              <textarea
                                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                id="goalimprovementsInput"
                                name="improvements"
                                placeholder="State the challengies"
                                value={values?.improvements}
                                onChange={(e) => setFieldValue('improvements', e.target.value)}
                                onBlur={handleBlur}
                                disabled={!user.isAdmin}
                                rows={4}
                              ></textarea>
                            </div>

                            <div className="xl:col-span-12">
                              <label
                                htmlFor="goalrecommendationsInput"
                                className="inline-block mb-2 text-base font-medium"
                              >
                                Recommendations
                              </label>
                              <textarea
                                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                id="goalrecommendationsInput"
                                name="recommendations"
                                placeholder="State the recommendations"
                                value={values?.recommendations}
                                onChange={(e) => setFieldValue('recommendations', e.target.value)}
                                onBlur={handleBlur}
                                disabled={!user.isAdmin}
                                rows={4}
                              ></textarea>
                            </div>

                          </div>

                          <div className="flex justify-end gap-2 mt-4">

                          {data?.status !== 'approved' && data?.status !== 'closed'? 
                              <button type="submit" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                {!!isEdit ? "Update" : "Save and Continue"}
                              </button>
                            : ''}
                          </div>
                          
                      </Form>
                          )}
                      </Formik>
                    </div>
                  </Collapse.Content>
                </Collapse>

                <Collapse>
                  <Collapse.Trigger className="flex text-white collapsible-header group/item btn w-full py-3 px-6 text-lg bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                    <span>People Relations</span>
                    <span className="ml-auto pr-2">Max Score: {peopleRelation?.max_score ?? 0}</span>
                    <div className="ltr:ml-2 rtl:mr-2 shrink-0">
                      <ChevronDown className="hidden size-4 group-[.show]/item:inline-block"></ChevronDown>
                      <ChevronUp className="inline-block size-4 group-[.show]/item:hidden"></ChevronUp>
                    </div>
                  </Collapse.Trigger>
                  <Collapse.Content className="mt-2 mb-0 collapsible-content card">
                    <div className="card-body">

                      {peopleRelation && peopleRelation?.kpis?.length > 0 ? (
                        <Formik
                          initialValues={{ peopleRelationData }} // Initialize with your peopleRelationData object
                          validationSchema={performanceValidationSchema}
                          onSubmit={async (values) => {
                            console.log('Submitted People Relations values', values);
                            await dispatch(onAppraisePerformance(values.peopleRelationData));
                            await dispatch(onGetAppraisalPerformance(values.peopleRelationData.appraisal_id));
                          }}
                        >
                          {({ values, setFieldValue }) => (
                            <Form>
  
                              <div className="flex gap-4 mb-2 justify-end">
                                <label className="text-lg font-medium">
                                  N/A: 0
                                </label>
                                <label className="text-lg font-medium">
                                  Very Weak: 1
                                </label>
                                <label className="text-lg font-medium">
                                  Weak: 2
                                </label>
                                <label className="text-lg font-medium">
                                  Satisfactory: 3
                                </label>
                                <label className="text-lg font-medium">
                                  Good: 4
                                </label>
                                <label className="text-lg font-medium">
                                  Outstanding: 5
                                </label>
                              </div>
  
                              <FieldArray name="peopleRelationData.kpis">
                                {({ push, remove }) => (
                                  <div>
                                    {values?.peopleRelationData?.kpis?.map((kpi:any, index:any) => (
                                      <div key={index} className="border rounded-md mb-4">
                                        <div className="px-3.5 py-2.5 border-b dark:border-zink-500">
                                          <div className="flex flex-wrap gap-2">
                                            <label className="block w-full text-lg font-medium">{kpi.item}</label>
  
                                            {/* Radio buttons for selecting marks (0-5) */}
                                            {[0, 1, 2, 3, 4, 5].map((value) => (
                                              <div key={value} className="flex items-center gap-2">
                                                <Field
                                                  id={`peopleRelationData.kpis[${index}].mark_${value}`} // Unique ID for each radio button
                                                  name={`peopleRelationData.kpis[${index}].mark`} // Correct path to 'mark' field
                                                  type="radio"
                                                  value={value} // The radio button's value
                                                  checked={kpi.mark === value}
                                                  disabled={!user.isAdmin || kpi.item === "Relationship with colleagues"}
                                                  onChange={() => setFieldValue(`peopleRelationData.kpis[${index}].mark`, value)} // Explicitly set the field value
                                                  className="size-4 border rounded-full appearance-none cursor-pointer bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 checked:border-custom-500 dark:checked:bg-custom-500 dark:checked:border-custom-500"
                                                />
                                                <label htmlFor={`peopleRelationData.kpis[${index}].mark_${value}`}>{value}</label>
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </FieldArray>
  
                              <div className="border rounded-md mb-4">
                                <div className="px-3.5 py-2.5 border-b dark:border-zink-500">
                                  <div className="flex gap-4">
                                    <label className="text-lg font-medium">
                                      Total: <CountUp end={peopleRelation?.kpis?.length * 5} className="counter-value" />
                                    </label>
                                    <label className="text-lg font-medium">
                                      Score: <CountUp end={peopleRelation?.total_mark} className="counter-value" />
                                    </label>
                                    <label className="text-lg font-medium">
                                      Percentage: <CountUp end={peopleRelation?.percentage} className="counter-value" />%
                                    </label>
                                  </div>
                                </div>
                              </div>
  
                              {/* Submit Button */}
                              <div className="flex justify-end gap-2 mt-4">
                              
                                {user.isAdmin && data?.status !== 'approved' ? 
                                <button type="submit" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                  {!!isEdit ? "Update" : "Save and Continue"}
                                </button>
                              : ''}
  
                              </div>
  
                            </Form>
                          )}
                        </Formik>
                      ) : <></>}

                    </div>
                  </Collapse.Content>
                </Collapse>

                <Collapse>
                  <Collapse.Trigger className="flex text-white collapsible-header group/item btn w-full py-3 px-6 text-lg bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">  
                    <span>Character Traits</span>
                    <span className="ml-auto pr-2">Max Score: {characterTraits?.max_score}</span>
                    <div className="ltr:ml-2 rtl:mr-2 shrink-0">
                      <ChevronDown className="hidden size-4 group-[.show]/item:inline-block"></ChevronDown>
                      <ChevronUp className="inline-block size-4 group-[.show]/item:hidden"></ChevronUp>
                    </div>
                  </Collapse.Trigger>
                  <Collapse.Content className="mt-2 mb-0 collapsible-content card">
                    <div className="card-body">

                      {characterTraits && characterTraits?.kpis?.length > 0 ? (
                        <Formik
                          initialValues={{ characterTraitsData }} // Initialize with your characterTraitsData object
                          validationSchema={performanceValidationSchema}
                          onSubmit={async (values) => {
                            console.log('Submitted characterTraits values', values);
                            await dispatch(onAppraisePerformance(values.characterTraitsData));
                            await dispatch(onGetAppraisalPerformance(values.characterTraitsData.appraisal_id));
                          }}
                        >
                          {({ values, setFieldValue }) => (
                            <Form>
                              <div className="flex gap-4 mb-2 justify-end">
                                <label className="text-lg font-medium">
                                  N/A: 0
                                </label>
                                <label className="text-lg font-medium">
                                  Very Weak: 1
                                </label>
                                <label className="text-lg font-medium">
                                  Weak: 2
                                </label>
                                <label className="text-lg font-medium">
                                  Satisfactory: 3
                                </label>
                                <label className="text-lg font-medium">
                                  Good: 4
                                </label>
                                <label className="text-lg font-medium">
                                  Outstanding: 5
                                </label>
                              </div>
  
                              <FieldArray name="characterTraitsData.kpis">
                                {({ push, remove }) => (
                                  <div>
                                    {values?.characterTraitsData?.kpis.map((kpi:any, index:any) => (
                                      <div key={index} className="border rounded-md mb-4">
                                        <div className="px-3.5 py-2.5 border-b dark:border-zink-500">
                                          <div className="flex flex-wrap gap-2">
                                            <label className="block w-full text-lg font-medium">{kpi.item}</label>
  
                                            {/* Radio buttons for selecting marks (0-5) */}
                                            {[0, 1, 2, 3, 4, 5].map((value) => (
                                              <div key={value} className="flex items-center gap-2">
                                                <Field
                                                  id={`characterTraitsData.kpis[${index}].mark_${value}`} // Unique ID for each radio button
                                                  name={`characterTraitsData.kpis[${index}].mark`} // Correct path to 'mark' field
                                                  type="radio"
                                                  value={value} // The radio button's value
                                                  checked={kpi.mark === value}
                                                  disabled={!user.isAdmin}
                                                  onChange={() => setFieldValue(`characterTraitsData.kpis[${index}].mark`, value)} // Explicitly set the field value
                                                  className="size-4 border rounded-full appearance-none cursor-pointer bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 checked:border-custom-500 dark:checked:bg-custom-500 dark:checked:border-custom-500"
                                                />
                                                <label htmlFor={`characterTraitsData.kpis[${index}].mark_${value}`}>{value}</label>
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </FieldArray>
  
                              <div className="border rounded-md mb-4">
                                <div className="px-3.5 py-2.5 border-b dark:border-zink-500">
                                  <div className="flex gap-4">
                                    <label className="text-lg font-medium">
                                      Total: <CountUp end={characterTraits?.kpis?.length * 5} className="counter-value" />
                                    </label>
                                    <label className="text-lg font-medium">
                                      Score: <CountUp end={characterTraits?.total_mark} className="counter-value" />
                                    </label>
                                    <label className="text-lg font-medium">
                                      Percentage: <CountUp end={characterTraits?.percentage} className="counter-value" />%
                                    </label>
                                  </div>
                                </div>
                              </div>
  
                              {/* Submit Button */}
                              <div className="flex justify-end gap-2 mt-4">
                              {user.isAdmin && data?.status !== 'approved' ? 
                                <button type="submit" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                  {!!isEdit ? "Update" : "Save and Continue"}
                                </button>
                              : ''}
                              </div>
  
                            </Form>
                          )}
                        </Formik>
                      ) : <></>}

                    </div>
                  </Collapse.Content>
                </Collapse>

                <Collapse>
                  <Collapse.Trigger className="flex text-white collapsible-header group/item btn w-full py-3 px-6 text-lg bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                    
                    <span>Effectiveness of communication</span>
                    <span className="ml-auto pr-2">Max Score: {effectiveComm?.max_score}</span>
                    <div className="ltr:ml-2 rtl:mr-2 shrink-0">
                      <ChevronDown className="hidden size-4 group-[.show]/item:inline-block"></ChevronDown>
                      <ChevronUp className="inline-block size-4 group-[.show]/item:hidden"></ChevronUp>
                    </div>
                  </Collapse.Trigger>
                  <Collapse.Content className="mt-2 mb-0 collapsible-content card">
                    <div className="card-body">

                      {effectiveComm && effectiveComm?.kpis?.length > 0 ? (
                        <Formik
                          initialValues={{ effectiveCommData }} // Initialize with your effectiveCommData object
                          validationSchema={performanceValidationSchema}
                          onSubmit={async (values) => {
                            console.log('Submitted effectiveComm values', values);
                            await dispatch(onAppraisePerformance(values.effectiveCommData));
                            await dispatch(onGetAppraisalPerformance(values.effectiveCommData.appraisal_id));
                          }}
                        >
                          {({ values, setFieldValue }) => (
  
                            <Form>
                              <div className="flex gap-4 mb-2 justify-end">
                                <label className="text-lg font-medium">
                                  N/A: 0
                                </label>
                                <label className="text-lg font-medium">
                                  Very Weak: 1
                                </label>
                                <label className="text-lg font-medium">
                                  Weak: 2
                                </label>
                                <label className="text-lg font-medium">
                                  Satisfactory: 3
                                </label>
                                <label className="text-lg font-medium">
                                  Good: 4
                                </label>
                                <label className="text-lg font-medium">
                                  Outstanding: 5
                                </label>
                              </div>
  
                              <FieldArray name="effectiveCommData.kpis">
                                {({ push, remove }) => (
                                  <div>
                                    {values?.effectiveCommData?.kpis.map((kpi:any, index:any) => (
                                      <div key={index} className="border rounded-md mb-4">
                                        <div className="px-3.5 py-2.5 border-b dark:border-zink-500">
                                          <div className="flex flex-wrap gap-2">
                                            <label className="block w-full text-lg font-medium">{kpi.item}</label>
  
                                            {/* Radio buttons for selecting marks (0-5) */}
                                            {[0, 1, 2, 3, 4, 5].map((value) => (
                                              <div key={value} className="flex items-center gap-2">
                                                <Field
                                                  id={`effectiveCommData.kpis[${index}].mark_${value}`} // Unique ID for each radio button
                                                  name={`effectiveCommData.kpis[${index}].mark`} // Correct path to 'mark' field
                                                  type="radio"
                                                  value={value} // The radio button's value
                                                  checked={kpi.mark === value}
                                                  disabled={!user.isAdmin}
                                                  onChange={() => setFieldValue(`effectiveCommData.kpis[${index}].mark`, value)} // Explicitly set the field value
                                                  className="size-4 border rounded-full appearance-none cursor-pointer bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 checked:border-custom-500 dark:checked:bg-custom-500 dark:checked:border-custom-500"
                                                />
                                                <label htmlFor={`effectiveCommData.kpis[${index}].mark_${value}`}>{value}</label>
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </FieldArray>
  
                              <div className="border rounded-md mb-4">
                                <div className="px-3.5 py-2.5 border-b dark:border-zink-500">
                                  <div className="flex gap-4">
                                    <label className="text-lg font-medium">
                                      Total: <CountUp end={effectiveComm?.kpis?.length * 5} className="counter-value" />
                                    </label>
                                    <label className="text-lg font-medium">
                                      Score: <CountUp end={effectiveComm?.total_mark} className="counter-value" />
                                    </label>
                                    <label className="text-lg font-medium">
                                      Percentage: <CountUp end={effectiveComm?.percentage} className="counter-value" />%
                                    </label>
                                  </div>
                                </div>
                              </div>
  
                              {/* Submit Button */}
                              <div className="flex justify-end gap-2 mt-4">
                              {user.isAdmin && data?.status !== 'approved' ? 
                                <button type="submit" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                  {!!isEdit ? "Update" : "Save and Continue"}
                                </button>
                              : ''}
                              </div>
  
                            </Form>
                          )}
                        </Formik>
                      ) : <></>}

                    </div>
                  </Collapse.Content>
                </Collapse>

                <Collapse>
                  <Collapse.Trigger className="flex text-white collapsible-header group/item btn w-full py-3 px-6 text-lg bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                    
                    <span>Work Habits</span>
                    <span className="ml-auto pr-2">Max Score: {workHabit?.max_score}</span>
                    <div className="ltr:ml-2 rtl:mr-2 shrink-0">
                      <ChevronDown className="hidden size-4 group-[.show]/item:inline-block"></ChevronDown>
                      <ChevronUp className="inline-block size-4 group-[.show]/item:hidden"></ChevronUp>
                    </div>
                  </Collapse.Trigger>
                  <Collapse.Content className="mt-2 mb-0 collapsible-content card">
                    <div className="card-body">

                      {workHabit && workHabit?.kpis?.length > 0 ? (
                        <Formik
                          initialValues={{ workHabitData }} // Initialize with your workHabitData object
                          validationSchema={performanceValidationSchema}
                          onSubmit={async (values) => {
                            console.log('Submitted Work Habits values', values);
                            await dispatch(onAppraisePerformance(values.workHabitData));
                            await dispatch(onGetAppraisalPerformance(values.workHabitData.appraisal_id));
                          }}
                        >
                          {({ values, setFieldValue }) => (
                            <Form>
                              <div className="flex gap-4 mb-2 justify-end">
                                <label className="text-lg font-medium">
                                  N/A: 0
                                </label>
                                <label className="text-lg font-medium">
                                  Very Weak: 1
                                </label>
                                <label className="text-lg font-medium">
                                  Weak: 2
                                </label>
                                <label className="text-lg font-medium">
                                  Satisfactory: 3
                                </label>
                                <label className="text-lg font-medium">
                                  Good: 4
                                </label>
                                <label className="text-lg font-medium">
                                  Outstanding: 5
                                </label>
                              </div>
                              
                              <FieldArray name="workHabitData.kpis">
                                {({ push, remove }) => (
                                  <div>
                                    {values?.workHabitData?.kpis.map((kpi:any, index:any) => (
                                      <div key={index} className="border rounded-md mb-4">
                                        <div className="px-3.5 py-2.5 border-b dark:border-zink-500">
                                          <div className="flex flex-wrap gap-2">
                                            <label className="block w-full text-lg font-medium">{kpi.item}</label>
  
                                            {/* Radio buttons for selecting marks (0-5) */}
                                            {[0, 1, 2, 3, 4, 5].map((value) => (
                                              <div key={value} className="flex items-center gap-2">
                                                <Field
                                                  id={`workHabitData.kpis[${index}].mark_${value}`} // Unique ID for each radio button
                                                  name={`workHabitData.kpis[${index}].mark`} // Correct path to 'mark' field
                                                  type="radio"
                                                  value={value} // The radio button's value
                                                  checked={kpi.mark === value}
                                                  disabled={!user.isAdmin}
                                                  onChange={() => setFieldValue(`workHabitData.kpis[${index}].mark`, value)} // Explicitly set the field value
                                                  className="size-4 border rounded-full appearance-none cursor-pointer bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 checked:border-custom-500 dark:checked:bg-custom-500 dark:checked:border-custom-500"
                                                />
                                                <label htmlFor={`workHabitData.kpis[${index}].mark_${value}`}>{value}</label>
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </FieldArray>
  
                              <div className="border rounded-md mb-4">
                                <div className="px-3.5 py-2.5 border-b dark:border-zink-500">
                                  <div className="flex gap-4">
                                    <label className="text-lg font-medium">
                                      Total: <CountUp end={workHabit?.kpis?.length * 5} className="counter-value" />
                                    </label>
                                    <label className="text-lg font-medium">
                                      Score: <CountUp end={workHabit?.total_mark} className="counter-value" />
                                    </label>
                                    <label className="text-lg font-medium">
                                      Percentage: <CountUp end={workHabit?.percentage} className="counter-value" />%
                                    </label>
                                  </div>
                                </div>
                              </div>
  
                              {/* Submit Button */}
                              <div className="flex justify-end gap-2 mt-4">
                              {user.isAdmin && data?.status !== 'approved' ? 
                                <button type="submit" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                  {!!isEdit ? "Update" : "Save and Continue"}
                                </button>
                              : ''}
                              </div>
  
                            </Form>
                          )}
                        </Formik>
                      ) : <></>}

                    </div>
                  </Collapse.Content>
                </Collapse>

                <Collapse>
                  <Collapse.Trigger className="flex text-white collapsible-header group/item btn w-full py-3 px-6 text-lg bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                    
                    <span>Operational Service Delivery Quality</span>
                    <span className="ml-auto pr-2">Max Score: 70</span>
                    <div className="ltr:ml-2 rtl:mr-2 shrink-0">
                      <ChevronDown className="hidden size-4 group-[.show]/item:inline-block"></ChevronDown>
                      <ChevronUp className="inline-block size-4 group-[.show]/item:hidden"></ChevronUp>
                    </div>
                  </Collapse.Trigger>
                  <Collapse.Content className="mt-2 mb-0 collapsible-content card">
                    <div className="card-body">

                      {amiResearch && amiResearch?.kpis?.length > 0 ? (
                        <Formik
                        initialValues={{ amiResearchData  }} // Initialize with your amiResearchData  object
                        validationSchema={performanceValidationSchema}
                        onSubmit={async (values) => {
                          console.log('Submitted osdq values', values);
                          await dispatch(onAppraisePerformance(values.amiResearchData));
                          await dispatch(onGetAppraisalPerformance(values.amiResearchData.appraisal_id));
                        }}
                      >
                        {({ values, setFieldValue }) => (
                          <Form>

                            <div className="border rounded-md mb-4 mt-4">
                              <div className="px-3.5 py-2.5 border-b dark:border-zink-500">
                                <div className="flex gap-4">
                                  <h2 className="text-xl font-bold">AMI Research</h2>
                                </div>
                              </div>
                            </div>

                            <FieldArray name="amiResearchData.kpis">
                              {({ push, remove }) => (
                                <div>
                                  {values?.amiResearchData?.kpis.map((kpi:any, index:any) => (
                                    <div key={index} className="border rounded-md mb-4">
                                      <div className="px-3.5 py-2.5 border-b dark:border-zink-500">
                                        <div className="flex flex-wrap gap-2">
                                          <label className="block w-full text-lg font-medium">{kpi.item}</label>

                                          {/* Radio buttons for selecting marks (0-5) */}
                                          {[0, 1, 2, 3, 4, 5].map((value) => (
                                            <div key={value} className="flex items-center gap-2">
                                              <Field
                                                id={`amiResearchData.kpis[${index}].mark_${value}`} // Unique ID for each radio button
                                                name={`amiResearchData.kpis[${index}].mark`} // Correct path to 'mark' field
                                                type="radio"
                                                value={value} // The radio button's value
                                                checked={kpi.mark === value} 
                                                disabled={values?.amiResearchData?.team_lead_id !== user.id}
                                                onChange={() => setFieldValue(`amiResearchData.kpis[${index}].mark`, value)} // Explicitly set the field value
                                                className="size-4 border rounded-full appearance-none cursor-pointer bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 checked:border-custom-500 dark:checked:bg-custom-500 dark:checked:border-custom-500"
                                              />
                                              <label htmlFor={`amiResearchData.kpis[${index}].mark_${value}`}>{value}</label>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </FieldArray>

                            <div className="border rounded-md mb-4">
                              <div className="px-3.5 py-2.5 border-b dark:border-zink-500">
                                <div className="flex gap-4">
                                  <label className="text-lg font-medium">
                                    Total: <CountUp end={amiResearch?.kpis?.length * 5} className="counter-value" />
                                  </label>
                                  <label className="text-lg font-medium">
                                    Score: <CountUp end={amiResearch?.total_mark} className="counter-value" />
                                  </label>
                                  <label className="text-lg font-medium">
                                    Percentage: <CountUp end={amiResearch?.percentage} className="counter-value" />%
                                  </label>
                                </div>
                              </div>
                            </div>

                            {/* Submit Button */}
                            <div className="flex justify-end gap-2 mt-4">
                            {values?.amiResearchData?.team_lead_id == user.id && data?.status !== 'approved' ? 
                              <button type="submit" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                {!!isEdit ? "Update" : "Save and Continue"}
                              </button>
                            : ''}
                            </div>

                          </Form>
                        )}
                        </Formik>
                       ) : <></>}

                      {miResearch && miResearch?.kpis?.length > 0 ? (
                        <Formik
                        initialValues={{ miResearchData  }} // Initialize with your miResearchData  object
                        validationSchema={performanceValidationSchema}
                        onSubmit={async (values) => {
                          console.log('Submitted osdq values', values);
                          await dispatch(onAppraisePerformance(values.miResearchData));
                          await dispatch(onGetAppraisalPerformance(values.miResearchData.appraisal_id));
                        }}
                      >
                        {({ values, setFieldValue }) => (
                          <Form>

                            <div className="border rounded-md mb-4 mt-4">
                              <div className="px-3.5 py-2.5 border-b dark:border-zink-500">
                                <div className="flex gap-4">
                                  <h2 className="text-xl font-bold">Macro & Industry Research</h2>
                                </div>
                              </div>
                            </div>

                            <FieldArray name="miResearchData.kpis">
                              {({ push, remove }) => (
                                <div>
                                  {values?.miResearchData?.kpis.map((kpi:any, index:any) => (
                                    <div key={index} className="border rounded-md mb-4">
                                      <div className="px-3.5 py-2.5 border-b dark:border-zink-500">
                                        <div className="flex flex-wrap gap-2">
                                          <label className="block w-full text-lg font-medium">{kpi.item}</label>

                                          {/* Radio buttons for selecting marks (0-5) */}
                                          {[0, 1, 2, 3, 4, 5].map((value) => (
                                            <div key={value} className="flex items-center gap-2">
                                              <Field
                                                id={`miResearchData.kpis[${index}].mark_${value}`} // Unique ID for each radio button
                                                name={`miResearchData.kpis[${index}].mark`} // Correct path to 'mark' field
                                                type="radio"
                                                value={value} // The radio button's value
                                                checked={kpi.mark === value} 
                                                disabled={values?.miResearchData?.team_lead_id !== user.id}
                                                onChange={() => setFieldValue(`miResearchData.kpis[${index}].mark`, value)} // Explicitly set the field value
                                                className="size-4 border rounded-full appearance-none cursor-pointer bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 checked:border-custom-500 dark:checked:bg-custom-500 dark:checked:border-custom-500"
                                              />
                                              <label htmlFor={`miResearchData.kpis[${index}].mark_${value}`}>{value}</label>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </FieldArray>

                            <div className="border rounded-md mb-4">
                              <div className="px-3.5 py-2.5 border-b dark:border-zink-500">
                                <div className="flex gap-4">
                                  <label className="text-lg font-medium">
                                    Total: <CountUp end={miResearch?.kpis?.length * 5} className="counter-value" />
                                  </label>
                                  <label className="text-lg font-medium">
                                    Score: <CountUp end={miResearch?.total_mark} className="counter-value" />
                                  </label>
                                  <label className="text-lg font-medium">
                                    Percentage: <CountUp end={miResearch?.percentage} className="counter-value" />%
                                  </label>
                                </div>
                              </div>
                            </div>

                            {/* Submit Button */}
                            <div className="flex justify-end gap-2 mt-4">
                            {values?.miResearchData?.team_lead_id == user.id && data?.status !== 'approved' ? 
                              <button type="submit" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                {!!isEdit ? "Update" : "Save and Continue"}
                              </button>
                            : ''}
                            </div>

                          </Form>
                        )}
                        </Formik>
                       ) : <></>}

                      {training && training?.kpis?.length > 0 ? (
                        <Formik
                        initialValues={{ trainingData  }} // Initialize with your trainingData  object
                        validationSchema={performanceValidationSchema}
                        onSubmit={async(values) => {
                          console.log('Submitted osdq values', values);
                          await dispatch(onAppraisePerformance(values.trainingData));
                          await dispatch(onGetAppraisalPerformance(values.trainingData.appraisal_id));
                        }}
                      >
                        {({ values, setFieldValue }) => (
                          <Form>

                            <div className="border rounded-md mb-4 mt-4">
                              <div className="px-3.5 py-2.5 border-b dark:border-zink-500">
                                <div className="flex gap-4">
                                  <h2 className="text-xl font-bold">Training</h2>
                                </div>
                              </div>
                            </div>

                            <FieldArray name="trainingData.kpis">
                              {({ push, remove }) => (
                                <div>
                                  {values?.trainingData?.kpis.map((kpi:any, index:any) => (
                                    <div key={index} className="border rounded-md mb-4">
                                      <div className="px-3.5 py-2.5 border-b dark:border-zink-500">
                                        <div className="flex flex-wrap gap-2">
                                          <label className="block w-full text-lg font-medium">{kpi.item}</label>

                                          {/* Radio buttons for selecting marks (0-5) */}
                                          {[0, 1, 2, 3, 4, 5].map((value) => (
                                            <div key={value} className="flex items-center gap-2">
                                              <Field
                                                id={`trainingData.kpis[${index}].mark_${value}`} // Unique ID for each radio button
                                                name={`trainingData.kpis[${index}].mark`} // Correct path to 'mark' field
                                                type="radio"
                                                value={value} // The radio button's value
                                                checked={kpi.mark === value} 
                                                disabled={values?.trainingData?.team_lead_id !== user.id}
                                                onChange={() => setFieldValue(`trainingData.kpis[${index}].mark`, value)} // Explicitly set the field value
                                                className="size-4 border rounded-full appearance-none cursor-pointer bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 checked:border-custom-500 dark:checked:bg-custom-500 dark:checked:border-custom-500"
                                              />
                                              <label htmlFor={`trainingData.kpis[${index}].mark_${value}`}>{value}</label>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </FieldArray>

                            <div className="border rounded-md mb-4">
                              <div className="px-3.5 py-2.5 border-b dark:border-zink-500">
                                <div className="flex gap-4">
                                  <label className="text-lg font-medium">
                                    Total: <CountUp end={training?.kpis?.length * 5} className="counter-value" />
                                  </label>
                                  <label className="text-lg font-medium">
                                    Score: <CountUp end={training?.total_mark} className="counter-value" />
                                  </label>
                                  <label className="text-lg font-medium">
                                    Percentage: <CountUp end={training?.percentage} className="counter-value" />%
                                  </label>
                                </div>
                              </div>
                            </div>

                            {/* Submit Button */}
                            <div className="flex justify-end gap-2 mt-4">
                            {values?.trainingData?.team_lead_id == user.id && data?.status !== 'approved' ? 
                              <button type="submit" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                {!!isEdit ? "Update" : "Save and Continue"}
                              </button>
                            : ''}
                            </div>

                          </Form>
                        )}
                        </Formik>
                       ) : <></>}

                      {clientSpecificReport && clientSpecificReport?.kpis?.length > 0 ? (
                        <Formik
                        initialValues={{ clientSpecificReportData  }} // Initialize with your clientSpecificReportData  object
                        validationSchema={performanceValidationSchema}
                        onSubmit={async (values) => {
                          console.log('Submitted osdq values', values);
                          await dispatch(onAppraisePerformance(values.clientSpecificReportData));
                          await dispatch(onGetAppraisalPerformance(values.clientSpecificReportData.appraisal_id));
                        }}
                      >
                        {({ values, setFieldValue }) => (
                          <Form>

                            <div className="border rounded-md mb-4 mt-4">
                              <div className="px-3.5 py-2.5 border-b dark:border-zink-500">
                                <div className="flex gap-4">
                                  <h2 className="text-xl font-bold">Consulting/Client Specific Reports</h2>
                                </div>
                              </div>
                            </div>

                            <FieldArray name="clientSpecificReportData.kpis">
                              {({ push, remove }) => (
                                <div>
                                  {values?.clientSpecificReportData?.kpis.map((kpi:any, index:any) => (
                                    <div key={index} className="border rounded-md mb-4">
                                      <div className="px-3.5 py-2.5 border-b dark:border-zink-500">
                                        <div className="flex flex-wrap gap-2">
                                          <label className="block w-full text-lg font-medium">{kpi.item}</label>

                                          {/* Radio buttons for selecting marks (0-5) */}
                                          {[0, 1, 2, 3, 4, 5].map((value) => (
                                            <div key={value} className="flex items-center gap-2">
                                              <Field
                                                id={`clientSpecificReportData.kpis[${index}].mark_${value}`} // Unique ID for each radio button
                                                name={`clientSpecificReportData.kpis[${index}].mark`} // Correct path to 'mark' field
                                                type="radio"
                                                value={value} // The radio button's value
                                                checked={kpi.mark === value} 
                                                disabled={values?.clientSpecificReportData?.team_lead_id !== user.id}
                                                onChange={() => setFieldValue(`clientSpecificReportData.kpis[${index}].mark`, value)} // Explicitly set the field value
                                                className="size-4 border rounded-full appearance-none cursor-pointer bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 checked:border-custom-500 dark:checked:bg-custom-500 dark:checked:border-custom-500"
                                              />
                                              <label htmlFor={`clientSpecificReportData.kpis[${index}].mark_${value}`}>{value}</label>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </FieldArray>

                            <div className="border rounded-md mb-4">
                              <div className="px-3.5 py-2.5 border-b dark:border-zink-500">
                                <div className="flex gap-4">
                                  <label className="text-lg font-medium">
                                    Total: <CountUp end={clientSpecificReport?.kpis?.length * 5} className="counter-value" />
                                  </label>
                                  <label className="text-lg font-medium">
                                    Score: <CountUp end={clientSpecificReport?.total_mark} className="counter-value" />
                                  </label>
                                  <label className="text-lg font-medium">
                                    Percentage: <CountUp end={clientSpecificReport?.percentage} className="counter-value" />%
                                  </label>
                                </div>
                              </div>
                            </div>

                            {/* Submit Button */}
                            <div className="flex justify-end gap-2 mt-4">
                            {values?.clientSpecificReportData?.team_lead_id == user.id && data?.status !== 'approved' ? 
                              <button type="submit" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                {!!isEdit ? "Update" : "Save and Continue"}
                              </button>
                            : ''}
                            </div>

                          </Form>
                        )}
                        </Formik>
                       ) : <></>}

                      {ratings && ratings?.kpis?.length > 0 ? (
                        <Formik
                        initialValues={{ ratingsData  }} // Initialize with your ratingsData  object
                        validationSchema={performanceValidationSchema}
                        onSubmit={async (values) => {
                          console.log('Submitted osdq values', values);
                          await dispatch(onAppraisePerformance(values.ratingsData));
                          await dispatch(onGetAppraisalPerformance(values.ratingsData.appraisal_id));
                        }}
                      >
                        {({ values, setFieldValue }) => (
                          <Form>

                            <div className="border rounded-md mb-4 mt-4">
                              <div className="px-3.5 py-2.5 border-b dark:border-zink-500">
                                <div className="flex gap-4">
                                  <h2 className="text-xl font-bold">Ratings</h2>
                                </div>
                              </div>
                            </div>

                            <FieldArray name="ratingsData.kpis">
                              {({ push, remove }) => (
                                <div>
                                  {values?.ratingsData?.kpis.map((kpi:any, index:any) => (
                                    <div key={index} className="border rounded-md mb-4">
                                      <div className="px-3.5 py-2.5 border-b dark:border-zink-500">
                                        <div className="flex flex-wrap gap-2">
                                          <label className="block w-full text-lg font-medium">{kpi.item}</label>

                                          {/* Radio buttons for selecting marks (0-5) */}
                                          {[0, 1, 2, 3, 4, 5].map((value) => (
                                            <div key={value} className="flex items-center gap-2">
                                              <Field
                                                id={`ratingsData.kpis[${index}].mark_${value}`} // Unique ID for each radio button
                                                name={`ratingsData.kpis[${index}].mark`} // Correct path to 'mark' field
                                                type="radio"
                                                value={value} // The radio button's value
                                                checked={kpi.mark === value} 
                                                disabled={!user.isAdmin}
                                                onChange={() => setFieldValue(`ratingsData.kpis[${index}].mark`, value)} // Explicitly set the field value
                                                className="size-4 border rounded-full appearance-none cursor-pointer bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 checked:border-custom-500 dark:checked:bg-custom-500 dark:checked:border-custom-500"
                                              />
                                              <label htmlFor={`ratingsData.kpis[${index}].mark_${value}`}>{value}</label>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </FieldArray>

                            <div className="border rounded-md mb-4">
                              <div className="px-3.5 py-2.5 border-b dark:border-zink-500">
                                <div className="flex gap-4">
                                  <label className="text-lg font-medium">
                                    Total: <CountUp end={ratings?.kpis?.length * 5} className="counter-value" />
                                  </label>
                                  <label className="text-lg font-medium">
                                    Score: <CountUp end={ratings?.total_mark} className="counter-value" />
                                  </label>
                                  <label className="text-lg font-medium">
                                    Percentage: <CountUp end={ratings?.percentage} className="counter-value" />%
                                  </label>
                                </div>
                              </div>
                            </div>

                            {/* Submit Button */}
                            <div className="flex justify-end gap-2 mt-4">
                            {user.isAdmin && data?.status !== 'approved' ? 
                              <button type="submit" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                {!!isEdit ? "Update" : "Save and Continue"}
                              </button>
                            : ''}
                            </div>

                          </Form>
                        )}
                        </Formik>
                       ) : <></>}

                      {businessSupport && businessSupport?.kpis?.length > 0 ? (
                        <Formik
                          initialValues={{ businessSupportData  }} // Initialize with your businessSupportData  object
                          validationSchema={performanceValidationSchema}
                          onSubmit={async (values) => {
                            console.log('Submitted osdq values', values);
                            await dispatch(onAppraisePerformance(values.businessSupportData));
                            await dispatch(onGetAppraisalPerformance(values.businessSupportData.appraisal_id));
                          }}
                        >
                          {({ values, setFieldValue }) => (
                            <Form>
  
                              <div className="flex gap-4 mb-2 justify-end">
                                <label className="text-lg font-medium">
                                  N/A: 0
                                </label>
                                <label className="text-lg font-medium">
                                  Very Weak: 1
                                </label>
                                <label className="text-lg font-medium">
                                  Weak: 2
                                </label>
                                <label className="text-lg font-medium">
                                  Satisfactory: 3
                                </label>
                                <label className="text-lg font-medium">
                                  Good: 4
                                </label>
                                <label className="text-lg font-medium">
                                  Outstanding: 5
                                </label>
                              </div>
  
                              <div className="border rounded-md mb-4 mt-4">
                                <div className="px-3.5 py-2.5 border-b dark:border-zink-500">
                                  <div className="flex gap-4">
                                      <h2 className="text-xl font-bold">Business Support</h2>
                                  </div>
                                </div>
                              </div>
  
                              <FieldArray name="businessSupportData.kpis">
                                {({ push, remove }) => (
                                  <div>
                                    {values?.businessSupportData?.kpis.map((kpi:any, index:any) => (
                                      <div key={index} className="border rounded-md mb-4">
                                        <div className="px-3.5 py-2.5 border-b dark:border-zink-500">
                                          <div className="flex flex-wrap gap-2">
                                            <label className="block w-full text-lg font-medium">{kpi.item}</label>
  
                                            {/* Radio buttons for selecting marks (0-5) */}
                                            {[0, 1, 2, 3, 4, 5].map((value) => (
                                              <div key={value} className="flex items-center gap-2">
                                                <Field
                                                  id={`businessSupportData.kpis[${index}].mark_${value}`} // Unique ID for each radio button
                                                  name={`businessSupportData.kpis[${index}].mark`} // Correct path to 'mark' field
                                                  type="radio"
                                                  value={value} // The radio button's value
                                                  checked={kpi.mark === value} 
                                                  disabled={!user.isAdmin}
                                                  onChange={() => setFieldValue(`businessSupportData.kpis[${index}].mark`, value)} // Explicitly set the field value
                                                  className="size-4 border rounded-full appearance-none cursor-pointer bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 checked:border-custom-500 dark:checked:bg-custom-500 dark:checked:border-custom-500"
                                                />
                                                <label htmlFor={`businessSupportData.kpis[${index}].mark_${value}`}>{value}</label>
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </FieldArray>
  
                              <div className="border rounded-md mb-4">
                                <div className="px-3.5 py-2.5 border-b dark:border-zink-500">
                                  <div className="flex gap-4">
                                    <label className="text-lg font-medium">
                                      Total: <CountUp end={businessSupport?.kpis?.length * 5} className="counter-value" />
                                    </label>
                                    <label className="text-lg font-medium">
                                      Score: <CountUp end={businessSupport?.total_mark} className="counter-value" />
                                    </label>
                                    <label className="text-lg font-medium">
                                      Percentage: <CountUp end={businessSupport?.percentage} className="counter-value" />%
                                    </label>
                                  </div>
                                </div>
                              </div>
  
                              {/* Submit Button */}
                              <div className="flex justify-end gap-2 mt-4">
                              {user.isAdmin && data?.status !== 'approved' ? 
                                <button type="submit" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                  {!!isEdit ? "Update" : "Save and Continue"}
                                </button>
                              : ''}
                              </div>
  
                            </Form>
                          )}
                        </Formik>
                      ) : <></>}
                      
                    </div>
                  </Collapse.Content>
                </Collapse>

                <Collapse>
                  <Collapse.Trigger className="flex text-white collapsible-header group/item btn w-full py-3 px-6 text-lg bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                    
                    <span>Supervisor Remark</span>
                    <span className="ml-auto pr-2">Max Score: 0</span>
                    <div className="ltr:ml-2 rtl:mr-2 shrink-0">
                      <ChevronDown className="hidden size-4 group-[.show]/item:inline-block"></ChevronDown>
                      <ChevronUp className="inline-block size-4 group-[.show]/item:hidden"></ChevronUp>
                    </div>
                  </Collapse.Trigger>
                  <Collapse.Content className="mt-2 mb-0 collapsible-content card">
                    <div className="card-body">
                      <Formik
                        initialValues={payload} // Initialize with your peopleRelationData object
                        validationSchema={validation}
                        onSubmit={(values) => {
                          console.log('Submitted values', values);
                          dispatch(onUpdateAppraisal(values));
                        }}>
                          {({ values, setFieldValue, handleChange, handleBlur}) => (
                            
                            <Form>
                              <div className="grid grid-cols-1 gap-4 xl:grid-cols-12">
                                <div className="xl:col-span-12">
                                  <label
                                    htmlFor="goalInput"
                                    className="inline-block mb-2 text-base font-medium"
                                  >
                                    Supervisors Comment
                                  </label>
                                  <textarea
                                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                    id="goalInput"
                                    name="remark"
                                    placeholder="State the remark for the period"
                                    value={values?.remark}
                                    onChange={(e) => setFieldValue('remark', e.target.value)}
                                    onBlur={handleBlur}
                                    disabled={!user.isAdmin}
                                    rows={4}
                                  ></textarea>
                                </div>
                              </div>

                              <div className="flex justify-end gap-2 mt-4">
                                {user.isAdmin && data?.status !== 'approved' ? 
                                  <button type="submit" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                    {!!isEdit ? "Update" : "Save and Continue"}
                                  </button>
                                : ''}
                              </div>
                          </Form>
                          )}
                      </Formik>
                    </div>
                  </Collapse.Content>
                </Collapse>

                <Collapse>
                  <Collapse.Trigger className="flex text-white collapsible-header group/item btn w-full py-3 px-6 text-lg bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                    
                    <span>Staff Comment</span>
                    <span className="ml-auto pr-2">Max Score: 0</span>
                    <div className="ltr:ml-2 rtl:mr-2 shrink-0">
                      <ChevronDown className="hidden size-4 group-[.show]/item:inline-block"></ChevronDown>
                      <ChevronUp className="inline-block size-4 group-[.show]/item:hidden"></ChevronUp>
                    </div>
                  </Collapse.Trigger>
                  <Collapse.Content className="mt-2 mb-0 collapsible-content card">
                    <div className="card-body">

                    <Formik
                      initialValues={payload} // Initialize with your peopleRelationData object
                      validationSchema={validation}
                      onSubmit={(values) => {
                        console.log('Submitted values', values);
                        dispatch(onUpdateAppraisal(values));
                      }}>
                        {({ values, setFieldValue, handleChange, handleBlur}) => (
                          
                          <Form>
                            <div className="grid grid-cols-1 gap-4 xl:grid-cols-12">
                              <div className="xl:col-span-12">
                                <label
                                  htmlFor="goalInput"
                                  className="inline-block mb-2 text-base font-medium"
                                >
                                  Add your Comment
                                </label>
                                <textarea
                                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                  id="goalInput"
                                  name="comment"
                                  placeholder="State the remark for the period"
                                  value={values?.comment}
                                  onChange={(e) => setFieldValue('comment', e.target.value)}
                                  onBlur={handleBlur}
                                  disabled={user.isAdmin}
                                  rows={4}
                                ></textarea>
                              </div>
                            </div>

                            <div className="flex justify-end gap-2 mt-4">
                            {!user.isAdmin && data?.status !== 'approved' ? 
                              <button type="submit" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                {!!isEdit ? "Update" : "Save and Continue"}
                              </button>
                            : ''}
                            </div>
                        </Form>
                        )}
                      </Formik>
                    </div>
                  </Collapse.Content>
                </Collapse>

                <Collapse>
                  <Collapse.Trigger className="flex text-white collapsible-header group/item btn w-full py-3 px-6 text-lg bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                    
                    <span>Action</span>
                    <span className="ml-auto pr-2"></span>
                    <div className="ltr:ml-2 rtl:mr-2 shrink-0">
                      <ChevronDown className="hidden size-4 group-[.show]/item:inline-block"></ChevronDown>
                      <ChevronUp className="inline-block size-4 group-[.show]/item:hidden"></ChevronUp>
                    </div>
                  </Collapse.Trigger>
                  <Collapse.Content className="mt-2 mb-0 collapsible-content card">
                    <div className="card-body">

                    <Formik
                      initialValues={payload} // Initialize with your peopleRelationData object
                      validationSchema={validation}
                      onSubmit={(values) => {
                        console.log('Submitted values', values);
                        dispatch(onUpdateAppraisal(values));
                      }}>
                        {({ values, setFieldValue, handleChange, handleBlur}) => (
                          
                          <Form>
                            <div className="grid grid-cols-1 gap-4 xl:grid-cols-12">
                              <div className="xl:col-span-12">
                                <label
                                  htmlFor="ActionInput"
                                  className="inline-block mb-2 text-base font-medium"
                                >
                                  Action
                                </label>
                                <textarea
                                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                  id="ActionInput"
                                  name="action"
                                  placeholder="State the remark for the period"
                                  value={values?.action}
                                  onChange={(e) => setFieldValue('action', e.target.value)}
                                  onBlur={handleBlur}
                                  disabled={!user.isSuperAdmin}
                                  rows={4}
                                ></textarea>
                              </div>
                            </div>

                            <div className="flex justify-end gap-2 mt-4">
                            {user.isSuperAdmin && data?.status !== 'approved' ? 
                              <button type="submit" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                {!!isEdit ? "Update" : "Save and Continue"}
                              </button>
                            : ''}
                            </div>
                        </Form>
                        )}
                      </Formik>
                    </div>
                  </Collapse.Content>
                </Collapse>
              </div>

              <div className="card-footer mt-4 mb-4">

                <div className="flex justify-center gap-2">

                  {!user?.isAdmin && data?.status === 'appraised' ?
                    <button type="submit" className="text-white btn bg-green-500 border-green-500 hover:text-white hover:bg-green-600 hover:border-green-600 focus:text-white focus:bg-green-600 focus:border-green-600 focus:ring focus:ring-green-100 active:text-white active:bg-green-600 active:border-green-600 active:ring active:ring-green-100 dark:ring-green-400/20"
                      onClick={() => {
                        onClickApproval(data?.id, 'review');
                      }}
                    >
                      Review
                    </button>
                  : ''}

                  {user?.isAdmin && data?.status !== 'approved' ? 
                    <button type="submit" className="text-white btn bg-green-500 border-green-500 hover:text-white hover:bg-green-600 hover:border-green-600 focus:text-white focus:bg-green-600 focus:border-green-600 focus:ring focus:ring-green-100 active:text-white active:bg-green-600 active:border-green-600 active:ring active:ring-green-100 dark:ring-green-400/20"
                    onClick={() => {
                      onClickApproval(data?.id, 'appraise');
                    }}
                    >
                      Update Appraisal
                    </button>
                  : ''}

                  {user?.isAdmin && data?.status === 'reviewed' ? 
                    <button type="submit" className="text-white btn bg-red-500 border-red-500 hover:text-white hover:bg-red-600 hover:border-red-600 focus:text-white focus:bg-red-600 focus:border-red-600 focus:ring focus:ring-red-100 active:text-white active:bg-red-600 active:border-red-600 active:ring active:ring-red-100 dark:ring-red-400/20"
                    onClick={() => {
                      onClickApproval(data?.id, 'close');
                    }}
                    >
                      Close Appraisal
                    </button>
                  : ''}

                  {user?.isSuperAdmin && data?.status === 'closed' ? 
                    <button type="submit" className="text-white btn bg-green-500 border-green-500 hover:text-white hover:bg-green-600 hover:border-green-600 focus:text-white focus:bg-green-600 focus:border-green-600 focus:ring focus:ring-green-100 active:text-white active:bg-green-600 active:border-green-600 active:ring active:ring-green-100 dark:ring-green-400/20"
                    onClick={() => {
                      onClickApproval(data?.id, 'approve');
                    }}
                    >
                      Approve Appraisal
                    </button>
                  : ''}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AppraisalDetails;
