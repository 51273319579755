import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getEmployee as getEmployeeApi,
  addEmployee as addEmployeeApi,
  updateEmployee as updateEmployeeApi,
  deleteEmployee as deleteEmployeeApi,
  getHolidays as getHolidaysApi,
  addHolidays as addHolidaysApi,
  updateHolidays as updateHolidaysApi,
  deleteHolidays as deleteHolidaysApi,
  //   getDepartments as getDepartmentsApi,
  getEstimates as getEstimatesApi,
  addEstimates as addEstimatesApi,
  updateEstimates as updateEstimatesApi,
  deleteEstimates as deleteEstimatesApi,
  getExpenses as getExpensesApi,
  addExpenses as addExpensesApi,
  updateExpenses as updateExpensesApi,
  deleteExpenses as deleteExpensesApi,
  getLeaveManageHR as getLeaveManageHRApi,
  addLeaveManageHR as addLeaveManageHRApi,
  updateLeaveManageHR as updateLeaveManageHRApi,
  deleteLeaveManageHR as deleteLeaveManageHRApi,
  getEmployeeSalary as getEmployeeSalaryApi,
  addEmployeeSalary as addEmployeeSalaryApi,
  updateEmployeeSalary as updateEmployeeSalaryApi,
  deleteEmployeeSalary as deleteEmployeeSalaryApi,
  getAttendance as getAttendanceApi,
  getMainAttendance as getMainAttendanceApi,
  getLeaveManageEmployee as getLeaveManageEmployeeApi,
  getPayments as getPaymentsApi,
} from "../../helpers/fakebackend_helper";

import {
  getDashboardAdminRequests as getDashboardAdminRequestsApi,
  getRoles as getRolesApi,
  getActiveRoles as getActiveRolesApi,
  getRoleDetails as getRoleDetailsApi,
  addRole as addRoleApi,
  updateRole as updateRoleApi,
  deleteRole as deleteRoleApi,
  getDepartments as getDepartmentsApi,
  getDepartmentDetails as getDepartmentDetailsApi,
  getActiveDepartments as getActiveDepartmentsApi,
  addDepartment as addDepartmentsApi,
  updateDepartment as updateDepartmentsApi,
  deleteDepartment as deleteDepartmentsApi,
  getUserCategory as getUserCategoryApi,
  getActiveUserCategory as getActiveUserCategoryApi,
  getUserCategoryDetails as getUserCategoryDetailsApi,
  addUserCategory as addUserCategoryApi,
  updateUserCategory as updateUserCategoryApi,
  deleteUserCategory as deleteUserCategoryApi,
  getPositions as getPositionsApi,
  getActivePositions as getActivePositionsApi,
  getPositionDetails as getPositionDetailsApi,
  addPosition as addPositionApi,
  updatePosition as updatePositionApi,
  deletePosition as deletePositionApi,
  getAppraisalCategories as getAppraisalCategoriesApi,
  getAppraisalCategoryDetails as getAppraisalCategoryDetailsApi,
  getActiveAppraisalCategories as getActiveAppraisalCategoriesApi,
  checkOpenedAppraisalCategories as checkOpenedAppraisalCategoriesApi,
  addAppraisalCategory as addAppraisalCategoryApi,
  updateAppraisalCategory as updateAppraisalCategoryApi,
  deleteAppraisalCategory as deleteAppraisalCategoryApi,
  getAppraisalKpis as getAppraisalKpisApi,
  getAppraisalKpiDetails as getAppraisalKpiDetailsApi,
  getActiveAppraisalKpis as getActiveAppraisalKpisApi,
  addAppraisalKpi as addAppraisalKpiApi,
  updateAppraisalKpi as updateAppraisalKpiApi,
  deleteAppraisalKpi as deleteAppraisalKpiApi,
  getAppraisals as getAppraisalsApi,
  getUserAppraisals as getUserAppraisalsApi,
  getColleagueAppraisals as getColleagueAppraisalsApi,
  getSupervisorAppraisals as getSupervisorAppraisalsApi,
  getAppraisalSupervisorMetrix as getAppraisalSupervisorMetrixApi,
  getAppraisalDetails as getAppraisalDetailsApi,
  getActiveAppraisals as getActiveAppraisalsApi,
  appraisePerformance as appraisePerformanceApi,
  createAppraisal as createAppraisalApi,
  updateAppraisal as updateAppraisalApi,
  deleteAppraisal as deleteAppraisalApi,
  appraisalRequestStatus as appraisalRequestStatusApi,

  updateColleagueAppraise as updateColleagueAppraiseApi,
  getUserColleagueRelations as getUserColleagueRelationsApi,
  getColleagueRelationsDetails as getColleagueRelationsDetailsApi,
  getTeamLeadPerformances as getTeamLeadPerformancesApi,
  getAppraisalPerformance as getAppraisalPerformanceApi,
  getAppraisalParameter as getAppraisalParameterApi,

  getWfh as getWfhApi,
  getWfhDetails as getWfhDetailsApi,
  getWfhBoard as getWfhBoardApi,
  getSupervisorWfh as getSupervisorWfhApi,
  getActiveWfh as getActiveWfhApi,
  addWfh as addWfhApi,
  updateWfh as updateWfhApi,
  deleteWfh as deleteWfhApi,
  rejectWfh as rejectWfhApi,
  getUserWfh as getUserWfhApi,
  getLeaveTypes as getLeaveTypesApi,
  getLeaveTypeDetails as getLeaveTypeDetailsApi,
  getActiveLeaveTypes as getActiveLeaveTypesApi,
  createLeaveType as createLeaveTypeApi,
  updateLeaveType as updateLeaveTypeApi,
  deleteLeaveType as deleteLeaveTypeApi,
  getLeaveApplications as getLeaveApplicationsApi,
  getUserLeaveApplications as getUserLeaveApplicationsApi,
  getSupervisorLeaveMetrix as getSupervisorLeaveMetrixApi,
  getLeaveApplicationDetails as getLeaveApplicationDetailsApi,
  getActiveLeaveApplications as getActiveLeaveApplicationsApi,
  getSupervisorLeaveApplications as getSupervisorLeaveApplicationsApi,
  leaveApplicationApproval as leaveApplicationApprovalApi,
  createLeaveApplication as createLeaveApplicationApi,
  updateLeaveApplication as updateLeaveApplicationApi,
  deleteLeaveApplication as deleteLeaveApplicationApi,
  getAllTask as getAllTaskApi,
  getTaskBoard as getTaskBoardApi,
  getTaskMetrix as getTaskMetrixApi,
  getDepartmentalTask as getDepartmentalTaskApi,
  getTaskDetails as getTaskDetailsApi,
  getActiveTask as getActiveTaskApi,
  addTask as addTaskApi,
  updateTask as updateTaskApi,
  deleteTask as deleteTaskApi,
  getUserTask as getUserTaskApi,
} from "services/http/api_service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Dashboard
export const getDashboardAdminRequests = createAsyncThunk(
  "hrManagement/getDashboardAdminRequests",
  async () => {
    try {
      const response: any = await getDashboardAdminRequestsApi();
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);

// Roles

export const getRoles = createAsyncThunk(
  "hrManagement/getRoles",
  async (params: any) => {
    try {
      const response: any = await getRolesApi(params);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const getActiveRoles = createAsyncThunk(
  "hrManagement/getActiveRoles",
  async () => {
    try {
      const response: any = await getActiveRolesApi();
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const getRoleDetails = createAsyncThunk(
  "admin/getRoleDetails",
  async () => {
    try {
      const response: any = await getRoleDetailsApi();
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);

export const addRole = createAsyncThunk(
  "hrManagement/getRoles",
  async (event: any) => {
    try {
      const success: any = await addRoleApi(event);
      const added: any = await success?.data;
      if (added.code === 200) {
        toast.success(added?.message, { autoClose: 2000 });

        const response: any = await getRolesApi({
          page: 1,
          per_page: 12,
        });
        return response?.data;
      } else {
        toast.error("something went wrong", { autoClose: 2000 });
      }
    } catch (error) {
      toast.error("Unable to add Role", { autoClose: 2000 });
      return error;
    }
  }
);

export const updateRole = createAsyncThunk(
  "hrManagement/getRoles",
  async (event: any) => {
    try {
      const success: any = await updateRoleApi(event);
      const updated: any = await success?.data;
      if (updated.code === 200) {
        toast.success(updated?.message, { autoClose: 2000 });

        const response: any = await getRolesApi({
          page: 1,
          per_page: 12,
        });
        return response?.data;
      } else {
        toast.error("something went wrong", { autoClose: 2000 });
      }
    } catch (error) {
      toast.error("Unable to update role", { autoClose: 2000 });
      return error;
    }
  }
);

export const deleteRole = createAsyncThunk(
  "hrManagement/getRoles",
  async (event: any) => {
    try {
      const success: any = await deleteRoleApi(event);
      const deleted: any = await success?.data;
      if (deleted.code === 200) {
        toast.success(deleted?.message, { autoClose: 2000 });

        const response: any = await getRolesApi({
          page: 1,
          per_page: 12,
        });
        return response?.data;
      } else {
        toast.error("something went wrong", { autoClose: 2000 });
      }
    } catch (error) {
      toast.error("Unable to delete Role", { autoClose: 2000 });
      return error;
    }
  }
);

//Department
export const getDepartments = createAsyncThunk(
  "hrManagement/getDepartments",
  async (params: any) => {
    try {
      const response: any = await getDepartmentsApi(params);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const getDepartmentDetails = createAsyncThunk(
  "hrManagement/getDepartmentDetails",
  async (id: any) => {
    try {
      const response: any = await getDepartmentDetailsApi(id);
      console.log(response);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const getActiveDepartments = createAsyncThunk(
  "hrManagement/getActiveDepartments",
  async () => {
    try {
      const response: any = await getActiveDepartmentsApi();
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const addDepartments = createAsyncThunk(
  "hrManagement/getDepartments",
  async (event: any) => {
    try {
      const added: any = await addDepartmentsApi(event);

      const response: any = await added?.data;

      if (response.code === 200) {
        toast.success(response?.message, { autoClose: 2000 });

        const departments: any = await getDepartmentsApi({
          page: 1,
          per_page: 12,
        });
        return departments?.data;
      } else {
        toast.error("something went wrong", { autoClose: 2000 });
      }
    } catch (error) {
      toast.error("Departments Added Failed", { autoClose: 2000 });
      return error;
    }
  }
);
export const updateDepartments = createAsyncThunk(
  "hrManagement/getDepartments",
  async (event: any) => {
    try {
      const success: any = await updateDepartmentsApi(event);

      const updated: any = await success?.data;

      if (updated.code === 200) {
        toast.success(updated?.message, { autoClose: 2000 });

        const response: any = await getDepartmentsApi({
          page: 1,
          per_page: 12,
        });
        return response?.data;
      } else {
        toast.error("something went wrong", { autoClose: 2000 });
      }
    } catch (error) {
      toast.error("Departments updated Failed", { autoClose: 2000 });
      return error;
    }
  }
);
export const deleteDepartments = createAsyncThunk(
  "hrManagement/getDepartments",
  async (event: any) => {
    try {
      const success: any = await deleteDepartmentsApi(event);

      const deleted: any = await success?.data;

      if (deleted.code === 200) {
        toast.success(deleted?.message, { autoClose: 2000 });

        const response: any = await getDepartmentsApi({
          page: 1,
          per_page: 12,
        });
        return response?.data;
      } else {
        toast.error("something went wrong", { autoClose: 2000 });
      }
    } catch (error) {
      toast.error("Departments deleted Failed", { autoClose: 2000 });
      return error;
    }
  }
);

// Positions
export const getPositions = createAsyncThunk(
  "hrManagement/getPositions",
  async (params: any) => {
    try {
      const response: any = await getPositionsApi(params);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const getActivePositions = createAsyncThunk(
  "hrManagement/getActivePositions",
  async () => {
    try {
      const response: any = await getActivePositionsApi();
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);

export const getPositionDetails = createAsyncThunk(
  "hrManagement/getPositionDetails",
  async (id: any) => {
    try {
      const response: any = await getPositionDetailsApi(id);
      console.log(response);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);

export const addPosition = createAsyncThunk(
  "hrManagement/getPositions",
  async (event: any) => {
    try {
      const success: any = await addPositionApi(event);

      const added: any = await success?.data;

      if (added.code === 200) {
        toast.success(added?.message, { autoClose: 2000 });

        const response: any = await getPositionsApi({ page: 1, per_page: 12 });
        return response?.data;
      } else {
        toast.error("something went wrong", { autoClose: 2000 });
      }
    } catch (error) {
      toast.error("Position Added Failed", { autoClose: 2000 });
      return error;
    }
  }
);

export const updatePosition = createAsyncThunk(
  "hrManagement/getPositions",
  async (event: any) => {
    try {
      const success: any = await updatePositionApi(event);
      const updated: any = await success?.data;

      if (updated.code === 200) {
        toast.success(updated?.message, { autoClose: 2000 });

        const response: any = await getPositionsApi({ page: 1, per_page: 12 });
        return response?.data;
      } else {
        toast.error("something went wrong", { autoClose: 2000 });
      }
    } catch (error) {
      toast.error("Departments updated Failed", { autoClose: 2000 });
      return error;
    }
  }
);

export const deletePosition = createAsyncThunk(
  "hrManagement/getPositions",
  async (id: any) => {
    try {
      const success: any = await deletePositionApi(id);
      const deleted: any = await success?.data;

      if (deleted.code === 200) {
        toast.success(deleted?.message, { autoClose: 2000 });

        const response: any = await getPositionsApi({ page: 1, per_page: 12 });
        return response?.data;
      } else {
        toast.error("something went wrong", { autoClose: 2000 });
      }
    } catch (error) {
      toast.error("Departments deleted Failed", { autoClose: 2000 });
      return error;
    }
  }
);

// User Category
export const getUserCategory = createAsyncThunk(
  "hrManagement/getUserCategory",
  async (params: any) => {
    try {
      const response: any = await getUserCategoryApi(params);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);

export const getActiveUserCategory = createAsyncThunk(
  "hrManagement/getActiveUserCategory",
  async () => {
    try {
      const response: any = await getActiveUserCategoryApi();
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);

export const getUserCategoryDetails = createAsyncThunk(
  "hrManagement/getUserCategoryDetails",
  async (id: any) => {
    try {
      const response: any = await getUserCategoryDetailsApi(id);
      console.log(response);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);

export const addUserCategory = createAsyncThunk(
  "hrManagement/getUserCategory",
  async (event: any) => {
    try {
      const success: any = await addUserCategoryApi(event);

      const added: any = await success?.data;

      if (added.code === 200) {
        toast.success(added?.message, { autoClose: 2000 });

        const response: any = await getUserCategoryApi({
          page: 1,
          per_page: 12,
        });
        return response?.data;
      } else {
        toast.error("something went wrong", { autoClose: 2000 });
      }
    } catch (error) {
      toast.error("User Category Added Failed", { autoClose: 2000 });
      return error;
    }
  }
);

export const updateUserCategory = createAsyncThunk(
  "hrManagement/getUserCategory",
  async (event: any) => {
    try {
      const success: any = await updateUserCategoryApi(event);

      const updated: any = await success?.data;

      if (updated.code === 200) {
        toast.success(updated.message, { autoClose: 2000 });

        const response: any = await getUserCategoryApi({
          page: 1,
          per_page: 12,
        });
        return response?.data;
      } else {
        toast.error("something went wrong", { autoClose: 2000 });
      }
    } catch (error) {
      toast.error("Category updated Failed", { autoClose: 2000 });
      return error;
    }
  }
);

export const deleteUserCategory = createAsyncThunk(
  "hrManagement/deleteUserCategory",
  async (id: string) => {
    try {
      const success: any = await deleteUserCategoryApi(id);
      const deleted: any = await success?.data;
      if (deleted.code === 200) {
        toast.success(deleted.message, { autoClose: 2000 });

        const response: any = await getUserCategoryApi({
          page: 1,
          per_page: 12,
        });
        return response?.data;
      } else {
        toast.error("something went wrong", { autoClose: 2000 });
      }
    } catch (error) {
      toast.error("Departments deleted Failed", { autoClose: 2000 });
      return error;
    }
  }
);

//Appraisal Categories
export const getAppraisalCategories = createAsyncThunk(
  "hrManagement/getAppraisalCategories",
  async (params: any) => {
    try {
      const response: any = await getAppraisalCategoriesApi(params);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const getAppraisalCategoryDetails = createAsyncThunk(
  "hrManagement/getAppraisalCategoryDetails",
  async (id: any) => {
    try {
      const response: any = await getAppraisalCategoryDetailsApi(id);
      console.log(response);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const getActiveAppraisalCategories = createAsyncThunk(
  "hrManagement/getActiveAppraisalCategories",
  async () => {
    try {
      const response: any = await getActiveAppraisalCategoriesApi();
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const checkOpenedAppraisalCategories = createAsyncThunk(
  "hrManagement/checkOpenedAppraisalCategories",
  async () => {
    try {
      const response: any = await checkOpenedAppraisalCategoriesApi();
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const addAppraisalCategory = createAsyncThunk(
  "hrManagement/getAppraisalCategories",
  async (event: any) => {
    try {
      const success: any = await addAppraisalCategoryApi(event);

      const added: any = await success?.data;

      if (added.code === 200) {
        await toast.success(added?.message, { autoClose: 2000 });
      } else {
        await toast.error(added?.meassage, { autoClose: 2000 });
      }
    } catch (error: any) {
      console.log("error", error);
      await toast.error(error?.response?.data || error?.message, {
        autoClose: 2000,
      });
    }

    const response: any = await getAppraisalCategoriesApi({
      page: 1,
      per_page: 12,
    });
    return response?.data;
  }
);
export const updateAppraisalCategory = createAsyncThunk(
  "hrManagement/getAppraisalCategories",
  async (event: any) => {
    try {
      const success: any = await updateAppraisalCategoryApi(event);

      const updated: any = await success?.data;

      if (updated.code === 200) {
        await toast.success(updated?.message, { autoClose: 2000 });
      } else {
        await toast.error(updated?.meassage, { autoClose: 2000 });
      }
    } catch (error: any) {
      console.log("error", error);
      await toast.error(error?.response?.data || error?.message, {
        autoClose: 2000,
      });
    }

    const response: any = await getAppraisalCategoriesApi({
      page: 1,
      per_page: 12,
    });
    return response?.data;
  }
);
export const deleteAppraisalCategory = createAsyncThunk(
  "hrManagement/getAppraisalCategories",
  async (event: any) => {
    try {
      const success: any = await deleteAppraisalCategoryApi(event);

      const deleted: any = await success?.data;

      if (deleted.code === 200) {
        await toast.success(deleted?.message, { autoClose: 2000 });
      } else {
        await toast.error(deleted?.meassage, { autoClose: 2000 });
      }
    } catch (error: any) {
      console.log("error", error);
      await toast.error(error?.response?.data || error?.message, {
        autoClose: 2000,
      });
    }

    const response: any = await getAppraisalCategoriesApi({
      page: 1,
      per_page: 12,
    });
    return response?.data;
  }
);

//Appraisal KPI
export const getAppraisalKpis = createAsyncThunk(
  "hrManagement/getAppraisalKpis",
  async (params: any) => {
    try {
      const response: any = await getAppraisalKpisApi(params);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const getAppraisalKpiDetails = createAsyncThunk(
  "hrManagement/getAppraisalKpiDetails",
  async (id: any) => {
    try {
      const response: any = await getAppraisalKpiDetailsApi(id);
      console.log(response);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const getActiveAppraisalKpis = createAsyncThunk(
  "hrManagement/getActiveAppraisalKpis",
  async () => {
    try {
      const response: any = await getActiveAppraisalKpisApi();
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const addAppraisalKpi = createAsyncThunk(
  "hrManagement/getAppraisalKpis",
  async (event: any) => {
    try {
      const success: any = await addAppraisalKpiApi(event);

      const added: any = await success?.data;

      if (added.code === 200) {
        await toast.success(added?.message, { autoClose: 2000 });
      } else {
        await toast.error(added?.meassage, { autoClose: 2000 });
      }
    } catch (error: any) {
      console.log("error", error);
      await toast.error(error?.response?.data || error?.message, {
        autoClose: 2000,
      });
    }

    const response: any = await getAppraisalKpisApi({ page: 1, per_page: 12 });
    return response?.data;
  }
);
export const updateAppraisalKpi = createAsyncThunk(
  "hrManagement/getAppraisalKpis",
  async (event: any) => {
    try {
      const success: any = await updateAppraisalKpiApi(event);

      const updated: any = await success?.data;

      if (updated.code === 200) {
        await toast.success(updated?.message, { autoClose: 2000 });
      } else {
        await toast.error(updated?.meassage, { autoClose: 2000 });
      }
    } catch (error: any) {
      console.log("error", error);
      await toast.error(error?.response?.data || error?.message, {
        autoClose: 2000,
      });
    }

    const response: any = await getAppraisalKpisApi({ page: 1, per_page: 12 });
    return response?.data;
  }
);
export const deleteAppraisalKpi = createAsyncThunk(
  "hrManagement/getAppraisalKpis",
  async (event: any) => {
    try {
      const success: any = await deleteAppraisalKpiApi(event);

      const deleted: any = await success?.data;

      if (deleted.code === 200) {
        await toast.success(deleted?.message, { autoClose: 2000 });
      } else {
        await toast.error(deleted?.meassage, { autoClose: 2000 });
      }
    } catch (error: any) {
      console.log("error", error);
      await toast.error(error?.response?.data || error?.message, {
        autoClose: 2000,
      });
    }

    const response: any = await getAppraisalKpisApi({ page: 1, per_page: 12 });
    return response?.data;
  }
);

//Appraisals
export const getAppraisals = createAsyncThunk(
  "hrManagement/getAppraisals",
  async (params: any) => {
    try {
      const response: any = await getAppraisalsApi(params);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const getUserAppraisals = createAsyncThunk(
  "hrManagement/getUserAppraisals",
  async (params: any) => {
    try {
      const response: any = await getUserAppraisalsApi(params);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const getColleagueAppraisals = createAsyncThunk(
  "hrManagement/getColleagueAppraisals",
  async (params: any) => {
    try {
      const response:any = await getColleagueAppraisalsApi(params);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const getSupervisorAppraisals = createAsyncThunk(
  "hrManagement/getSupervisorAppraisals",
  async (params: any) => {
    try {
      const response: any = await getSupervisorAppraisalsApi(params);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const getAppraisalDetails = createAsyncThunk(
  "hrManagement/getAppraisalDetails",
  async (id: any) => {
    try {
      const response: any = await getAppraisalDetailsApi(id);
      console.log(response);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const getActiveAppraisals = createAsyncThunk(
  "hrManagement/getActiveAppraisals",
  async () => {
    try {
      const response: any = await getActiveAppraisalsApi();
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const getAppraisalSupervisorMetrix = createAsyncThunk(
  "hrManagement/getAppraisalSupervisorMetrix",
  async () => {
    try {
      const response: any = await getAppraisalSupervisorMetrixApi();
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const appraisalRequestStatus = createAsyncThunk(
  "hrManagement/getAppraisalDetails",
  async (params: any) => {
    try {
      const success: any = await appraisalRequestStatusApi(
        params?.id,
        params?.status
      );
      console.log(success);

      const response: any = await getAppraisalDetailsApi(params?.id);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const createAppraisal = createAsyncThunk(
  "hrManagement/getUserAppraisals",
  async (event: any) => {
    try {
      const success: any = await createAppraisalApi(event);

      const added: any = await success?.data;

      if (added.code === 200) {
        await toast.success(added?.message, { autoClose: 2000 });
      } else {
        await toast.error(added?.meassage, { autoClose: 2000 });
      }
    } catch (error: any) {
      console.log("error", error);
      await toast.error(error?.response?.data || error?.message, {
        autoClose: 2000,
      });
    }

    const response: any = await getUserAppraisalsApi({ page: 1, per_page: 12 });
    return response?.data;
  }
);
export const updateAppraisal = createAsyncThunk(
  "hrManagement/getAppraisalDetails",
  async (event: any) => {
    try {
      const success: any = await updateAppraisalApi(event);

      const updated: any = await success?.data;

      if (updated.code === 200) {
        await toast.success(updated?.message, { autoClose: 2000 });
      } else {
        await toast.error(updated?.meassage, { autoClose: 2000 });
      }
    } catch (error: any) {
      console.log("error", error);
      await toast.error(error?.response?.data || error?.message, {
        autoClose: 2000,
      });
    }

    const response: any = await getAppraisalDetailsApi(event?.id);
    return response?.data;
  }
);
export const appraisePerformance = createAsyncThunk(
  "hrManagement/getAppraisalDetails",
  async (event: any) => {
    try {
      const success: any = await appraisePerformanceApi(event);

      const updated: any = await success?.data;

      if (updated.code === 200) {
        await toast.success(updated?.message, { autoClose: 2000 });
      } else {
        await toast.error(updated?.meassage, { autoClose: 2000 });
      }
    } catch (error: any) {
      console.log("error", error);
      await toast.error(error?.response?.data || error?.message, {
        autoClose: 2000,
      });
    }

    const response: any = await getAppraisalDetailsApi(event?.appraisal_id);
    return response?.data;
  }
);
export const deleteAppraisal = createAsyncThunk(
  "hrManagement/getUserAppraisals",
  async (event: any) => {
    try {
      const success: any = await deleteAppraisalApi(event);

      const deleted: any = await success?.data;

      if (deleted.code === 200) {
        await toast.success(deleted?.message, { autoClose: 2000 });
      } else {
        await toast.error(deleted?.meassage, { autoClose: 2000 });
      }
    } catch (error: any) {
      console.log("error", error);
      await toast.error(error?.response?.data || error?.message, {
        autoClose: 2000,
      });
    }

    const response: any = await getUserAppraisalsApi({ page: 1, per_page: 12 });
    return response?.data;
  }
);

//Appraisal Performance
export const getUserColleagueRelations = createAsyncThunk(
  "hrManagement/getUserColleagueRelations",
  async () => {
    try {
      const response: any = await getUserColleagueRelationsApi();
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const getColleagueRelationsDetails = createAsyncThunk(
  "hrManagement/getColleagueRelationsDetails",
  async (id: any) => {
    try {
      const response:any = await getColleagueRelationsDetailsApi(id);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const getAppraisalPerformance = createAsyncThunk(
  "hrManagement/getAppraisalPerformance",
  async (id: any) => {
    try {
      const response: any = await getAppraisalPerformanceApi(id);
      console.log(response);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const getTeamLeadPerformances = createAsyncThunk(
  "hrManagement/getTeamLeadPerformances",
  async (id: any) => {
    try {
      const response:any = await getTeamLeadPerformancesApi(id);
      console.log(response);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const getAppraisalParameter = createAsyncThunk(
  "hrManagement/getAppraisalParameter",
  async (params: any) => {
    try {
      const response:any = await getAppraisalParameterApi(params.appraisal_id, params.parameter);
      console.log(response);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const updateColleagueAppraise = createAsyncThunk(
  "hrManagement/getUserColleagueRelations",
  async (event: any) => {
    try {

      const success:any = await updateColleagueAppraiseApi(event);

      const updated:any = await success?.data;

      if (updated.code === 200) {
        await toast.success(updated?.message, { autoClose: 2000 });
      } else {
        await toast.error(updated?.meassage, { autoClose: 2000 });
      }
    } catch (error:any) {
      console.log('error', error);
      await toast.error(error?.response?.data || error?.message, { autoClose: 2000 });
    }

    const response:any = await getUserColleagueRelationsApi();
    return response?.data;
  }
);

export const updateAppraisePerformance = createAsyncThunk(
  "hrManagement/getTeamLeadPerformances",
  async (event: any) => {
    try {

      const success:any = await appraisePerformanceApi(event);

      const updated:any = await success?.data;

      if (updated.code === 200) {
        await toast.success(updated?.message, { autoClose: 2000 });
      } else {
        await toast.error(updated?.meassage, { autoClose: 2000 });
      }
    } catch (error:any) {
      console.log('error', error);
      await toast.error(error?.response?.data || error?.message, { autoClose: 2000 });
    }

    const response:any = await getTeamLeadPerformancesApi(event?.appraisal_id);
    return response?.data;
  }
);

//WFH
export const getWfh = createAsyncThunk(
  "hrManagement/getWfh",
  async (params: any) => {
    try {
      const response: any = await getWfhApi(params);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const getWfhDetails = createAsyncThunk(
  "hrManagement/getWfhDetails",
  async (id: any) => {
    try {
      const response: any = await getWfhDetailsApi(id);
      console.log(response);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const getWfhBoard = createAsyncThunk(
  "hrManagement/getWfhBoard",
  async (params: any) => {
    try {
      const response: any = await getWfhBoardApi(params);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const getSupervisorWfh = createAsyncThunk(
  "hrManagement/getSupervisorWfh",
  async () => {
    try {
      const response: any = await getSupervisorWfhApi();
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const getActiveWfh = createAsyncThunk(
  "hrManagement/getActiveWfh",
  async () => {
    try {
      const response: any = await getActiveWfhApi();
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const addWfh = createAsyncThunk(
  "hrManagement/getWfhBoard",
  async (event: any) => {
    try {
      const success: any = await addWfhApi(event);

      const added: any = await success?.data;

      if (added.code === 200) {
        await toast.success(added?.message, { autoClose: 2000 });
      } else {
        await toast.error(added?.meassage, { autoClose: 2000 });
      }
    } catch (error: any) {
      console.log("error", error);
      await toast.error(error?.response?.data || error?.message, {
        autoClose: 2000,
      });
    }

    const response: any = await getWfhBoardApi({ search: "", date: "" });
    return response?.data;
  }
);
export const updateWfh = createAsyncThunk(
  "hrManagement/getWfhBoard",
  async (event: any) => {
    try {
      const success: any = await updateWfhApi(event);

      const updated: any = await success?.data;

      if (updated.code === 200) {
        await toast.success(updated?.message, { autoClose: 2000 });
      } else {
        await toast.error(updated?.meassage, { autoClose: 2000 });
      }
    } catch (error: any) {
      console.log("error", error);
      await toast.error(error?.response?.data || error?.message, {
        autoClose: 2000,
      });
    }

    const response: any = await getWfhBoardApi({ search: "", date: "" });
    return response?.data;
  }
);
export const deleteWfh = createAsyncThunk(
  "hrManagement/getWfhBoard",
  async (event: any) => {
    try {
      const success: any = await deleteWfhApi(event);

      const deleted: any = await success?.data;

      if (deleted.code === 200) {
        await toast.success(deleted?.message, { autoClose: 2000 });
      } else {
        await toast.error(deleted?.meassage, { autoClose: 2000 });
      }
    } catch (error: any) {
      console.log("error", error);
      await toast.error(error?.response?.data || error?.message, {
        autoClose: 2000,
      });
    }

    const response: any = await getWfhBoardApi({ search: "", date: "" });
    return response?.data;
  }
);
export const rejectWfh = createAsyncThunk(
  "hrManagement/getSupervisorWfh",
  async (event: any) => {
    try {
      const success: any = await rejectWfhApi(event);

      const deleted: any = await success?.data;

      if (deleted.code === 200) {
        toast.success(deleted?.message, { autoClose: 2000 });

        const response: any = await getSupervisorWfhApi();
        const dashboardResponse: any = await getDashboardAdminRequests();
        return response?.data;
      } else {
        toast.error("something went wrong", { autoClose: 2000 });
      }
    } catch (error) {
      toast.error("Departments deleted Failed", { autoClose: 2000 });
      return error;
    }
  }
);
export const getUserWfh = createAsyncThunk(
  "hrManagement/getUserWfh",
  async (params: any) => {
    try {
      const response: any = await getUserWfhApi(params);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);

//Leave Type
export const getLeaveTypes = createAsyncThunk(
  "hrManagement/getLeaveTypes",
  async (params: any) => {
    try {
      const response: any = await getLeaveTypesApi(params);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const getLeaveTypeDetails = createAsyncThunk(
  "hrManagement/getLeaveTypeDetails",
  async (id: any) => {
    try {
      const response: any = await getLeaveTypeDetailsApi(id);
      console.log(response);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const getActiveLeaveTypes = createAsyncThunk(
  "hrManagement/getActiveLeaveTypes",
  async () => {
    try {
      const response: any = await getActiveLeaveTypesApi();
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const createLeaveType = createAsyncThunk(
  "hrManagement/getLeaveTypes",
  async (event: any) => {
    try {
      const success: any = await createLeaveTypeApi(event);

      const added: any = await success?.data;

      if (added.code === 200) {
        await toast.success(added?.message, { autoClose: 2000 });
      } else {
        await toast.error(added?.meassage, { autoClose: 2000 });
      }
    } catch (error: any) {
      console.log("error", error);
      await toast.error(error?.response?.data || error?.message, {
        autoClose: 2000,
      });
    }

    const response: any = await getLeaveTypesApi({ page: 1, per_page: 12 });
    return response?.data;
  }
);
export const updateLeaveType = createAsyncThunk(
  "hrManagement/getLeaveTypes",
  async (event: any) => {
    try {
      const success: any = await updateLeaveTypeApi(event);

      const updated: any = await success?.data;

      if (updated.code === 200) {
        await toast.success(updated?.message, { autoClose: 2000 });
      } else {
        await toast.error(updated?.meassage, { autoClose: 2000 });
      }
    } catch (error: any) {
      console.log("error", error);
      await toast.error(error?.response?.data || error?.message, {
        autoClose: 2000,
      });
    }

    const response: any = await getLeaveTypesApi({ page: 1, per_page: 12 });
    return response?.data;
  }
);
export const deleteLeaveType = createAsyncThunk(
  "hrManagement/getLeaveTypes",
  async (event: any) => {
    try {
      const success: any = await deleteLeaveTypeApi(event);

      const deleted: any = await success?.data;

      if (deleted.code === 200) {
        await toast.success(deleted?.message, { autoClose: 2000 });
      } else {
        await toast.error(deleted?.meassage, { autoClose: 2000 });
      }
    } catch (error: any) {
      console.log("error", error);
      await toast.error(error?.response?.data || error?.message, {
        autoClose: 2000,
      });
    }

    const response: any = await getLeaveTypesApi({ page: 1, per_page: 12 });
    return response?.data;
  }
);

//Leave Application
export const getLeaveApplications = createAsyncThunk(
  "hrManagement/getLeaveApplications",
  async (params: any) => {
    try {
      const response: any = await getLeaveApplicationsApi(params);
      console.log(response?.data);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const getSupervisorLeaveMetrix = createAsyncThunk(
  "hrManagement/getSupervisorLeaveMetrix",
  async () => {
    try {
      const response: any = await getSupervisorLeaveMetrixApi();
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const getUserLeaveApplications = createAsyncThunk(
  "hrManagement/getUserLeaveApplications",
  async (params: any) => {
    try {
      const response: any = await getUserLeaveApplicationsApi(params);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const getSupervisorLeaveApplications = createAsyncThunk(
  "hrManagement/getSupervisorLeaveApplications",
  async (params: any) => {
    try {
      const response: any = await getSupervisorLeaveApplicationsApi(params);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const getLeaveApplicationDetails = createAsyncThunk(
  "hrManagement/getLeaveApplicationDetails",
  async (id: any) => {
    try {
      const response: any = await getLeaveApplicationDetailsApi(id);
      console.log(response);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const leaveApplicationApproval = createAsyncThunk(
  "hrManagement/getSupervisorLeaveApplications",
  async (params: any) => {
    try {
      const success: any = await leaveApplicationApprovalApi(
        params?.id,
        params?.status
      );
      console.log(success);

      const response: any = await getSupervisorLeaveApplicationsApi({
        page: 1,
        per_page: 12,
      });
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const getActiveLeaveApplications = createAsyncThunk(
  "hrManagement/getActiveLeaveApplications",
  async () => {
    try {
      const response: any = await getActiveLeaveApplicationsApi();
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const createLeaveApplication = createAsyncThunk(
  "hrManagement/getUserLeaveApplications",
  async (event: any) => {
    try {
      const success: any = await createLeaveApplicationApi(event);

      const added: any = await success?.data;

      if (added.code === 200) {
        await toast.success(added?.message, { autoClose: 2000 });
      } else {
        await toast.error(added?.meassage, { autoClose: 2000 });
      }
    } catch (error: any) {
      console.log("error", error);
      await toast.error(error?.response?.data || error?.message, {
        autoClose: 2000,
      });
    }

    const response: any = await getUserLeaveApplicationsApi({
      page: 1,
      per_page: 12,
    });
    return response?.data;
  }
);
export const updateLeaveApplication = createAsyncThunk(
  "hrManagement/getUserLeaveApplications",
  async (event: any) => {
    try {
      const success: any = await updateLeaveApplicationApi(event);

      const updated: any = await success?.data;

      if (updated.code === 200) {
        await toast.success(updated?.message, { autoClose: 2000 });
      } else {
        await toast.error(updated?.meassage, { autoClose: 2000 });
      }
    } catch (error: any) {
      console.log("error", error);
      await toast.error(error?.response?.data || error?.message, {
        autoClose: 2000,
      });
    }

    const response: any = await getUserLeaveApplicationsApi({
      page: 1,
      per_page: 12,
    });
    return response?.data;
  }
);
export const deleteLeaveApplication = createAsyncThunk(
  "hrManagement/getUserLeaveApplications",
  async (event: any) => {
    try {
      const success: any = await deleteLeaveApplicationApi(event);

      const deleted: any = await success?.data;

      if (deleted.code === 200) {
        await toast.success(deleted?.message, { autoClose: 2000 });
      } else {
        await toast.error(deleted?.meassage, { autoClose: 2000 });
      }
    } catch (error: any) {
      console.log("error", error);
      await toast.error(error?.response?.data || error?.message, {
        autoClose: 2000,
      });
    }

    const response: any = await getUserLeaveApplicationsApi({
      page: 1,
      per_page: 12,
    });
    return response?.data;
  }
);

//Task
export const getAllTask = createAsyncThunk(
  "hrManagement/getAllTask",
  async (params: any) => {
    try {
      const response: any = await getAllTaskApi(params);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const getTaskBoard = createAsyncThunk(
  "hrManagement/getTaskBoard",
  async (params: any) => {
    try {
      const response: any = await getTaskBoardApi(params);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const getTaskMetrix = createAsyncThunk(
  "hrManagement/getTaskMetrix",
  async (params: any) => {
    try {
      const response: any = await getTaskMetrixApi(params);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const getTaskDetails = createAsyncThunk(
  "hrManagement/getTaskDetails",
  async (id: any) => {
    try {
      const response: any = await getTaskDetailsApi(id);
      console.log(response);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const getDepartmentalTask = createAsyncThunk(
  "hrManagement/getDepartmentalTask",
  async (params: any) => {
    try {
      const response: any = await getDepartmentalTaskApi(params);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const getActiveTask = createAsyncThunk(
  "hrManagement/getActiveTask",
  async () => {
    try {
      const response: any = await getActiveTaskApi();
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);
export const addTask = createAsyncThunk(
  "hrManagement/getTaskBoard",
  async (event: any) => {
    try {
      const success: any = await addTaskApi(event);

      const added: any = await success?.data;

      if (added.code === 200) {
        await toast.success(added?.message, { autoClose: 2000 });
      } else {
        await toast.error(added?.meassage, { autoClose: 2000 });
      }
    } catch (error: any) {
      console.log("error", error);
      await toast.error(error?.response?.data || error?.message, {
        autoClose: 2000,
      });
    }

    const response: any = await getTaskBoardApi({ search: "", date: "" });
    return response?.data;
  }
);
export const updateTask = createAsyncThunk(
  "hrManagement/getTaskBoard",
  async (event: any) => {
    try {
      const success: any = await updateTaskApi(event);

      const updated: any = await success?.data;

      if (updated.code === 200) {
        await toast.success(updated?.message, { autoClose: 2000 });
      } else {
        await toast.error(updated?.meassage, { autoClose: 2000 });
      }
    } catch (error: any) {
      console.log("error", error);
      await toast.error(error?.response?.data || error?.message, {
        autoClose: 2000,
      });
    }

    const response: any = await getTaskBoardApi({ search: "", date: "" });
    return response?.data;
  }
);
export const deleteTask = createAsyncThunk(
  "hrManagement/getTaskBoard",
  async (event: any) => {
    try {
      const success: any = await deleteTaskApi(event);

      const deleted: any = await success?.data;

      if (deleted.code === 200) {
        await toast.success(deleted?.message, { autoClose: 2000 });
      } else {
        await toast.error(deleted?.meassage, { autoClose: 2000 });
      }
    } catch (error: any) {
      console.log("error", error);
      await toast.error(error?.response?.data || error?.message, {
        autoClose: 2000,
      });
    }

    const response: any = await getTaskBoardApi({ search: "", date: "" });
    return response?.data;
  }
);
export const getUserTask = createAsyncThunk(
  "hrManagement/getUserTask",
  async (params: any) => {
    try {
      const response: any = await getUserTaskApi(params);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);

export const getEmployee = createAsyncThunk(
  "hrManagement/getEmployee",
  async () => {
    try {
      const response = getEmployeeApi();
      return response;
    } catch (error) {
      return error;
    }
  }
);
export const addEmployee = createAsyncThunk(
  "hrManagement/addEmployee",
  async (event: any) => {
    try {
      const response = addEmployeeApi(event);
      const data = await response;
      toast.success("Employee Added Successfully", { autoClose: 2000 });
      return data;
    } catch (error) {
      toast.error("Employee Added Failed", { autoClose: 2000 });
      return error;
    }
  }
);
export const updateEmployee = createAsyncThunk(
  "hrManagement/updateEmployee",
  async (event: any) => {
    try {
      const response = updateEmployeeApi(event);
      const data = await response;
      toast.success("Employee updated Successfully", { autoClose: 2000 });
      return data;
    } catch (error) {
      toast.error("Employee updated Failed", { autoClose: 2000 });
      return error;
    }
  }
);
export const deleteEmployee = createAsyncThunk(
  "hrManagement/deleteEmployee",
  async (event: any) => {
    try {
      const response = deleteEmployeeApi(event);
      toast.success("Employee deleted Successfully", { autoClose: 2000 });
      return response;
    } catch (error) {
      toast.error("Employee deleted Failed", { autoClose: 2000 });
      return error;
    }
  }
);
export const getHolidays = createAsyncThunk(
  "hrManagement/getHolidays",
  async () => {
    try {
      const response = getHolidaysApi();
      return response;
    } catch (error) {
      return error;
    }
  }
);
export const addHolidays = createAsyncThunk(
  "hrManagement/addHolidays",
  async (event: any) => {
    try {
      const response = addHolidaysApi(event);
      const data = await response;
      toast.success("Holidays Added Successfully", { autoClose: 2000 });
      return data;
    } catch (error) {
      toast.error("Holidays Added Failed", { autoClose: 2000 });
      return error;
    }
  }
);
export const updateHolidays = createAsyncThunk(
  "hrManagement/updateHolidays",
  async (event: any) => {
    try {
      const response = updateHolidaysApi(event);
      const data = await response;
      toast.success("Holidays updated Successfully", { autoClose: 2000 });
      return data;
    } catch (error) {
      toast.error("Holidays updated Failed", { autoClose: 2000 });
      return error;
    }
  }
);
export const deleteHolidays = createAsyncThunk(
  "hrManagement/deleteHolidays",
  async (event: any) => {
    try {
      const response = deleteHolidaysApi(event);
      toast.success("Holidays deleted Successfully", { autoClose: 2000 });
      return response;
    } catch (error) {
      toast.error("Holidays deleted Failed", { autoClose: 2000 });
      return error;
    }
  }
);
export const getEstimates = createAsyncThunk(
  "hrManagement/getEstimates",
  async () => {
    try {
      const response = getEstimatesApi();
      return response;
    } catch (error) {
      return error;
    }
  }
);
export const addEstimates = createAsyncThunk(
  "hrManagement/addEstimates",
  async (event: any) => {
    try {
      const response = addEstimatesApi(event);
      const data = await response;
      toast.success("Estimates Added Successfully", { autoClose: 2000 });
      return data;
    } catch (error) {
      toast.error("Estimates Added Failed", { autoClose: 2000 });
      return error;
    }
  }
);
export const updateEstimates = createAsyncThunk(
  "hrManagement/updateEstimates",
  async (event: any) => {
    try {
      const response = updateEstimatesApi(event);
      const data = await response;
      toast.success("Estimates updated Successfully", { autoClose: 2000 });
      return data;
    } catch (error) {
      toast.error("Estimates updated Failed", { autoClose: 2000 });
      return error;
    }
  }
);
export const deleteEstimates = createAsyncThunk(
  "hrManagement/deleteEstimates",
  async (event: any) => {
    try {
      const response = deleteEstimatesApi(event);
      toast.success("Estimates deleted Successfully", { autoClose: 2000 });
      return response;
    } catch (error) {
      toast.error("Estimates deleted Failed", { autoClose: 2000 });
      return error;
    }
  }
);
export const getExpenses = createAsyncThunk(
  "hrManagement/getExpenses",
  async () => {
    try {
      const response = getExpensesApi();
      return response;
    } catch (error) {
      return error;
    }
  }
);
export const addExpenses = createAsyncThunk(
  "hrManagement/addExpenses",
  async (event: any) => {
    try {
      const response = addExpensesApi(event);
      const data = await response;
      toast.success("Expenses Added Successfully", { autoClose: 2000 });
      return data;
    } catch (error) {
      toast.error("Expenses Added Failed", { autoClose: 2000 });
      return error;
    }
  }
);
export const updateExpenses = createAsyncThunk(
  "hrManagement/updateExpenses",
  async (event: any) => {
    try {
      const response = updateExpensesApi(event);
      const data = await response;
      toast.success("Expenses updated Successfully", { autoClose: 2000 });
      return data;
    } catch (error) {
      toast.error("Expenses updated Failed", { autoClose: 2000 });
      return error;
    }
  }
);
export const deleteExpenses = createAsyncThunk(
  "hrManagement/deleteExpenses",
  async (event: any) => {
    try {
      const response = deleteExpensesApi(event);
      toast.success("Expenses deleted Successfully", { autoClose: 2000 });
      return response;
    } catch (error) {
      toast.error("Expenses deleted Failed", { autoClose: 2000 });
      return error;
    }
  }
);
export const getLeaveManageHR = createAsyncThunk(
  "hrManagement/getLeaveManageHR",
  async () => {
    try {
      const response = getLeaveManageHRApi();
      return response;
    } catch (error) {
      return error;
    }
  }
);
export const addLeaveManageHR = createAsyncThunk(
  "hrManagement/addLeaveManageHR",
  async (event: any) => {
    try {
      const response = addLeaveManageHRApi(event);
      const data = await response;
      toast.success("Leave Added Successfully", { autoClose: 2000 });
      return data;
    } catch (error) {
      toast.error("Leave Added Failed", { autoClose: 2000 });
      return error;
    }
  }
);
export const updateLeaveManageHR = createAsyncThunk(
  "hrManagement/updateLeaveManageHR",
  async (event: any) => {
    try {
      const response = updateLeaveManageHRApi(event);
      const data = await response;
      toast.success("Leave updated Successfully", { autoClose: 2000 });
      return data;
    } catch (error) {
      toast.error("Leave updated Failed", { autoClose: 2000 });
      return error;
    }
  }
);
export const deleteLeaveManageHR = createAsyncThunk(
  "hrManagement/deleteLeaveManageHR",
  async (event: any) => {
    try {
      const response = deleteLeaveManageHRApi(event);
      toast.success("Leave deleted Successfully", { autoClose: 2000 });
      return response;
    } catch (error) {
      toast.error("Leave deleted Failed", { autoClose: 2000 });
      return error;
    }
  }
);
export const getEmployeeSalary = createAsyncThunk(
  "hrManagement/getEmployeeSalary",
  async () => {
    try {
      const response = getEmployeeSalaryApi();
      return response;
    } catch (error) {
      return error;
    }
  }
);
export const addEmployeeSalary = createAsyncThunk(
  "hrManagement/addEmployeeSalary",
  async (event: any) => {
    try {
      const response = addEmployeeSalaryApi(event);
      const data = await response;
      toast.success("data Added Successfully", { autoClose: 2000 });
      return data;
    } catch (error) {
      toast.error("data Added Failed", { autoClose: 2000 });
      return error;
    }
  }
);
export const updateEmployeeSalary = createAsyncThunk(
  "hrManagement/updateEmployeeSalary",
  async (event: any) => {
    try {
      const response = updateEmployeeSalaryApi(event);
      const data = await response;
      toast.success("data updated Successfully", { autoClose: 2000 });
      return data;
    } catch (error) {
      toast.error("data updated Failed", { autoClose: 2000 });
      return error;
    }
  }
);
export const deleteEmployeeSalary = createAsyncThunk(
  "hrManagement/deleteEmployeeSalary",
  async (event: any) => {
    try {
      const response = deleteEmployeeSalaryApi(event);
      toast.success("data deleted Successfully", { autoClose: 2000 });
      return response;
    } catch (error) {
      toast.error("data deleted Failed", { autoClose: 2000 });
      return error;
    }
  }
);
export const getAttendance = createAsyncThunk(
  "hrManagement/getAttendance",
  async () => {
    try {
      const response = getAttendanceApi();
      return response;
    } catch (error) {
      return error;
    }
  }
);
export const getMainAttendance = createAsyncThunk(
  "hrManagement/getMainAttendance",
  async () => {
    try {
      const response = getMainAttendanceApi();
      return response;
    } catch (error) {
      return error;
    }
  }
);
export const getLeaveManageEmployee = createAsyncThunk(
  "hrManagement/getLeaveManageEmployee",
  async () => {
    try {
      const response = getLeaveManageEmployeeApi();
      return response;
    } catch (error) {
      return error;
    }
  }
);
export const getPayments = createAsyncThunk(
  "hrManagement/getPayments",
  async () => {
    try {
      const response = getPaymentsApi();
      return response;
    } catch (error) {
      return error;
    }
  }
);
