import React, { useCallback, useEffect, useMemo, useState } from "react";
import TableContainer from "Common/TableContainer";
import { Link } from "react-router-dom";
import {
  MoreHorizontal,
  Search,
  Eye,
  FileEdit,
  Trash2,
  CalendarClock,
  Trophy,
  SquareUser,
  X,
  Plus,
  Download,
  SlidersHorizontal,
  Pencil,
  Info,
} from "lucide-react";
import { Dropdown } from "Common/Components/Dropdown";
import filterDataBySearch from "Common/filterDataBySearch";
import { ToastContainer } from "react-toastify";
import DeleteModal from "Common/DeleteModal";
import { Nav } from "Common/Components/Tab/Nav";
import Tab from "Common/Components/Tab/Tab";
import {
  rejectWfh as onRejectWfh,
  getTaskDetails as onGetTaskDetails,
  getDepartmentalTask as onGetDepartmentalTask,
  getActiveTask as onGetActiveTask,
  getTaskBoard as onGetTaskBoard,
  addTask as onAddTask,
  updateTask as onUpdateTask,
  deleteTask as onDeleteTask,
  getTaskMetrix as onGetTaskMetrix,
  getSupervisorWfh as onGetSupervisorWfh,
  getSupervisorAppraisals as onGetSupervisorAppraisals,
  getSupervisorLeaveApplications as onGetSupervisorLeaveApplications,
} from "slices/thunk";
import { User2, Settings, Phone, Home } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import Modal from "Common/Components/Modal";
import Select from "react-select/dist/declarations/src/Select";

const TaskDashboard = () => {
  const dispatch = useDispatch<any>();

  const selectDataList = createSelector(
    (state: any) => state.Users,
    (hrState: any) => hrState.HRManagment,
    (state, hrState) => ({
      taskBoard: hrState.taskBoard,
      profileList: state.userProfile,
      wfhList: hrState.supervisorWfh,
      appraisalList: hrState.supervisorAppraisals,
      leaveList: hrState.supervisorLeaveApplications,
    })
  );
  const { profileList, wfhList, taskBoard, appraisalList, leaveList } =
    useSelector(selectDataList);
  const [data, setData] = useState([]);
  const [wfhs, setWfh] = useState([]);
  const [leaves, setLeave] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [show, setShow] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [appraisals, setAppraisals] = useState([]);
  const [user, setProfile] = useState<any>({});
  const [eventData, setEventData] = useState<any>();
  // Delete Modal
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [tab, setTab] = useState<string>("wfh");
  const deleteToggle = () => setDeleteModal(!deleteModal);

  useEffect(() => {
    setProfile(profileList?.data);
    setWfh(wfhList?.data);
    setLeave(leaveList?.data);
    setTasks(taskBoard?.data);
    setAppraisals(appraisalList?.data);
  }, [profileList, wfhList, taskBoard, leaveList, appraisalList]);

  // Search Data
  const filterSearchData = (e: any) => {
    const search = e.target.value;
    const keysToSearch = [
      "orderId",
      "customerName",
      "location",
      "orderDate",
      "payments",
      "quantity",
      "status",
    ];
    filterDataBySearch([], search, keysToSearch, setData);
  };

  useEffect(() => {
    const tableHead = document.querySelectorAll("th");
    const lastTheadElement = tableHead[tableHead.length - 1];
    lastTheadElement?.classList.add("ltr:text-right", "rtl:text-left");

    return () => {
      lastTheadElement?.classList.remove("ltr:text-right", "rtl:text-left");
    };
  });

  // Appraisal Datas

  const AppraisalStatus = ({ item }: any) => {
    switch (item) {
      case "approved":
        return (
          <span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent">
            Concluded
          </span>
        );
      case "pending":
        return (
          <span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-yellow-100 border-transparent text-yellow-500 dark:bg-yellow-500/20 dark:border-transparent">
            Pending
          </span>
        );
      case "opened":
        return (
          <span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-custom-100 border-transparent text-custom-500 dark:bg-custom-500/20 dark:border-transparent">
            Opened
          </span>
        );
      case "appraised":
        return (
          <span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-yellow-100 border-transparent text-yellow-500 dark:bg-yellow-500/20 dark:border-transparent">
            Appraised
          </span>
        );
      case "reviewed":
        return (
          <span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-orange-100 border-transparent text-orange-500 dark:bg-orange-500/20 dark:border-transparent">
            Reviewed
          </span>
        );
      case "closed":
        return (
          <span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-yellow-100 border-transparent text-yellow-500 dark:bg-yellow-500/20 dark:border-transparent">
            Closed, Await Approval
          </span>
        );
      default:
        return (
          <span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-slate-100 border-transparent text-slate-500 dark:bg-slate-500/20 dark:border-transparent">
            {item}
          </span>
        );
    }
  };

  const AppraisalType = ({ item }: any) => {
    switch (item) {
      case "half_yr":
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">
            Half Year
          </span>
        );
      case "full_yr":
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">
            Full Year
          </span>
        );
      case "confirmation":
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">
            Confirmations
          </span>
        );
      default:
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">
            {item}
          </span>
        );
    }
  };

  const AppraisalColumns = useMemo(
    () => [
      {
        header: (
          <div className="flex items-center h-full">
            <input
              id="CheckboxAll"
              className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer"
              type="checkbox"
            />
          </div>
        ),
        enableSorting: false,
        id: "checkAll",
        cell: (cell: any) => {
          return (
            <div className="flex items-center h-full">
              <input
                id="Checkbox1"
                className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer"
                type="checkbox"
              />
            </div>
          );
        },
      },
      {
        header: "Appraisal ID",
        accessorKey: "ref_no",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <Link
            to={`/appaisals/${cell.row.original.id}`}
            className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600 user-id"
          >
            <AppraisalType item={cell.getValue()} />
          </Link>
        ),
      },
      {
        header: "Start Date",
        accessorKey: "created_at",
        enableColumnFilter: false,
      },

      {
        header: "Staff",
        accessorKey: "employee_name",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div className="flex items-center gap-2">
            <div className="flex items-center justify-center size-10 font-medium rounded-full shrink-0 bg-slate-200 text-slate-800 dark:text-zink-50 dark:bg-zink-600">
              {cell.row.original.employee_image ? (
                <img
                  src={cell.row.original.employee_image}
                  alt=""
                  className="h-10 rounded-full"
                />
              ) : (
                ""
              )}
            </div>
            <div className="grow">
              <h6 className="mb-1">
                <Link to="#!" className="name">
                  {cell.getValue()}
                </Link>
              </h6>
              <p className="text-slate-500 dark:text-zink-200">
                {cell.row.original.employee_role}
              </p>
            </div>
          </div>
        ),
      },
      {
        header: "Type",
        accessorKey: "appraisal_category.type",
        enableColumnFilter: false,
        cell: (cell: any) => <AppraisalType item={cell.getValue()} />,
      },
      {
        header: "Period",
        accessorKey: "appraisal_category.year",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div className="flex items-center gap-2">
            <div className="grow">
              <h6 className="mb-1">
                <Link to="#!" className="name">
                  {cell.row.original.appraisal_category?.month}
                </Link>
              </h6>
              <p className="text-slate-500 dark:text-zink-200">
                {cell.getValue()}
              </p>
            </div>
          </div>
        ),
      },
      {
        header: "Supervisor",
        accessorKey: "supervisor_name",
        enableColumnFilter: false,
      },
      {
        header: "Scores",
        accessorKey: "total_percentage",
        enableColumnFilter: false,
        cell: (cell: any) => <h5>{cell.getValue()}%</h5>,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => <AppraisalStatus item={cell.getValue()} />,
      },

      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <div className="flex gap-3">
            <Link
              className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md bg-slate-100 text-slate-500 hover:text-custom-500 hover:bg-custom-100 dark:bg-zink-600 dark:text-zink-200 dark:hover:bg-custom-500/20 dark:hover:text-custom-500"
              to={`/appaisals/${cell.row.original.id}`}
            >
              <Eye className="inline-block size-3" />{" "}
            </Link>
          </div>
        ),
      },
    ],
    []
  );

  // WFH Datas

  const WFHStatus = ({ item }: any) => {
    switch (item) {
      case "approved":
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent inline-flex items-center status">
            {item}
          </span>
        );
      case "pending":
        return (
          <span className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-yellow-100 border-transparent text-yellow-500 dark:bg-slate-500/20 dark:text-zink-200 dark:border-transparent status">
            {item}
          </span>
        );
      case "declined":
        return (
          <span className="px-2.5 py-0.5 inline-flex items-center text-xs font-medium rounded border bg-red-100 border-transparent text-red-500 dark:bg-red-500/20 dark:border-transparent status">
            {item}
          </span>
        );
      default:
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent inline-flex items-center status">
            {item}
          </span>
        );
    }
  };

  const wfhColumns = useMemo(
    () => [
      {
        header: "Date",
        accessorKey: "date",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <React.Fragment>
            {cell.getValue()}{" "}
            <span className="px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-white border-slate-400 text-slate-500 dark:bg-zink-700 dark:border-zink-400 dark:text-zink-200 ltr:ml-1 rtl:mr-1 align-middle">
              {cell.row.original.day}
            </span>
          </React.Fragment>
        ),
      },
      {
        header: "Staff",
        accessorKey: "user_name",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div className="flex items-center gap-2">
            <div className="flex items-center justify-center size-10 font-medium rounded-full shrink-0 bg-slate-200 text-slate-800 dark:text-zink-50 dark:bg-zink-600">
              {cell.row.original.user_image ? (
                <img
                  src={cell.row.original.user_image}
                  alt=""
                  className="h-10 rounded-full"
                />
              ) : (
                ""
              )}
            </div>
            <div className="grow">
              <h6 className="mb-1">
                <Link to="#!" className="name">
                  {cell.getValue()}
                </Link>
              </h6>
              <p className="text-slate-500 dark:text-zink-200">
                {cell.row.original.user_role}
              </p>
            </div>
          </div>
        ),
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => <WFHStatus item={cell.getValue()} />,
      },
      {
        header: "Created At",
        accessorKey: "created_at",
        enableColumnFilter: false,
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell: any) => (
          <div className="flex gap-2">
            {/* <Link to="#!" className="flex items-center justify-center size-8 text-green-500 transition-all duration-200 ease-linear bg-green-100 rounded-md hover:text-white hover:bg-green-500 dark:bg-green-500/20 dark:hover:bg-green-500"><Check className="size-4" /></Link> */}
            <Link
              to="#!"
              className="flex items-center justify-center size-8 text-red-500 transition-all duration-200 ease-linear bg-red-100 rounded-md hover:text-white hover:bg-red-500 dark:bg-red-500/20 dark:hover:bg-red-500"
              onClick={() => {
                const data = cell.row.original;
                onWfhClickDelete(data);
              }}
            >
              <X className="size-4" />
            </Link>
          </div>
        ),
      },
    ],
    []
  );

  const onWfhClickDelete = (cell: any) => {
    setDeleteModal(true);
    setTab("wfh");
    if (cell.id) {
      setEventData(cell);
    }
  };

  const handleDelete = () => {
    if (eventData) {
      if (tab == "wfh") {
        dispatch(onRejectWfh(eventData.id));
        setDeleteModal(false);
      }

      if (tab == "task") {
        dispatch(onDeleteTask(eventData.id));
        setDeleteModal(false);
        // setShow(false);
      }
    }
  };

  // Leave data

  const LeaveStatus = ({ item }: any) => {
    switch (item) {
      case "approved":
        return (
          <span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent">
            Approved
          </span>
        );
      case "pending":
        return (
          <span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-yellow-100 border-transparent text-yellow-500 dark:bg-yellow-500/20 dark:border-transparent">
            Pending
          </span>
        );
      case "declined":
        return (
          <span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-red-100 border-transparent text-red-500 dark:bg-red-500/20 dark:border-transparent">
            Declined
          </span>
        );
      default:
        return (
          <span className="px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent">
            {item}
          </span>
        );
    }
  };

  const LeaveType = ({ item }: any) => {
    switch (item) {
      case "half_yr":
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">
            Half Year
          </span>
        );
      case "full_yr":
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">
            Full Year
          </span>
        );
      case "confirmation":
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">
            Confirmations
          </span>
        );
      default:
        return (
          <span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">
            {item}
          </span>
        );
    }
  };

  const LeaveColumns = useMemo(
    () => [
      {
        header: "#",
        accessorKey: "ref_no",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <Link
            to="#!"
            className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600 user-id"
          >
            {" "}
            <span className="px-2.5 py-0.5 text-xs font-medium inline-flex items-center status">
              {cell.getValue()}
            </span>
          </Link>
        ),
      },
      {
        header: "Staff",
        accessorKey: "user_name",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div className="flex items-center gap-2">
            <div className="flex items-center justify-center size-10 font-medium rounded-full shrink-0 bg-slate-200 text-slate-800 dark:text-zink-50 dark:bg-zink-600">
              {cell.row.original.user_image ? (
                <img
                  src={cell.row.original.user_image}
                  alt=""
                  className="h-10 rounded-full"
                />
              ) : (
                ""
              )}
            </div>
            <div className="grow">
              <h6 className="mb-1">
                <Link to="#!" className="name">
                  {cell.getValue()}
                </Link>
              </h6>
              <p className="text-slate-500 dark:text-zink-200">
                {cell.row.original.user_role}
              </p>
            </div>
          </div>
        ),
      },
      {
        header: "Leave Type",
        accessorKey: "type_name",
        enableColumnFilter: false,
      },
      {
        header: "No Of Days",
        accessorKey: "total_leave_days",
        enableColumnFilter: false,
      },
      {
        header: "Start Date",
        accessorKey: "start_date",
        enableColumnFilter: false,
      },
      {
        header: "End",
        accessorKey: "end_date",
        enableColumnFilter: false,
      },
      {
        header: "Relief Staff",
        accessorKey: "relief_user",
        enableColumnFilter: false,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        cell: (cell: any) => <LeaveStatus item={cell.getValue()} />,
      },
      {
        header: "Date Applied",
        accessorKey: "created_at",
        enableColumnFilter: false,
      },

      // {
      //   header: "Action",
      //   enableColumnFilter: false,
      //   enableSorting: true,
      //   cell: (cell: any) => (
      //     <div className="flex gap-3">
      //       <Link
      //         className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md bg-slate-100 text-slate-500 hover:text-custom-500 hover:bg-custom-100 dark:bg-zink-600 dark:text-zink-200 dark:hover:bg-custom-500/20 dark:hover:text-custom-500"
      //         to={`/leaves/${cell.row.original.id}`}
      //       >
      //         <Eye className="inline-block size-3" />{" "}
      //       </Link>
      //     </div>
      //   ),
      // },
    ],
    []
  );

  //Task Columns
  const taskcolumns = useMemo(
    () => [
      // {
      //   header: "#",
      //   accessorKey: "id",
      //   enableColumnFilter: false,
      //   enableSorting: true,
      // },
      {
        header: "Task Id",
        accessorKey: "ref_no",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <>
            <Link to="/apps-ecommerce-order-overview">
              {cell.row.original.ref_no}
            </Link>
          </>
        ),
      },

      // {
      //   header: "Type",
      //   accessorKey: "type",
      //   enableColumnFilter: false,
      //   enableSorting: true,
      // },
      {
        header: "Type",
        accessorKey: "type",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <>
            <div className="grow">
              <h6 className="mb-1">{cell.row.original.type}</h6>
              {/* <p className="text-slate-500 dark:text-zink-500">
                {cell.row.original.type}
              </p> */}
            </div>
          </>
        ),
      },
      {
        header: "Task",
        accessorKey: "task",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <>
            <div className="grow">
              <b className="mb-1">{cell.row.original.task}</b>
              <p className="text-slate-500 dark:text-zink-200">
                {cell.row.original.description.length > 50
                  ? `${cell.row.original.description.slice(0, 50)}...`
                  : cell.row.original.description}
              </p>
            </div>
          </>
        ),
      },
      {
        header: "Created By",
        accessorKey: "username",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <>
            <div className="grow">
              <b className="mb-1">{cell.row.original.username}</b>
              <p className="text-slate-500 dark:text-zink-200">
                {cell.row.original.user_role}
              </p>
            </div>
          </>
        ),
      },
      {
        header: "Due Date",
        accessorKey: "due_date",
        enableColumnFilter: false,
        enableSorting: true,
      },

      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <>
            {cell.row.original.status === "todo" ? (
              <span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-blue-100 border-blue-200 text-blue-500 dark:bg-blue-500/20 dark:border-blue-500/20">
                {cell.getValue()}
              </span>
            ) : cell.row.original.status === "in progress" ? (
              <span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-yellow-100 border-yellow-200 text-yellow-500 dark:bg-yellow-500/20 dark:border-yellow-500/20">
                {cell.row.original.status}
              </span>
            ) : cell.row.original.status === "done" ? (
              <span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-purple-100 border-purple-200 text-purple-500 dark:bg-purple-500/20 dark:border-purple-500/20">
                {cell.row.original.status}
              </span>
            ) : cell.row.original.status === "review" ? (
              <span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-orange-100 border-orange-200 text-orange-500 dark:bg-orange-500/20 dark:border-orange-500/20">
                {cell.row.original.status}
              </span>
            ) : cell.row.original.status === "backlog" ? (
              <span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-zink-100 border-zink-200 text-zink-500 dark:bg-zink-500/20 dark:border-zink-500/20">
                {cell.row.original.status}
              </span>
            ) : cell.row.original.status === "completed" ? (
              <span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-green-100 border-green-200 text-green-500 dark:bg-green-500/20 dark:border-green-500/20">
                {cell.row.original.status}
              </span>
            ) : cell.row.original.status === "canceled" ? (
              <span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-red-100 border-red-200 text-red-500 dark:bg-red-500/20 dark:border-red-500/20">
                {cell.row.original.status}
              </span>
            ) : (
              <span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-yellow-100 border-yellow-200 text-yellow-500 dark:bg-yellow-500/20 dark:border-yellow-500/20">
                {cell.row.original.status}
              </span>
            )}
          </>
        ),
      },
      {
        // action
        header: "Action",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell: any) => (
          <div className="flex justify-end gap-2">
            {/* {cell.row.original.status === "pending" ? ( */}
            <>
              <Link
                to="#!"
                className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md text-slate-500 bg-slate-100 hover:text-white hover:bg-slate-500 dark:text-zink-200 dark:bg-zink-600 dark:hover:text-white dark:hover:bg-zink-400"
                onClick={() => {
                  const data = cell.row.original;
                  handleUpdateTaskClick(data);
                }}
              >
                <Pencil className="size-4" />
              </Link>
              <Link
                to="#!"
                className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md text-red-500 bg-red-100 hover:text-white hover:bg-red-500 dark:text-zink-200 dark:bg-zink-600 dark:hover:text-white dark:hover:bg-zink-400"
                onClick={() => {
                  const data = cell.row.original;
                  onClickTaskDelete(data);
                }}
              >
                <Trash2 className="size-4" />
              </Link>
            </>
            {/* ) : null} */}

            {/* <Link
              to="#!"
              data-modal-target="leaveOverviewModal"
              className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md text-custom-500 bg-custom-100 hover:text-white hover:bg-custom-500 dark:bg-custom-500/20 dark:hover:bg-custom-500"
            >
              <Info className="size-4" />
            </Link> */}
          </div>
        ),
      },
      // {
      //   header: "Action",
      //   enableColumnFilter: false,
      //   enableSorting: true,
      //   cell: (cell: any) => (
      //     <>
      //       <Dropdown className="relative">
      //         <Dropdown.Trigger
      //           id="orderAction5"
      //           data-bs-toggle="dropdown"
      //           className="flex items-center justify-center size-[30px] dropdown-toggle p-0 text-slate-500 btn bg-slate-100 hover:text-white hover:bg-slate-600 focus:text-white focus:bg-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:ring active:ring-slate-100 dark:bg-slate-500/20 dark:text-slate-400 dark:hover:bg-slate-500 dark:hover:text-white dark:focus:bg-slate-500 dark:focus:text-white dark:active:bg-slate-500 dark:active:text-white dark:ring-slate-400/20"
      //         >
      //           <MoreHorizontal className="size-3"></MoreHorizontal>
      //         </Dropdown.Trigger>
      //         <Dropdown.Content
      //           placement={cell.row.index ? "top-end" : "right-end"}
      //           className="absolute z-50 py-2 mt-1 ltr:text-left rtl:text-right list-none bg-white rounded-md shadow-md dropdown-menu min-w-[10rem] dark:bg-zink-600"
      //           aria-labelledby="orderAction5"
      //         >
      //           <li>
      //             <Link
      //               className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
      //               to="/apps-ecommerce-order-overview"
      //             >
      //               <Eye className="inline-block size-3 ltr:mr-1 rtl:ml-1" />{" "}
      //               <span className="align-middle">Overview</span>
      //             </Link>
      //           </li>
      //           <li>
      //             <Link
      //               className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
      //               to="#!"
      //             >
      //               <FileEdit className="inline-block size-3 ltr:mr-1 rtl:ml-1" />{" "}
      //               <span className="align-middle">Edit</span>
      //             </Link>
      //           </li>
      //           <li>
      //             <Link
      //               className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
      //               to="#!"
      //             >
      //               <Trash2 className="inline-block size-3 ltr:mr-1 rtl:ml-1" />{" "}
      //               <span className="align-middle">Delete</span>
      //             </Link>
      //           </li>
      //         </Dropdown.Content>
      //       </Dropdown>
      //     </>
      //   ),
      // },
    ],
    []
  );

  // Delete Modal
  const [deleteTaskModal, setDeleteTaskModal] = useState<boolean>(false);

  // Delete Data
  const onClickTaskDelete = (cell: any) => {
    setDeleteModal(true);
    setTab("task");
    if (cell.id) {
      setEventData(cell);
    }
  };

  const handleTaskDelete = () => {
    if (eventData) {
      dispatch(onDeleteTask(eventData.id));
      setDeleteModal(false);
      setShow(false);
      setEventData("");
      setIsEdit(false);
    }
  };
  //

  // Update Data
  const handleUpdateTaskClick = (ele: any) => {
    // console.log(ele);
    setEventData({ ...ele });
    setIsEdit(true);
    setShow(true);
  };

  // validation
  const validation: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      type: (eventData && eventData.type) || "",
      task: (eventData && eventData.task) || "",
      category: (eventData && eventData.category) || "",
      description: (eventData && eventData.description) || "",
      assigned_to: (eventData && eventData.assigned_to) || "",
      priority: (eventData && eventData.priority) || "",
      start_date:
        (eventData &&
          new Date(eventData.start_date).toISOString().split("T")[0]) ||
        "",
      due_date:
        (eventData &&
          new Date(eventData.due_date).toISOString().split("T")[0]) ||
        "",
      attachment: (eventData && eventData.attachment) || "",
      status: (eventData && eventData.status) || "",
    },
    validationSchema: Yup.object({
      type: Yup.string().required("Please select task type"),
      task: Yup.string().required("Please fill your task"),
      category: Yup.string(),
      description: Yup.string(),
      assigned_to: Yup.string(),
      priority: Yup.string(),
      start_date: Yup.string(),
      due_date: Yup.string(),
      attachment: Yup.string(),
      status: Yup.string(),
    }),

    onSubmit: (values) => {
      if (isEdit) {
        const updateData = {
          id: eventData ? eventData.id : 0,
          ...values,
        };
        // update user
        dispatch(onUpdateTask(updateData));
      } else {
        const newData = {
          ...values,
          id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          userId:
            "#TW15000" +
            (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
        };
        // save new user
        dispatch(onAddTask(newData));
      }
      toggle();
    },
  });

  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
      setEventData("");
      setIsEdit(false);
    } else {
      setShow(true);
      setEventData("");
      validation.resetForm();
    }
  }, [show, validation]);

  let options: any = [
    { value: "", label: "Select Type" },
    { value: "Industry Research Status", label: "Industry Research Status" },
    { value: "Macro Economic Research", label: "Macro Economic Research" },
    { value: "Company Research", label: "Company Research" },
    { value: "Training", label: "Training" },
    { value: "Special", label: "Special" },
    { value: "Consulting", label: "Consulting" },
    { value: "IT", label: "IT" },
  ];

  const handleChange = (selectedOption: any) => {
    if (
      selectedOption.value === "Select Type" ||
      selectedOption.value === "Hidden"
    ) {
      dispatch(onGetTaskBoard({ page: 1, per_page: 5 }));
    } else {
      const filteredUsers = taskBoard.filter(
        (data: any) => data.type === selectedOption.value
      );
      dispatch(onGetTaskBoard({ page: 1, per_page: 5 }));
    }
  };

  const filterSearchTaskData = (e: any) => {
    const search = e.target.value;
    const keysToSearch = [
      "type",
      "ref_no",
      "task",
      "category",
      "username",
      "user_role",
      "status",
      "name",
    ];

    dispatch(onGetTaskBoard({ page: 1, per_page: 10, search: search }));
    dispatch(onGetTaskMetrix({ page: 1, per_page: 10, search: search }));

    filterDataBySearch(tasks, search, keysToSearch, setTasks);
  };

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} limit={1} />
      <DeleteModal
        show={deleteModal}
        onHide={deleteToggle}
        onDelete={handleDelete}
      />
      {user?.isAdmin == true ? (
        <>
          <div className="col-span-12 card md:col-span-8">
            <div className="card-body">
              <h6 className="mb-4 text-15">Admin Request Table</h6>
              <div>
                <Tab.Container defaultActiveKey="homeIcon">
                  <Nav className="flex flex-wrap w-full text-sm font-medium text-center border-b border-slate-200 dark:border-zink-500 nav-tabs">
                    <Nav.Item eventKey="homeIcon" className="group">
                      <a
                        href="#!"
                        data-tab-toggle
                        data-target="homeIcon"
                        className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                      >
                        <Home className="inline-block size-4 mr-1"></Home>
                        <span className="align-middle">WFH Request</span>
                      </a>
                    </Nav.Item>
                    <Nav.Item eventKey="settingIcon" className="group">
                      <a
                        href="#!"
                        data-tab-toggle
                        data-target="settingIcon"
                        className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                      >
                        <CalendarClock className="inline-block size-4 mr-1"></CalendarClock>
                        <span className="align-middle">Leave Request</span>
                      </a>
                    </Nav.Item>
                    <Nav.Item eventKey="ProfileIcon" className="group">
                      <a
                        href="#!"
                        data-tab-toggle
                        data-target="ProfileIcon"
                        className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                      >
                        <Trophy className="inline-block size-4 mr-1"></Trophy>
                        <span className="align-middle">Appraisals Request</span>
                      </a>
                    </Nav.Item>
                    <Nav.Item eventKey="contactIcon" className="group">
                      <a
                        href="#!"
                        data-tab-toggle
                        data-target="contactIcon"
                        className="inline-block px-4 py-2 text-base transition-all duration-300 ease-linear rounded-t-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:text-custom-500 group-[.active]:border-slate-200 dark:group-[.active]:border-zink-500 group-[.active]:border-b-white dark:group-[.active]:border-b-zink-700 hover:text-custom-500 active:text-custom-500 dark:hover:text-custom-500 dark:active:text-custom-500 -mb-[1px]"
                      >
                        <SquareUser className="inline-block size-4 mr-1"></SquareUser>
                        <span className="align-middle">Task</span>
                      </a>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content className="mt-5 tab-content">
                    <Tab.Pane eventKey="homeIcon">
                      <div className="col-span-12 card md:col-span-8">
                        <div className="card-body">
                          <div className="grid items-center grid-cols-1 gap-3 mb-5 2xl:grid-cols-12">
                            <div className="2xl:col-span-3">
                              <h6 className="text-15">WFH Request</h6>
                            </div>
                            <div className="2xl:col-span-3 2xl:col-start-10">
                              <div className="flex gap-3">
                                <div className="relative grow">
                                  <input
                                    type="text"
                                    className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                    placeholder="Search for ..."
                                    autoComplete="off"
                                    onChange={(e) => filterSearchData(e)}
                                  />
                                  <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600"></Search>
                                </div>
                                {/* <button type="button" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"><i className="align-baseline ltr:pr-1 rtl:pl-1 ri-download-2-line"></i> Export</button> */}
                              </div>
                            </div>
                          </div>

                          {wfhs && wfhs.length > 0 ? (
                            <TableContainer
                              isPagination={true}
                              columns={wfhColumns || []}
                              data={wfhs || []}
                              customPageSize={7}
                              divclassName="overflow-x-auto"
                              tableclassName="w-full whitespace-nowrap"
                              theadclassName="ltr:text-left rtl:text-right bg-slate-100 text-slate-500 dark:text-zink-200 dark:bg-zink-600"
                              thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold border-y border-slate-200 dark:border-zink-500"
                              tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 border-y border-slate-200 dark:border-zink-500"
                              PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
                            />
                          ) : (
                            <div className="noresult">
                              <div className="py-6 text-center">
                                <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                                <h5 className="mt-2 mb-1">
                                  Sorry! No Result Found
                                </h5>
                                {/* <p className="mb-0 text-slate-500 dark:text-zink-200">
                                  We've searched more than 199+ users We did not
                                  find any WFH for you search.
                                </p> */}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="settingIcon">
                      <div className="col-span-12 card md:col-span-8">
                        <div className="card-body">
                          <div className="grid items-center grid-cols-1 gap-3 mb-5 2xl:grid-cols-12">
                            <div className="2xl:col-span-3">
                              <h6 className="text-15">Leave Request</h6>
                            </div>
                            <div className="2xl:col-span-3 2xl:col-start-10">
                              <div className="flex gap-3">
                                <div className="relative grow">
                                  <input
                                    type="text"
                                    className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                    placeholder="Search for ..."
                                    autoComplete="off"
                                    onChange={(e) => filterSearchData(e)}
                                  />
                                  <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600"></Search>
                                </div>
                                {/* <button type="button" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"><i className="align-baseline ltr:pr-1 rtl:pl-1 ri-download-2-line"></i> Export</button> */}
                              </div>
                            </div>
                          </div>

                          {leaves && leaves.length > 0 ? (
                            <TableContainer
                              isPagination={true}
                              columns={LeaveColumns || []}
                              data={leaves || []}
                              customPageSize={7}
                              divclassName="overflow-x-auto"
                              tableclassName="w-full whitespace-nowrap"
                              theadclassName="ltr:text-left rtl:text-right bg-slate-100 text-slate-500 dark:text-zink-200 dark:bg-zink-600"
                              thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold border-y border-slate-200 dark:border-zink-500"
                              tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 border-y border-slate-200 dark:border-zink-500"
                              PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
                            />
                          ) : (
                            <div className="noresult">
                              <div className="py-6 text-center">
                                <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                                <h5 className="mt-2 mb-1">
                                  Sorry! No Result Found
                                </h5>
                                <p className="mb-0 text-slate-500 dark:text-zink-200">
                                  We've searched more than 199+ users We did not
                                  find any WFH for you search.
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="ProfileIcon">
                      <div className="col-span-12 card md:col-span-8">
                        <div className="card-body">
                          <div className="grid items-center grid-cols-1 gap-3 mb-5 2xl:grid-cols-12">
                            <div className="2xl:col-span-3">
                              <h6 className="text-15">Appraisal Request</h6>
                            </div>
                            <div className="2xl:col-span-3 2xl:col-start-10">
                              <div className="flex gap-3">
                                <div className="relative grow">
                                  <input
                                    type="text"
                                    className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                    placeholder="Search for ..."
                                    autoComplete="off"
                                    onChange={(e) => filterSearchData(e)}
                                  />
                                  <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600"></Search>
                                </div>
                                {/* <button type="button" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"><i className="align-baseline ltr:pr-1 rtl:pl-1 ri-download-2-line"></i> Export</button> */}
                              </div>
                            </div>
                          </div>

                          {appraisals && appraisals.length > 0 ? (
                            <TableContainer
                              isPagination={true}
                              columns={AppraisalColumns || []}
                              data={appraisals || []}
                              customPageSize={7}
                              divclassName="overflow-x-auto"
                              tableclassName="w-full whitespace-nowrap"
                              theadclassName="ltr:text-left rtl:text-right bg-slate-100 text-slate-500 dark:text-zink-200 dark:bg-zink-600"
                              thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold border-y border-slate-200 dark:border-zink-500"
                              tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 border-y border-slate-200 dark:border-zink-500"
                              PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
                            />
                          ) : (
                            <div className="noresult">
                              <div className="py-6 text-center">
                                <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                                <h5 className="mt-2 mb-1">
                                  Sorry! No Result Found
                                </h5>
                                <p className="mb-0 text-slate-500 dark:text-zink-200">
                                  We've searched more than 199+ users We did not
                                  find any appraisal request for you search.
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="contactIcon">
                      <div className="col-span-12 card md:col-span-8">
                        <div className="card-body">
                          <div className="flex items-center">
                            <h6 className="text-15 grow">
                              Recent Task Overview
                            </h6>
                            <div className="shrink-0">
                              {/* <button type="button" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20" onClick={toggle}><Plus className="inline-block size-4" /> <span className="align-middle">Add User</span></button> */}

                              <div className="ltr:lg:text-right rtl:lg:float-left">
                                {/* <Link to="/apps-create-user" type="button" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"><Plus className="inline-block size-4" /> <span className="align-middle">Add User</span></Link> */}
                                <Link
                                  to="#!"
                                  data-modal-target="addTaskModal"
                                  type="button"
                                  className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                                  onClick={toggle}
                                >
                                  <Plus className="inline-block size-4" />
                                  <span className="align-middle">
                                    Add New Task
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="!py-3.5 card-body border-y border-dashed border-slate-200 dark:border-zink-500">
                          <form action="#!">
                            <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                              <div className="relative xl:col-span-4">
                                <input
                                  type="text"
                                  className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                  placeholder="Search for name, email, phone number etc..."
                                  autoComplete="off"
                                  onChange={(e) => filterSearchTaskData(e)}
                                />
                                <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
                              </div>
                              <div className="xl:col-span-2">
                                {/* <Select
                                                            className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                                            options={options}
                                                            isSearchable={false}
                                                            defaultValue={options[0]}
                                                            onChange={(event: any) => handleChange(event)}
                                                            id="choices-single-default"
                                                        /> */}
                              </div>
                              <div className="xl:col-span-3 xl:col-start-10">
                                <div className="flex gap-2 xl:justify-end">
                                  {/* <div>
                                                                <button type="button" className="bg-white border-dashed text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600 dark:bg-zink-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20"><Download className="inline-block size-4" /> <span className="align-middle">Export</span></button>
                                                            </div> */}
                                  {/* <button className="flex items-center justify-center size-[37.5px] p-0 text-slate-500 btn bg-slate-100 hover:text-white hover:bg-slate-600 focus:text-white focus:bg-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:ring active:ring-slate-100 dark:bg-slate-500/20 dark:text-slate-400 dark:hover:bg-slate-500 dark:hover:text-white dark:focus:bg-slate-500 dark:focus:text-white dark:active:bg-slate-500 dark:active:text-white dark:ring-slate-400/20"><SlidersHorizontal className="size-4" /></button> */}
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div className="card-body">
                          {tasks && tasks.length > 0 ? (
                            <TableContainer
                              isPagination={true}
                              columns={taskcolumns || []}
                              data={tasks || []}
                              customPageSize={7}
                              divclassName="overflow-x-auto"
                              tableclassName="w-full whitespace-nowrap"
                              theadclassName="ltr:text-left rtl:text-right bg-slate-100 text-slate-500 dark:text-zink-200 dark:bg-zink-600"
                              thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold border-y border-slate-200 dark:border-zink-500"
                              tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 border-y border-slate-200 dark:border-zink-500"
                              PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
                            />
                          ) : (
                            <div className="noresult">
                              <div className="py-6 text-center">
                                <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                                <h5 className="mt-2 mb-1">
                                  Sorry! No Result Found
                                </h5>
                                {/* <p className="mb-0 text-slate-500 dark:text-zink-200">
                                  We've searched more than 199+ users We did not
                                  find any Task for you search.
                                </p> */}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="col-span-12 card md:col-span-8">
            <div className="card-body">
              <div className="flex items-center">
                <h6 className="text-15 grow">Recent Task Overview</h6>
                <div className="shrink-0">
                  {/* <button type="button" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20" onClick={toggle}><Plus className="inline-block size-4" /> <span className="align-middle">Add User</span></button> */}

                  <div className="ltr:lg:text-right rtl:lg:float-left">
                    {/* <Link to="/apps-create-user" type="button" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"><Plus className="inline-block size-4" /> <span className="align-middle">Add User</span></Link> */}
                    <Link
                      to="#!"
                      data-modal-target="addTaskModal"
                      type="button"
                      className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                      onClick={toggle}
                    >
                      <Plus className="inline-block size-4" />
                      <span className="align-middle">Add New Task</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="!py-3.5 card-body border-y border-dashed border-slate-200 dark:border-zink-500">
              <form action="#!">
                <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                  <div className="relative xl:col-span-4">
                    <input
                      type="text"
                      className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                      placeholder="Search for name, email, phone number etc..."
                      autoComplete="off"
                      onChange={(e) => filterSearchTaskData(e)}
                    />
                    <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
                  </div>
                  <div className="xl:col-span-2">
                    {/* <Select
                                      className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                      options={options}
                                      isSearchable={false}
                                      defaultValue={options[0]}
                                      onChange={(event: any) => handleChange(event)}
                                      id="choices-single-default"
                                  /> */}
                  </div>
                  {/* <div className="xl:col-span-2">
                                  <Select
                                      className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                      options={options}
                                      isSearchable={false}
                                      defaultValue={options[0]}
                                      onChange={(event: any) => handleChange(event)}
                                      id="choices-single-default"
                                  />
                              </div> */}
                  <div className="xl:col-span-3 xl:col-start-10">
                    <div className="flex gap-2 xl:justify-end">
                      {/* <div>
                                          <button type="button" className="bg-white border-dashed text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600 dark:bg-zink-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20"><Download className="inline-block size-4" /> <span className="align-middle">Export</span></button>
                                      </div> */}
                      {/* <button className="flex items-center justify-center size-[37.5px] p-0 text-slate-500 btn bg-slate-100 hover:text-white hover:bg-slate-600 focus:text-white focus:bg-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:ring active:ring-slate-100 dark:bg-slate-500/20 dark:text-slate-400 dark:hover:bg-slate-500 dark:hover:text-white dark:focus:bg-slate-500 dark:focus:text-white dark:active:bg-slate-500 dark:active:text-white dark:ring-slate-400/20"><SlidersHorizontal className="size-4" /></button> */}
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="card-body">
              {tasks && tasks.length > 0 ? (
                <TableContainer
                  isPagination={true}
                  columns={taskcolumns || []}
                  data={tasks || []}
                  customPageSize={7}
                  divclassName="overflow-x-auto"
                  tableclassName="w-full whitespace-nowrap"
                  theadclassName="ltr:text-left rtl:text-right bg-slate-100 text-slate-500 dark:text-zink-200 dark:bg-zink-600"
                  thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold border-y border-slate-200 dark:border-zink-500"
                  tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 border-y border-slate-200 dark:border-zink-500"
                  PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
                />
              ) : (
                <div className="noresult">
                  <div className="py-6 text-center">
                    <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                    <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                    {/* <p className="mb-0 text-slate-500 dark:text-zink-200">
                      We've searched more than 199+ users We did not find any
                      Task for you search.
                    </p> */}
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {/* here is the modal */}
      <Modal
        show={show}
        onHide={toggle}
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        {/* addTaskModal */}
        <Modal.Header
          className="flex items-center justify-between p-4 border-b dark:border-zink-500"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
        >
          <Modal.Title className="text-16">
            {!!isEdit ? "Update Task" : "Create New Task"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            action="#!"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-12">
              <div className="xl:col-span-12">
                <div>
                  <label
                    htmlFor="taskType"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Task Type
                  </label>
                  <select
                    className="form-input border-slate-300 focus:outline-none focus:border-custom-500"
                    data-choices
                    data-choices-search-false
                    id="taskType"
                    name="type"
                    onChange={validation.handleChange}
                    value={validation.values.type || ""}
                  >
                    <option value="">Select Type</option>
                    <option value="Rating">Rating</option>
                    <option value="Industry Research">Industry Research</option>
                    <option value="Macro Economic Research">
                      Macro Economic Research
                    </option>
                    <option value="Company Research">Company Research</option>
                    <option value="Training">Training</option>
                    <option value="Consulting">Consulting</option>
                    <option value="Special">Special</option>
                    <option value="IT">IT</option>
                    {/* {types?.map((type: any) => (
                      <option key={type.id} value={type.id}>
                        {type.type_name}
                      </option>
                    ))} */}
                  </select>
                  {validation.touched.type && validation.errors.type ? (
                    <p className="text-red-400">{validation.errors.type}</p>
                  ) : null}
                </div>
              </div>

              {/* <div className="xl:col-span-6">
                <div>
                  <label
                    htmlFor="categoryInput"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Task Category
                  </label>
                  <input
                    type="text"
                    id="categoryInput"
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    name="category"
                    onChange={validation.handleChange}
                    value={validation.values.category || ''}
                  />
                  {validation.touched.category &&
                  validation.errors.category ? (
                    <p className="text-red-400">
                      {validation.errors.category}
                    </p>
                  ) : null}
                </div>
              </div> */}

              <div className="xl:col-span-12">
                <label
                  htmlFor="taskInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Task Title
                </label>
                <input
                  type="text"
                  id="taskInput"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Task Title"
                  name="task"
                  onChange={validation.handleChange}
                  value={validation.values.task || ""}
                />
                {validation.touched.task && validation.errors.task ? (
                  <p className="text-red-400">{validation.errors.task}</p>
                ) : null}
              </div>

              <div className="xl:col-span-12">
                <div>
                  <label
                    htmlFor="descriptionInput"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Task Description
                  </label>
                  <textarea
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    id="descriptionInput"
                    rows={4}
                    name="description"
                    onChange={validation.handleChange}
                    value={validation.values.description || ""}
                  ></textarea>
                  {validation.touched.description &&
                  validation.errors.description ? (
                    <p className="text-red-400">
                      {validation.errors.description}
                    </p>
                  ) : null}
                </div>
              </div>

              <div className="xl:col-span-6">
                <label
                  htmlFor="start_dateInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Start Date
                </label>
                <input
                  type="date"
                  id="start_dateInput"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="User Category name"
                  name="start_date"
                  onChange={validation.handleChange}
                  value={validation.values.start_date || ""}
                />
                {validation.touched.start_date &&
                validation.errors.start_date ? (
                  <p className="text-red-400">{validation.errors.start_date}</p>
                ) : null}
              </div>

              <div className="xl:col-span-6">
                <label
                  htmlFor="due_dateInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Due Date
                </label>
                <input
                  type="date"
                  id="due_dateInput"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="User Category name"
                  name="due_date"
                  onChange={validation.handleChange}
                  value={validation.values.due_date || ""}
                />
                {validation.touched.due_date && validation.errors.due_date ? (
                  <p className="text-red-400">{validation.errors.due_date}</p>
                ) : null}
              </div>

              <div className="xl:col-span-6">
                <div>
                  <label
                    htmlFor="priorityType"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Priority
                  </label>
                  <select
                    className="form-input border-slate-300 focus:outline-none focus:border-custom-500"
                    data-choices
                    data-choices-search-false
                    id="priorityType"
                    name="priority"
                    onChange={validation.handleChange}
                    value={validation.values.priority || ""}
                  >
                    <option value="">Select Priority</option>
                    <option value="V.High">Very High</option>
                    <option value="High">High</option>
                    <option value="Medium">Medium</option>
                    <option value="Low">Low</option>
                    {/* {types?.map((type: any) => (
                      <option key={type.id} value={type.id}>
                        {type.type_name}
                      </option>
                    ))} */}
                  </select>
                  {validation.touched.priority && validation.errors.priority ? (
                    <p className="text-red-400">{validation.errors.priority}</p>
                  ) : null}
                </div>
              </div>

              {/* <div className="xl:col-span-6">
                <div>
                  <label
                    htmlFor="attachmentInput"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Attachment
                  </label>
                  <input
                    type="file"
                    id="attachmentInput"
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    name="attachment"
                    onChange={validation.handleChange}
                    value={validation.values.attachment || 0}
                  />
                  {validation.touched.attachment &&
                  validation.errors.attachment ? (
                    <p className="text-red-400">
                      {validation.errors.attachment}
                    </p>
                  ) : null}
                </div>
              </div> */}

              {/* {isEdit && ( */}
              <div className="xl:col-span-6">
                <label
                  htmlFor="statusInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Status
                </label>
                <select
                  className="form-input border-slate-300 focus:outline-none focus:border-custom-500"
                  data-choices
                  data-choices-search-false
                  id="statusInput"
                  name="status"
                  onChange={validation.handleChange}
                  value={validation.values.status || ""}
                >
                  <option value="">Select Status</option>
                  <option value="todo">Todo</option>
                  <option value="inprogress">In progress</option>
                  <option value="done">Done</option>
                  <option value="review">Review</option>
                  <option value="completed">Completed</option>
                  <option value="backlog">Backlog</option>
                  <option value="canceled">Canceled</option>
                </select>
                {validation.touched.status && validation.errors.status ? (
                  <p className="text-red-400">{validation.errors.status}</p>
                ) : null}
              </div>
              {/* )} */}
            </div>

            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                data-modal-close="addTaskModal"
                className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                onClick={toggle}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                {!!isEdit ? "Update Task" : "Create New Task"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default TaskDashboard;
